import {
  ContentfulComponentCtaButton,
  ContentfulGlobalMicrocopy,
  ContentfulTopicPortfolioSeries,
} from '../../graphql-types';
import { ApiUrlUndefinedError } from '../types/apiUrlUndefinedError';
import { SwpRow } from '../types/swpRow';
import { checkStatusCode } from '../utils/checkStatusCode';
import { getDateWithLocaleOffset, getDaysBetween } from './date.service';
import { getTranslation } from './translation.service';

const AWS_API_URL = process.env.GATSBY_AWS_API_URL;
const SWP_CALCULATOR_ENDPOINT = '/swp-calc';
const API_FULL_URL = `${AWS_API_URL}${SWP_CALCULATOR_ENDPOINT}`;

// minimum time required for calculations
const TIME_3_YEARS = 1000 * 60 * 60 * 24 * 365 * 3;

export const CALCULATOR_VARIANTS = {
  RETURNS: 'returns',
  SWP: 'swp',
};

export interface CalculatorInputData {
  initialInvestment: number;
  withdrawalAmount: number;
  startDate: Date;
  endDate: Date;
  selectedPortfolioSeries: ContentfulTopicPortfolioSeries;
  calculatedAt: Date;
}

export interface CalculatorResults {
  cumulative?: number;
  annualized?: number;
  rows: CalculatorDataRow[];
}

export interface CalculatorDataRow extends SwpRow {
  id: number;
  totalAnnualRedemption: number;
}

export interface SwpOverviewTableFieldLabels {
  investmentPeriod?: string;
  initialInvestment?: string;
  monthlyWithdrawal?: string;
  totalPreTaxWithdrawal?: string;
  totalTaxesPaid?: string;
  endingPortfolioValue?: string;
  cumulative?: string;
  annualized?: string;
}
export interface SwpResultsTableFieldLabels {
  date?: string;
  totalAnnualRedemption?: string;
  cumulativeReturnOfCapital?: string;
  cumulativeCapitalGainLoss?: string;
  cumulativeTotalRedemptions?: string;
  reinvestedDistributions?: string;
  cumulativeTaxesPaid?: string;
  portfolioValue?: string;
}
export interface CalculatorFieldLabels {
  withdrawalAmountLabel?: string;
  withdrawalAmountValidationMessage?: string;
  startDateLabel?: string;
  endDateLabel?: string;
  dateValidationMessage?: string;
  selectPortfolioLabel?: string;
  initialInvestmentLabel?: string;
  initialInvestmentValidationMessage?: string;
}
export interface CalculatorProps {
  className?: string;
  variant?: string;
  title?: string;
  subheading?: string;
  footnote?: string;
  withdrawalAmountLabel?: ContentfulGlobalMicrocopy;
  withdrawalAmountValidationMessage?: ContentfulGlobalMicrocopy;
  startDateLabel?: ContentfulGlobalMicrocopy;
  endDateLabel?: ContentfulGlobalMicrocopy;
  dateValidationMessage?: ContentfulGlobalMicrocopy;
  selectPortfolioLabel?: ContentfulGlobalMicrocopy;
  initialInvestmentLabel?: ContentfulGlobalMicrocopy;
  initialInvestmentValidationMessage?: ContentfulGlobalMicrocopy;
  resultsFieldLabels?: ContentfulGlobalMicrocopy[];
  portfolioSeries?: ContentfulTopicPortfolioSeries[];
  calculateButton?: ContentfulComponentCtaButton;
  printButton?: ContentfulComponentCtaButton;
}

/**
 * This function returns a Promise containing an array of SwpRow based on the amount of
 * months from startDate to endDate.
 * @param initialInvesment A number representing the initial investment dollars
 * @param instrumentKey A portfolio series instrument key.
 * @param startDate A date string in the format YYYY-MM-DD
 * @param endDate A date string in the format YYYY-MM-DD
 * @returns An object whose data field contains an array where each item is an SwpRow
 */
export const getSwp = async (
  initialInvesment: number,
  withdrawalAmount: number,
  instrumentKey: string,
  startDate: string,
  endDate: string,
  locale: string,
): Promise<{ data: SwpRow[] }> => {
  if (!API_FULL_URL) {
    return Promise.reject(new ApiUrlUndefinedError());
  }

  const swpApiUrl = new URL(API_FULL_URL);
  swpApiUrl.searchParams.append(
    'initialInvestment',
    initialInvesment.toString(),
  );
  swpApiUrl.searchParams.append(
    'withdrawalAmount',
    withdrawalAmount.toString(),
  );
  swpApiUrl.searchParams.append('series', instrumentKey);
  swpApiUrl.searchParams.append('startDate', startDate);
  swpApiUrl.searchParams.append('endDate', endDate);
  swpApiUrl.searchParams.append('locale', locale);

  return fetch(swpApiUrl.href).then((response) =>
    checkStatusCode<{ data: SwpRow[] }>(response),
  );
};

/**
 * @deprecated Not needed anymore - withdrawal amount will be passed as 0 if needed
 *
 * This function returns a Promise containing an array of SwpRow based on the amount of
 * months from startDate to endDate. The data is calculated similar to getSwp
 * calculator, except that the withdrawal amount is fixed to $0.
 * @param initialInvesment A number representing the initial investment dollars
 * @param instrumentKey A portfolio series instrument key.
 * @param startDate A date string in the format YYYY-MM-DD
 * @param endDate A date string in the format YYYY-MM-DD
 * @returns An object whose data field contains an array where each item is an SwpRow
 */
export const getReturn = async (
  initialInvesment: number,
  instrumentKey: string,
  startDate: string,
  endDate: string,
  locale: string,
): Promise<{ data: SwpRow[] }> => {
  return getSwp(initialInvesment, 0, instrumentKey, startDate, endDate, locale);
};

/**
 * Returns true if the data given is within the limits to calculate.
 *
 * @param initialInvestment
 * @param selectedPortfolioSeriesId
 * @param withdrawalAmount
 * @param selectedStartDate
 * @param selectedEndDate
 * @returns
 */
export const canCalculate = (
  variant: string,
  initialInvestment: number,
  selectedPortfolioSeriesId: string,
  withdrawalAmount: number,
  selectedStartDate: Date,
  selectedEndDate: Date,
): boolean => {
  return (
    initialInvestment != null &&
    initialInvestment >= 25000 &&
    initialInvestment <= 10000000 &&
    selectedPortfolioSeriesId !== '' &&
    (variant === CALCULATOR_VARIANTS.RETURNS ||
      (withdrawalAmount != null &&
        withdrawalAmount >= 4 &&
        withdrawalAmount <= 10)) &&
    selectedStartDate != null &&
    selectedEndDate != null &&
    selectedEndDate.getTime() - selectedStartDate.getTime() >= TIME_3_YEARS
  );
};

/**
 * Calculates the cumulative percentage total between the first and last calculator data.
 * @param data Calculator datapoints
 * @returns Cumulative total in floating point percentage
 */
export const getCumulativeValue = (data: CalculatorDataRow[]): number => {
  const result =
    data[data.length - 1]?.portfolioPlanValue / data[0]?.portfolioPlanValue - 1;
  return result;
};

/**
 * Calculates the annualized percentage total between the first and last calculator data.
 * @param data Calculator datapoints
 * @returns Annualized total in floating point percentage
 */
export const getAnnualizedValue = (data: CalculatorDataRow[]): number => {
  const daysBetween = getDaysBetween(data[0].date, data[data.length - 1]?.date);
  const result =
    Math.pow(
      data[data.length - 1]?.portfolioPlanValue / data[0]?.portfolioPlanValue,
      365.25 / daysBetween,
    ) - 1;
  return result;
};

/**
 * Returns the minimum start date. This will be the last day in the month of
 * the series inception date.
 * @param startDate Date from which end date is calculated
 */
export const getMinimumStartDate = (
  series: ContentfulTopicPortfolioSeries,
): Date | null => {
  if (!series) {
    return null;
  }

  const seriesInceptionDate = getDateWithLocaleOffset(series.inceptionDate);
  // set to last day of month
  seriesInceptionDate.setMonth(seriesInceptionDate.getMonth() + 1);
  seriesInceptionDate.setDate(0);
  return seriesInceptionDate;
};

/**
 * Returns the maximum start date. There must be 3 years between
 * the start and end date.
 * @param date Date from which max start date is calculated
 */
export const getMaximumStartDate = (date: Date): Date | null => {
  if (!date) {
    return null;
  }

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const maximumStartDate = getDateWithLocaleOffset(
    new Date(year - 3, month, day),
  );
  return maximumStartDate;
};

/**
 * Returns the minimum required end date. There must be 3 years between
 * the start and end date.
 * @param date Date from which end date is calculated
 */
export const getMinimumEndDate = (date: Date): Date | null => {
  if (!date) {
    return null;
  }

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  // take away one day to account for leap year comparisons
  const minimumEndDate = getDateWithLocaleOffset(
    new Date(year + 3, month, day - 1),
  );
  return minimumEndDate;
};

/**
 * Returns the amount of time between two dates in the format:
 * "N year(s) N month(s)"
 * @param start Start date
 * @param end End date
 * @returns string
 */
export const getTimePeriodString = (
  start: Date,
  end: Date,
  locale: string,
): string => {
  const monthsBetween =
    end?.getMonth() -
    start?.getMonth() +
    12 * (end?.getFullYear() - start?.getFullYear());
  const yearsBetween = Math.floor(monthsBetween / 12);
  const remainingMonths = monthsBetween - yearsBetween * 12;
  return `${yearsBetween} ${getTranslation('Year', locale)?.toLowerCase()}${
    yearsBetween > 1 ? 's' : ''
  }${
    remainingMonths > 0
      ? ` ${remainingMonths} ${getTranslation('Month', locale)?.toLowerCase()}${
          remainingMonths > 1 && locale === 'en' ? 's' : ''
        }`
      : ``
  }`;
};
