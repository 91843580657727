import { useState, useEffect } from 'react';

// Custom hook for getting the window width using requestAnimationFrame
const useWindowWidth = () => {
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0,
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let animationFrameId = null;
      let newWidth = window.innerWidth;

      // Update window width
      const updateWidth = () => {
        setWidth(newWidth);
        animationFrameId = null;
      };

      // Handle resize event
      const handleResize = () => {
        newWidth = window.innerWidth;
        // Ensure that updateWidth is only called once per frame
        if (animationFrameId === null) {
          animationFrameId = requestAnimationFrame(updateWidth);
        }
      };

      window.addEventListener('resize', handleResize);

      // Cleanup function to cancel the animation frame and remove the event listener
      return () => {
        window.removeEventListener('resize', handleResize);
        if (animationFrameId !== null) {
          cancelAnimationFrame(animationFrameId);
        }
      };
    }
  }, []); // Empty array means this effect runs only once on mount and cleanup on unmount

  return width;
};

export default useWindowWidth;
