import { useLocalization } from 'gatsby-theme-i18n';
import React from 'react';
import styled from 'styled-components';
import {
  ContentfulAsset,
  ContentfulComponentCtaButton,
  ContentfulTopicStat,
} from '../../graphql-types';
import Button from './Button';
import ContentfulImage from './ContentfulImage';
import Divider from './Divider';
import InfoTooltip from './InfoTooltip';
import Typography from './Typography';

const BackgroundContainer = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
`;
const Row = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoint.lg}) {
    margin: 0 -${(props) => props.theme.spacing.s3};
  }
`;
const Column = styled.div<{ columns: number }>`
  @media (min-width: ${(props) => props.theme.breakpoint.md}) {
    flex: 0 0 calc(100% / ${(props) => props.columns});
  }
`;

interface StatsSetProps {
  backgroundImage?: ContentfulAsset;
  button?: ContentfulComponentCtaButton;
  className?: string;
  columns?: number;
  items?: ContentfulTopicStat[];
  subheading?: string;
  title?: string;
  variant?: string;
}
const StatsSet: React.FC<StatsSetProps> = (props) => {
  const {
    backgroundImage,
    button,
    className,
    columns,
    items,
    subheading,
    title,
    variant,
  } = props;

  const { locale } = useLocalization();

  // calculate how many rows there are given the length of items and
  // the number of columns
  const rows = Math.ceil(items?.length / columns);

  // create cell matrix for mapping out items to proper row and column in React
  const cells = [];
  for (let i = 0; i < rows; i++) {
    cells.push([]);
    for (let j = 0; j < columns; j++) {
      const itemIndex = i * columns + j;
      cells[i][j] = items[itemIndex];
    }
  }

  let statsSetVariant = <></>;
  switch (variant) {
    case 'centered': {
      statsSetVariant = (
        <div className={`${className || ''} py-m3 relative`}>
          {backgroundImage && (
            <BackgroundContainer>
              <ContentfulImage
                image={backgroundImage}
                alt={backgroundImage.description || ''}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </BackgroundContainer>
          )}
          <div className="container relative">
            {title && (
              <Typography
                as="h2"
                variant="h1"
                className="text-center uppercase"
              >
                {title}
              </Typography>
            )}
            {subheading && (
              <Typography as="p" variant="h3" className="text-center">
                {subheading}
              </Typography>
            )}
            {rows &&
              Array(rows)
                .fill(null)
                .map((row, rowIndex) => {
                  return (
                    <Row
                      key={`row-${rowIndex}`}
                      className="flex flex-col mt-m2 lg:flex-row lg:justify-center"
                    >
                      {Array(columns)
                        .fill(null)
                        .map((column, columnIndex) => {
                          const itemIndex = rowIndex * columns + columnIndex;
                          return items[itemIndex] ? (
                            <Column
                              key={`row-${rowIndex}-column-${columnIndex}`}
                              columns={columns}
                              className="text-center mt-s3 first:mt-0 lg:mt-0 lg:px-m3"
                            >
                              {items[itemIndex]?.title && (
                                <Typography
                                  as="h3"
                                  variant="s2"
                                  className="text-secondary whitespace-nowrap"
                                >
                                  {items[itemIndex]?.type === 'dollar' &&
                                    locale === 'en' &&
                                    '$'}
                                  {items[itemIndex]?.title}
                                  {items[itemIndex]?.type === 'dollar' &&
                                    locale === 'fr' &&
                                    ' $'}
                                  {items[itemIndex]?.type === 'percent' && '%'}
                                  {items[itemIndex]?.additionalInfo?.copy && (
                                    <InfoTooltip
                                      copy={
                                        items[itemIndex]?.additionalInfo?.copy
                                      }
                                    />
                                  )}
                                </Typography>
                              )}
                              {items[itemIndex]?.subheading?.subheading && (
                                <Typography
                                  as="p"
                                  variant="body"
                                  className="mt-s1"
                                >
                                  {items[itemIndex]?.subheading?.subheading}
                                </Typography>
                              )}
                            </Column>
                          ) : (
                            <></>
                          );
                        })}
                    </Row>
                  );
                })}
            {button && (
              <Button
                variant={button.variant}
                link={button?.page?.slug || button?.externalUrl}
                openInNewWindow={button?.openInNewWindow}
                className="mt-m2"
              >
                {button.text}
              </Button>
            )}
          </div>
        </div>
      );
      break;
    }
    case 'split': {
      statsSetVariant = (
        <div className={`${className || ''} relative`}>
          {backgroundImage && (
            <BackgroundContainer>
              <ContentfulImage
                image={backgroundImage}
                alt={backgroundImage.description || ''}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </BackgroundContainer>
          )}
          <div className="container relative overflow-hidden py-l2">
            <div className="flex flex-col lg:flex-row">
              <div className="flex-none lg:w-40">
                {title && (
                  <Typography as="h2" variant="h2">
                    {title}
                  </Typography>
                )}
                {subheading && (
                  <Typography as="p" variant="body" className="mt-s2">
                    {subheading}
                  </Typography>
                )}
                {button && (
                  <Button
                    variant={button.variant}
                    link={button?.page?.slug || button?.externalUrl}
                    openInNewWindow={button?.openInNewWindow}
                    className="mt-s3"
                  >
                    {button.text}
                  </Button>
                )}
              </div>
              <div className="flex-none lg:w-10"></div>
              <div className="flex-none lg:w-50">
                {rows &&
                  Array(rows)
                    .fill(null)
                    .map((row, rowIndex) => {
                      return (
                        <div
                          key={`row-${rowIndex}`}
                          className="grid grid-flow-col auto-cols-fr gap-x-s3 mt-m2 first:mt-0"
                        >
                          {Array(columns)
                            .fill(null)
                            .map((column, columnIndex) => {
                              const itemIndex =
                                rowIndex * columns + columnIndex;
                              return items[itemIndex] ? (
                                <div
                                  key={`row-${rowIndex}-column-${columnIndex}`}
                                  className={`mt-m2 flex-1 lg:mt-0`}
                                >
                                  {items[itemIndex]?.image && (
                                    <ContentfulImage
                                      image={items[itemIndex]?.image}
                                      alt={
                                        items[itemIndex]?.image.description ||
                                        ''
                                      }
                                    />
                                  )}
                                  {items[itemIndex]?.title && (
                                    <Typography
                                      as="div"
                                      variant="body"
                                      className="mt-s1 font-medium"
                                    >
                                      {items[itemIndex]?.title}
                                    </Typography>
                                  )}
                                  {items[itemIndex]?.subheading?.subheading && (
                                    <Typography
                                      as="p"
                                      variant="body"
                                      className="mt-s1"
                                    >
                                      {items[itemIndex]?.subheading?.subheading}
                                    </Typography>
                                  )}
                                </div>
                              ) : (
                                <></>
                              );
                            })}
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
          <Divider color="primary" />
        </div>
      );
      break;
    }
  }

  return statsSetVariant;
};

export default StatsSet;
