import translations from '../../i18n/translations.json';

export const getTranslation = (
  item: string,
  locale: string,
  value?: string,
): string | null => {
  let localeString: string | null = translations[item]?.[locale];

  if (value != null && localeString && localeString.includes('{{}}')) {
    localeString = localeString.replace('{{}}', value);
  }

  return localeString;
};
