import React from 'react';
import {
  ContentfulAsset,
  ContentfulComponentCtaButton,
} from '../../graphql-types';
import { WEBSITE } from '../types/website.enum';
import Button from './Button';
import ContentfulImage from './ContentfulImage';
import { useGlobalState } from '../hooks/useGlobalState';
import Typography from './Typography';

interface CalloutCtaProps {
  className?: string;
  title?: string;
  subheading?: string;
  buttons?: ContentfulComponentCtaButton[];
  image?: ContentfulAsset;
  variant?: string;
}
const CalloutCta: React.FC<CalloutCtaProps> = (props) => {
  const { className, title, subheading, buttons, image, variant } = props;
  const { site } = useGlobalState();

  let variantClasses = '';
  if (variant === 'centered') {
    variantClasses = 'text-center';
  } else if (variant === 'left-aligned') {
    variantClasses = 'text-left';
  } else if (variant === 'split') {
    variantClasses = '';
  }
  return (
    <div className={`${className} flex flex-col ${variantClasses}`}>
      {image && (
        <ContentfulImage
          image={image}
          alt={image.description || ''}
          objectFit="contain"
          style={{
            width: '45px',
            height: '45px',
          }}
          className="mb-s3"
        />
      )}
      {title && (
        <Typography
          as="h2"
          variant={site === WEBSITE.EDGEPOINT ? 'h2' : 'h3'}
          className={site === WEBSITE.EDGEPOINT ? 'uppercase' : ''}
        >
          {title}
        </Typography>
      )}
      {subheading && (
        <Typography as="p" variant="body" className="mt-s2">
          {subheading}
        </Typography>
      )}
      {buttons && (
        <ul className={`${site === WEBSITE.CYMBRIA ? '' : 'mt-auto'}`}>
          {buttons.map((button) => {
            return (
              <li key={`button-${button.id}`}>
                <Button
                  variant={button?.variant}
                  link={button?.page?.slug || button?.externalUrl}
                  openInNewWindow={button.openInNewWindow}
                  className="mt-s2"
                >
                  {button?.text}
                </Button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default CalloutCta;
