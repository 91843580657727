import { ApiUrlUndefinedError } from '../types/apiUrlUndefinedError';
import { checkStatusCode } from '../utils/checkStatusCode';

const AWS_API_URL = process.env.GATSBY_AWS_API_URL;
const EMAIL_SUBSCRIPTION_ENDPOINT = '/email-subscription';
const API_FULL_URL = `${AWS_API_URL}${EMAIL_SUBSCRIPTION_ENDPOINT}`;

/**
 * Sends a post request to the email subscription endpoint. A request will return void on success,
 * or a rejected Promise on error.
 *
 * @param email Email from user input
 * @param recaptchaToken Recaptch v3 token
 * @returns void
 */
export const submitEmailSubscription = async (
  email: string,
  recaptchaToken: string,
): Promise<void> => {
  if (!API_FULL_URL) {
    return Promise.reject(new ApiUrlUndefinedError());
  }

  const emailSubscriptionApiUrl = new URL(API_FULL_URL);
  let data;

  try {
    data = JSON.stringify({
      email: email,
      recaptchaToken: recaptchaToken,
    });
  } catch (err) {
    return Promise.reject('Error on JSON.stringify');
  }

  return fetch(emailSubscriptionApiUrl.href, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: data,
  }).then((response) => checkStatusCode<void>(response));
};
