import React from 'react';
import { ThemeContext } from 'styled-components';
import { WEBSITE } from '../types/website.enum';
import { useGlobalState } from '../hooks/useGlobalState';

export enum HiddenOnOptions {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  None = 'None',
}
interface DividerProps {
  className?: string;
  color?: string;
  thick?: boolean;
  fullWidth?: boolean;
  hiddenOn?: HiddenOnOptions;
}

const Divider: React.FC<DividerProps> = (props) => {
  const { className, color, thick = false, fullWidth, hiddenOn } = props;
  const { site } = useGlobalState();
  const theme = React.useContext(ThemeContext);

  let hiddenClasses = '';
  if (hiddenOn && hiddenOn === HiddenOnOptions.Small) {
    hiddenClasses = 'hidden sm:block';
  } else if (hiddenOn && hiddenOn === HiddenOnOptions.Medium) {
    hiddenClasses = 'hidden md:block';
  } else if (hiddenOn && hiddenOn === HiddenOnOptions.Large) {
    hiddenClasses = 'hidden lg:block';
  } else if (hiddenOn && hiddenOn === HiddenOnOptions.None) {
    hiddenClasses = '';
  }

  return (
    <div
      className={`${fullWidth ? '' : 'container'} ${hiddenClasses} ${
        className || ''
      }`}
    >
      <hr
        style={{
          borderColor: color
            ? color
            : site === WEBSITE.EDGEPOINT
            ? theme.color.secondary
            : theme.color.primary,
          borderTopWidth: thick ? '2px' : '1px',
        }}
      />
    </div>
  );
};

export default Divider;
