// eslint-disable-next-line @typescript-eslint/no-var-requires
const baseTheme = require('./base');

const theme = JSON.parse(JSON.stringify(baseTheme)); // deep clone
theme.site = 'Edgepoint';
theme.color.primary = theme.color.black2;
theme.color.secondary = theme.color.orange2;
theme.spacing = {
  s1: '8px',
  s2: '16px',
  s3: '24px',
  s4: '32px',
  m1: '40px',
  m2: '48px',
  m3: '56px',
  m4: '64px',
  l1: '72px',
  l2: '80px',
  l3: '88px',
  l4: '96px',
};
theme.fontSize = {
  smaller: '12px',
  small: '14px',
  body: '16px',
  caption: '18px',
  h4: '22px',
  h3: '26px',
  h2: '28px',
  h1: '32px',
  s1: '46px',
};
theme.typography = {
  h1: `
  font-family: ${theme.fontFamily.heading};
  font-size: ${theme.fontSize.h1};
  font-weight: ${theme.fontWeight.medium};
  line-height: ${theme.lineHeight.xs};
  text-transform: uppercase;
  `,
  h2: `
  font-family: ${theme.fontFamily.heading};
  font-size: ${theme.fontSize.h2};
  font-weight: ${theme.fontWeight.medium};
  line-height: ${theme.lineHeight.xxs};
  `,
  h3: `
  font-family: ${theme.fontFamily.heading};
  font-size: ${theme.fontSize.h3};
  font-weight: ${theme.fontWeight.medium};
  line-height: ${theme.lineHeight.xs};
  `,
  h4: `
  font-family: ${theme.fontFamily.heading};
  font-size: ${theme.fontSize.h4};
  font-weight: ${theme.fontWeight.medium};
  line-height: ${theme.lineHeight.xs};
  `,
  s1: `
  font-family: ${theme.fontFamily.heading};
  font-size: ${theme.fontSize.s1};
  font-weight: ${theme.fontWeight.semiBold};
  `,
  s2: `
  font-family: ${theme.fontFamily.heading};
  font-size: ${theme.fontSize.h2};
  font-weight: ${theme.fontWeight.medium};
  line-height: ${theme.lineHeight.xs};
  `,
  body: `
  font-family: ${theme.fontFamily.body};
  font-size: ${theme.fontSize.body};
  font-weight: ${theme.fontWeight.light};
  line-height: ${theme.lineHeight.lg};
  `,
  nav: `
  font-family: ${theme.fontFamily.body};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.regular};
  `,
  navClick: `
  font-family: ${theme.fontFamily.body};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.regular};
  `,
  navLink: `
  font-family: ${theme.fontFamily.body};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.regular};
  `,
  dropDown: `
  font-family: ${theme.fontFamily.body};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.regular};
  line-height: 2.4;
  `,
  footerTitle: `
  font-family: ${theme.fontFamily.body};
  font-size: ${theme.fontSize.body};
  font-weight: ${theme.fontWeight.bold};
  `,
  footerBody1: `
  font-family: ${theme.fontFamily.body};
  font-size: ${theme.fontSize.body};
  font-weight: ${theme.fontWeight.light};
  line-height: ${theme.lineHeight.lg};
  `,
  footerBody2: `
  font-family: ${theme.fontFamily.body};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.regular};
  `,
  footerBody3: `
  font-family: ${theme.fontFamily.body};
  font-size: ${theme.fontSize.smaller};
  font-weight: ${theme.fontWeight.light};
  line-height: ${theme.lineHeight.md};
  `,
  subheading: `
  font-family: ${theme.fontFamily.heading};
  font-size: ${theme.fontSize.body};
  font-weight: ${theme.fontWeight.semiBold};
  line-height: ${theme.lineHeight.normal};
  `,
  callout: `
  font-family: ${theme.fontFamily.body};
  font-size: ${theme.fontSize.h2};
  font-weight: ${theme.fontWeight.light};
  line-height: ${theme.lineHeight.sm};
  `,
  caption: `
  font-family: ${theme.fontFamily.heading};
  font-size: ${theme.fontSize.caption};
  font-weight: ${theme.fontWeight.bold};
  `,
  date: `
  font-family: ${theme.fontFamily.body};
  font-size: ${theme.fontSize.body};
  font-weight: ${theme.fontWeight.light};
  line-height: ${theme.lineHeight.xxs};
  font-style: italic;
  color: ${theme.color.black3}
  `,
  tag: `
  font-family: ${theme.fontFamily.body};
  font-size: ${theme.fontSize.smaller};
  font-weight: ${theme.fontWeight.medium};
  letter-spacing: 1px;
  `,
};
theme.mobileTypography = {
  h1: `
  font-family: ${theme.fontFamily.heading};
  font-size: 22px;
  font-weight: ${theme.fontWeight.medium};
  line-height: 1.3;
  text-transform: uppercase;
  `,
  h2: `
  font-family: ${theme.fontFamily.heading};
  font-size: 22px;
  font-weight: ${theme.fontWeight.medium};
  line-height: 1.3;
  `,
  h3: `
  font-family: ${theme.fontFamily.heading};
  font-size: 21px;
  font-weight: ${theme.fontWeight.medium};
  line-height: 1.3;
  `,
  h4: `
  font-family: ${theme.fontFamily.heading};
  font-size: 18px;
  font-weight: ${theme.fontWeight.medium};
  line-height: 1.3;
  `,
  s1: `
  font-family: ${theme.fontFamily.heading};
  font-size: 36px;
  font-weight: ${theme.fontWeight.medium};
  `,
  s2: `
  font-family: ${theme.fontFamily.heading};
  font-size: 22px;
  font-weight: ${theme.fontWeight.medium};
  line-height: 1.2;
  `,
  subheading: `
  font-family: ${theme.fontFamily.heading};
  font-size: 12px;
  font-weight: ${theme.fontWeight.semiBold};
  line-height: 1.5;
  `,
  callout: `
  font-family: ${theme.fontFamily.body};
  font-size: 20px;
  font-weight: ${theme.fontWeight.light};
  line-height: 1.35;
  `,
  button: `
  font-family: ${theme.fontFamily.heading};
  font-size: 12px;
  font-weight: ${theme.fontWeight.medium};
  `,
  footerTitle: `
  font-family: ${theme.fontFamily.body};
  font-size: 12px;
  font-weight: ${theme.fontWeight.bold};
  `,
  footerBody1: `
  font-family: ${theme.fontFamily.body};
  font-size: 12px;
  font-weight: ${theme.fontWeight.light};
  line-height: 2.2;
  `,
  footerBody2: `
  font-family: ${theme.fontFamily.body};
  font-size: 10px;
  font-weight: ${theme.fontWeight.regular};
  `,
  footerBody3: `
  font-family: ${theme.fontFamily.body};
  font-size: 10px;
  font-weight: ${theme.fontWeight.light};
  line-height: ${theme.lineHeight.md};
  `,
};
module.exports = theme;
