import 'date-fns';
import React, { CSSProperties } from 'react';
import Highcharts from 'highcharts';
import styled from 'styled-components';
import HighchartsReact from 'highcharts-react-official';
import {
  CircularProgress,
  Fade,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Alert } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import { enCA, frCA, zhCN } from 'date-fns/locale';
import { useLocalization } from 'gatsby-theme-i18n';
import { ContentfulTopicPortfolioSeries } from '../../graphql-types';
import { getPlainTextFromMicrocopy } from '../utils/getPlainTextFromMicrocopy';
import Typography from './Typography';
import Button from './Button';
import {
  CalculatorDataRow,
  CalculatorFieldLabels,
  CalculatorInputData,
  CalculatorProps,
  CalculatorResults,
  CALCULATOR_VARIANTS,
  canCalculate,
  getAnnualizedValue,
  getCumulativeValue,
  getMaximumStartDate,
  getMinimumEndDate,
  getMinimumStartDate,
  getSwp,
  getTimePeriodString,
  SwpOverviewTableFieldLabels,
  SwpResultsTableFieldLabels,
} from '../services/calculator.service';
import {
  getDateWithLocaleOffset,
  isLastBusinessDayOfMonth,
} from '../services/date.service';
import {
  formatLocalizedCurrency,
  formatLocalizedDate,
  formatLocalizedNumber,
} from '../services/localization.service';

const PrintArea = styled.div`
  @media print {
    width: 946px;
    table {
      table-layout: fixed;
    }
  }
`;

const Calculator: React.FC<CalculatorProps> = (props) => {
  const {
    className,
    variant,
    title,
    subheading,
    footnote,
    withdrawalAmountLabel,
    withdrawalAmountValidationMessage,
    startDateLabel,
    endDateLabel,
    dateValidationMessage,
    selectPortfolioLabel,
    initialInvestmentLabel,
    initialInvestmentValidationMessage,
    resultsFieldLabels,
    portfolioSeries,
    calculateButton,
    printButton,
  } = props;

  // various field labels parsed from microcopy
  const [calculatorFieldLabels, setCalculatorFieldLabels] =
    React.useState<CalculatorFieldLabels>();
  const [swpOverviewTableFieldLabels, setSwpOverviewTableFieldLabels] =
    React.useState<SwpOverviewTableFieldLabels>();
  const [swpResultsTableFieldLabels, setSwpResultsTableFieldLabels] =
    React.useState<SwpResultsTableFieldLabels>();
  const [initialInvestment, setInitialInvestment] = React.useState(25000);
  const [withdrawalAmount, setWithdrawalAmount] = React.useState(
    variant === CALCULATOR_VARIANTS.RETURNS ? 0 : 4,
  );
  const [selectedPortfolioSeries, setSelectedPortfolioSeries] =
    React.useState<ContentfulTopicPortfolioSeries>(null);
  const [selectedPortfolioSeriesId, setSelectedPortfolioSeriesId] =
    React.useState('');

  // setup and state for the date selectors
  const [selectedStartDate, setSelectedStartDate] = React.useState(null);
  const lastMonthEndDate = getDateWithLocaleOffset();
  lastMonthEndDate.setDate(0);
  const [selectedEndDate, setSelectedEndDate] =
    React.useState(lastMonthEndDate);

  // ref for the printable area
  const printAreaElementRef = React.useRef<HTMLDivElement>(null);

  // input to calculation so the results variables aren't
  // still tied to the input forms
  const [calculatorInput, setCalculatorInput] =
    React.useState<CalculatorInputData>();
  // results of fetching the backend are set here
  const [calculatorResults, setCalculatorResults] =
    React.useState<CalculatorResults>(null);

  // state for the highchart displayed after calculation
  const [highchartOptions, setHighchartOptions] =
    React.useState<Highcharts.Options>();
  const highchartsRef = React.useRef<HighchartsReact.RefObject>(null);

  // state for the results table after calculation
  const tableCellHeaderColumnStyle = {
    minWidth: '80px',
    maxWidth: variant === 'swp' ? '12.5%' : '25%',
    fontSize: '12px',
  };
  const tableCellColumnStyle: CSSProperties = {
    fontSize: '14px',
    whiteSpace: 'nowrap',
  };

  // various state for controlling buttons
  const [isLoading, setIsLoading] = React.useState(false);
  const [canDownloadPdf, setCanDownloadPdf] = React.useState(false);
  const [isDownloadingPdf, setIsDownloadingPdf] = React.useState(false);
  const [hasCalculateError, setHasCalculateError] = React.useState(false);

  const { locale } = useLocalization();
  const muiPickerLocale =
    locale === 'en' ? enCA : locale === 'fr' ? frCA : zhCN;

  React.useEffect(() => {
    // get plain text for labels and set once
    setCalculatorFieldLabels({
      startDateLabel: getPlainTextFromMicrocopy(startDateLabel?.copy?.raw),
      endDateLabel: getPlainTextFromMicrocopy(endDateLabel?.copy?.raw),
      dateValidationMessage: getPlainTextFromMicrocopy(
        dateValidationMessage?.copy?.raw,
      ),
      selectPortfolioLabel: getPlainTextFromMicrocopy(
        selectPortfolioLabel?.copy?.raw,
      ),
      initialInvestmentLabel: getPlainTextFromMicrocopy(
        initialInvestmentLabel?.copy?.raw,
      ),
      initialInvestmentValidationMessage: getPlainTextFromMicrocopy(
        initialInvestmentValidationMessage?.copy?.raw,
      ),
      withdrawalAmountLabel: getPlainTextFromMicrocopy(
        withdrawalAmountLabel?.copy?.raw,
      ),
      withdrawalAmountValidationMessage: getPlainTextFromMicrocopy(
        withdrawalAmountValidationMessage?.copy?.raw,
      ),
    });

    // parse field labels for the SWP Overview Table
    setSwpOverviewTableFieldLabels({
      investmentPeriod: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find(
          (field) => field.key === 'SwpOverviewInvestmentPeriod',
        )?.copy?.raw,
      ),
      initialInvestment: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find(
          (field) => field.key === 'SwpOverviewInitialInvestment',
        )?.copy?.raw,
      ),
      monthlyWithdrawal: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find(
          (field) => field.key === 'SwpOverviewMonthlyWithdrawal',
        )?.copy?.raw,
      ),
      totalPreTaxWithdrawal: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find(
          (field) => field.key === 'SwpOverviewTotalPreTaxWithdrawal',
        )?.copy?.raw,
      ),
      totalTaxesPaid: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find(
          (field) => field.key === 'SwpOverviewTotalTaxesPaid',
        )?.copy?.raw,
      ),
      endingPortfolioValue: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find(
          (field) => field.key === 'SwpOverviewEndingPortfolioValue',
        )?.copy?.raw,
      ),
      cumulative: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find(
          (field) => field.key === 'SwpOverviewCumulative',
        )?.copy?.raw,
      ),
      annualized: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find(
          (field) => field.key === 'SwpOverviewAnnualized',
        )?.copy?.raw,
      ),
    });

    // setup SWP data grid and parse field labels for the SWP Results Table
    setSwpResultsTableFieldLabels({
      date: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find((field) => field.key === 'SwpResultsDate')
          ?.copy?.raw,
      ),
      totalAnnualRedemption: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find(
          (field) => field.key === 'SwpResultsTotalAnnualRedemption',
        )?.copy?.raw,
      ),
      cumulativeReturnOfCapital: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find(
          (field) => field.key === 'SwpResultsCumulativeReturnOfCapital',
        )?.copy?.raw,
      ),
      cumulativeCapitalGainLoss: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find(
          (field) => field.key === 'SwpResultsCumulativeCapitalGainLoss',
        )?.copy?.raw,
      ),
      cumulativeTotalRedemptions: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find(
          (field) => field.key === 'SwpResultsCumulativeTotalRedemptions',
        )?.copy?.raw,
      ),
      reinvestedDistributions: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find(
          (field) => field.key === 'SwpResultsReinvestedDistributions',
        )?.copy?.raw,
      ),
      cumulativeTaxesPaid: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find(
          (field) => field.key === 'SwpResultsCumulativeTaxesPaid',
        )?.copy?.raw,
      ),
      portfolioValue: getPlainTextFromMicrocopy(
        resultsFieldLabels?.find(
          (field) => field.key === 'SwpResultsPortfolioValue',
        )?.copy?.raw,
      ),
    });

    // set up default selected portfolio series
    if (portfolioSeries && portfolioSeries[0]?.id) {
      const series = portfolioSeries[0];
      setSelectedPortfolioSeries(series);
      setSelectedPortfolioSeriesId(series.id);
      const startDate = getDateWithLocaleOffset(series.inceptionDate);
      // set to end of month
      startDate.setMonth(startDate.getMonth() + 1);
      startDate.setDate(0);
      setSelectedStartDate(startDate);
    }

    // withdrawal amount is defaulted to 0 when using result calculator
    if (variant === 'returns') {
      setWithdrawalAmount(0);
    }
  }, []);

  React.useEffect(() => {
    const series = portfolioSeries?.find(
      (series) => series.id === selectedPortfolioSeriesId,
    );
    if (!series) {
      return;
    }

    setSelectedPortfolioSeries(series);
    const startDate = getDateWithLocaleOffset(series.inceptionDate);
    // set to end of month
    startDate.setMonth(startDate.getMonth() + 1);
    startDate.setDate(0);
    setSelectedStartDate(startDate);

    // reset end date as well
    setSelectedEndDate(lastMonthEndDate);
  }, [selectedPortfolioSeriesId]);

  const handleCalculate = async () => {
    const selectedSeries = portfolioSeries.find(
      (series) => series.id === selectedPortfolioSeriesId,
    );
    const instrumentKey = selectedSeries?.instrumentKey;

    // parse and format dates to YYYY-MM-DD ISO string
    const startDateYYYYMMDD = `${selectedStartDate.getFullYear()}-${
      selectedStartDate.getMonth() + 1
    }-${selectedStartDate.getDate()}`;
    const endDateYYYYMMDD = `${selectedEndDate.getFullYear()}-${
      selectedEndDate.getMonth() + 1
    }-${selectedEndDate.getDate()}`;

    setIsLoading(true);
    const result = await getSwp(
      initialInvestment,
      withdrawalAmount,
      instrumentKey,
      startDateYYYYMMDD,
      endDateYYYYMMDD,
      locale,
    )
      .then((result) => ({
        data: result.data as CalculatorDataRow[],
      }))
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('Calculate Error:', error);
      });
    setIsLoading(false);

    if (!result || !result.data) {
      setHasCalculateError(true);
      setCalculatorResults(null);
      return;
    }

    // happy path
    setCalculatorInput({
      initialInvestment,
      withdrawalAmount,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      selectedPortfolioSeries: selectedSeries,
      calculatedAt: getDateWithLocaleOffset(),
    });

    // get cumulative and annualized total
    const calculatorResults: CalculatorResults = {
      cumulative: getCumulativeValue(result.data),
      annualized: getAnnualizedValue(result.data),
      rows: result.data,
    };

    // highchart is built only from the yearly data, and doesn't need the first row
    // if it isn't an end-of-year, i.e. December
    const firstRowDate = getDateWithLocaleOffset(
      calculatorResults?.rows?.[0]?.date,
    );
    const highchartData: CalculatorDataRow[] = [];
    if (firstRowDate.getMonth() === 11 /* December: 11 */) {
      highchartData.push(...calculatorResults?.rows);
    } else {
      highchartData.push(...calculatorResults?.rows?.slice(1));
    }

    // set state
    setCalculatorResults(calculatorResults);
    setupResultsChart(highchartData);
    setHasCalculateError(false);
    setCanDownloadPdf(true);
  };

  /**
   * Copies the 'print-area' element, removes all other elements on the page,
   * and only prints out the results of the calculator.
   */
  const handleDownloadPdf = () => {
    if (!printAreaElementRef.current) {
      return;
    }

    setIsDownloadingPdf(true);
    // copy print area and resize highchart for printed page
    const pageClone = document.documentElement.cloneNode(true) as HTMLElement;
    if (!pageClone) {
      // if we can't copy the page we'll have no styles
      return;
    }
    const bodyElement = pageClone.querySelector('body');
    if (!bodyElement) {
      // if we have no body we can't copy calculator results to it
      return;
    }
    bodyElement.innerHTML = '';
    bodyElement.style.width = '992px';

    const printAreaCopy = printAreaElementRef.current?.cloneNode(
      true,
    ) as HTMLElement;
    if (!printAreaCopy) {
      // if we can't get access to element we can't really print
      return;
    }
    bodyElement.appendChild(printAreaCopy);

    if (window?.dataLayer) {
      const portfolio = portfolioSeries?.find(
        (series) => series.id === selectedPortfolioSeriesId,
      );
      const duration = `${selectedStartDate?.toLocaleString('default', {
        month: 'long',
      })} ${selectedStartDate?.getFullYear()} to ${selectedEndDate?.toLocaleString(
        'default',
        { month: 'long' },
      )} ${selectedEndDate?.getFullYear()}`;
      const calculatorTitle = variant === 'swp' ? 'SWP' : 'Returns';
      const details = `${calculatorTitle} Calculator - ${portfolio?.title} - Initial Investment: $${initialInvestment} - Withdrawl Amount: ${withdrawalAmount}% - ${duration}`;

      window.dataLayer?.push({
        event: `downloadEvent`,
        value: details,
      });
    }
    const printWindow = window.open();
    printWindow.document.write(pageClone.innerHTML);
    printWindow.document.close();
    printWindow.document.addEventListener('DOMContentLoaded', () => {
      // we need to wait for page to render before printing
      printWindow.focus();
      printWindow.print();
      printWindow.close();
      setIsDownloadingPdf(false);
    });
  };

  /**
  /**
   * This function builds the hightcharts options and series data for the
   * highchart displayed after fetching the calculator data.
   * @param data SwpRow[]
   */
  const setupResultsChart = (data: CalculatorDataRow[]) => {
    if (!data || data.length === 0) {
      return;
    }

    const startDate = getDateWithLocaleOffset(data[0].date);
    const endDate = getDateWithLocaleOffset(data[data.length - 1].date);
    const chartTitle = `${
      portfolioSeries?.find((series) => series.id === selectedPortfolioSeriesId)
        ?.title
    }`;
    const subtitle = `${selectedStartDate?.toLocaleString('default', {
      month: 'long',
    })} ${selectedStartDate?.getFullYear()} to ${selectedEndDate?.toLocaleString(
      'default',
      { month: 'long' },
    )} ${selectedEndDate?.getFullYear()}`;

    const series = [];

    // only 'swp' calculator has withdrawal amounts
    if (variant === 'swp') {
      series.push({
        color: '#836746',
        data: data.map(
          (row) => row.portfolioPlanValue + row.cumulativeRedemptions,
        ),
        name: 'Portfolio Value + Withdrawn Amount',
        type: 'spline',
      });
      series.push({
        color: '#666666',
        data: data.map((row) => row.cumulativeRedemptions),
        name: 'Withdrawn Amount',
        type: 'spline',
      });
    }

    series.push({
      color: '#f4911e',
      data: data.map((row) => row.portfolioPlanValue),
      name: 'Portfolio Value',
      type: 'spline',
    });

    setHighchartOptions({
      title: {
        text: chartTitle,
      },
      subtitle: {
        text: subtitle,
      },
      xAxis: {
        startOnTick: true,
        allowDecimals: false,
        min: startDate.getFullYear(),
        max: endDate.getFullYear(),
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          formatter: (options) => {
            return '$' + Highcharts.numberFormat(options.value as number, 0);
          },
        },
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'top',
        floating: false,
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          pointStart: startDate.getFullYear(),
        },
      },
      tooltip: {
        pointFormat:
          "<span style='color:{point.color}'>●</span> {series.name}: <b>${point.y:,.0f}</b><br/>",
      },
      accessibility: {
        description: `Image description: A line chart showing the total value of an initial investment of ${initialInvestment} with a withdrawal amount of ${withdrawalAmount}% from ${selectedStartDate} to ${selectedEndDate}. The dollar value is plotted on the Y-axis and the year on the X-axis. The chart is interactive.`,
      },
      series: series,
    });
  };

  return (
    <section className={`container ${className ?? ''}`}>
      {title && (
        <Typography as="h2" variant="h2">
          {title}
        </Typography>
      )}
      {subheading && (
        <Typography as="p" variant="body">
          {subheading}
        </Typography>
      )}

      <div className="lg:grid lg:grid-cols-10 lg:grid-flow-col-dense lg:gap-x-s3">
        {calculatorFieldLabels?.initialInvestmentLabel && (
          <FormControl fullWidth className="mt-m1 lg:col-span-4">
            <InputLabel htmlFor="initial-investment-input">
              {calculatorFieldLabels.initialInvestmentLabel}
            </InputLabel>
            <Input
              id="initial-investment-input"
              type="number"
              inputProps={{
                min: '25000',
                max: '10000000',
                step: '10000',
              }}
              value={initialInvestment}
              onChange={(e) => setInitialInvestment(parseInt(e.target.value))}
              fullWidth
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
        )}
        {variant === 'swp' && calculatorFieldLabels?.withdrawalAmountLabel && (
          <FormControl fullWidth className="mt-m1 lg:col-span-4 lg:col-start-6">
            <InputLabel htmlFor="standard-adornment-amount">
              {calculatorFieldLabels.withdrawalAmountLabel}
            </InputLabel>
            <Input
              type="number"
              inputProps={{
                min: '4',
                max: '10',
                step: '1',
              }}
              value={withdrawalAmount}
              onChange={(e) => setWithdrawalAmount(parseInt(e.target.value))}
              fullWidth
              startAdornment={
                <InputAdornment position="start">%</InputAdornment>
              }
            />
          </FormControl>
        )}
        {calculatorFieldLabels?.selectPortfolioLabel && (
          <div className="mt-m1 lg:col-span-4 lg:col-start-6">
            <TextField
              select
              label={calculatorFieldLabels.selectPortfolioLabel}
              value={selectedPortfolioSeriesId}
              onChange={(e) => setSelectedPortfolioSeriesId(e.target.value)}
              fullWidth
            >
              {portfolioSeries?.map((series) => {
                return (
                  <MenuItem key={series.id} value={series.id}>
                    {series.title}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
        )}
        <div className="mt-m1 lg:col-span-4 flex gap-x-s3">
          {calculatorFieldLabels?.startDateLabel &&
            calculatorFieldLabels?.endDateLabel && (
              <MuiPickersUtilsProvider
                locale={muiPickerLocale}
                utils={DateFnsUtils}
              >
                <KeyboardDatePicker
                  fullWidth
                  variant="inline"
                  format={locale === 'fr' ? 'd MMM yyyy' : 'MMM d, yyyy'}
                  margin="normal"
                  id="start-date-picker"
                  className="flex-1 mt-0"
                  value={selectedStartDate}
                  autoOk={true}
                  label={calculatorFieldLabels.startDateLabel}
                  onChange={(date) => setSelectedStartDate(date)}
                  minDate={getMinimumStartDate(selectedPortfolioSeries)}
                  maxDate={getMaximumStartDate(selectedEndDate)}
                  views={['year', 'month', 'date']}
                  shouldDisableDate={(date) => !isLastBusinessDayOfMonth(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change start date',
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <KeyboardDatePicker
                  fullWidth
                  disableFuture
                  value={selectedEndDate}
                  className="flex-1 mt-0"
                  variant="inline"
                  format={locale === 'fr' ? 'd MMM yyyy' : 'MMM d, yyyy'}
                  margin="normal"
                  id="end-date-picker"
                  label={calculatorFieldLabels.endDateLabel}
                  onChange={(date) => setSelectedEndDate(date)}
                  minDate={getMinimumEndDate(selectedStartDate)}
                  maxDate={lastMonthEndDate}
                  shouldDisableDate={(date) => !isLastBusinessDayOfMonth(date)}
                  autoOk={true}
                  views={['year', 'month', 'date']}
                  KeyboardButtonProps={{
                    'aria-label': 'change end date',
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </MuiPickersUtilsProvider>
            )}
        </div>
        <div className="mt-m1 lg:col-span-4 lg:col-start-6">
          <div className="flex flex-col sm:flex-row gap-x-s3">
            {calculateButton && (
              <div className="">
                <Button
                  variant={calculateButton.variant}
                  className="sm:w-full"
                  disabled={
                    !canCalculate(
                      variant,
                      initialInvestment,
                      selectedPortfolioSeriesId,
                      withdrawalAmount,
                      selectedStartDate,
                      selectedEndDate,
                    )
                  }
                  onClick={handleCalculate}
                >
                  {calculateButton.text}
                </Button>
              </div>
            )}
            {printButton && (
              <div className="mt-m1 sm:mt-0">
                <Button
                  variant={printButton.variant}
                  className="sm:w-full"
                  disabled={!canDownloadPdf || isDownloadingPdf || isLoading}
                  onClick={handleDownloadPdf}
                >
                  {printButton.text}
                </Button>
              </div>
            )}
          </div>
          {footnote && (
            <div className="mt-s3 lg:col-span-4">
              <Typography variant="footerBody3">{footnote}</Typography>
            </div>
          )}
        </div>
      </div>

      <Fade in={isLoading} unmountOnExit>
        <div className="container pt-l1 flex justify-center">
          <CircularProgress aria-label="Loading data" />
        </div>
      </Fade>

      {!isLoading && hasCalculateError && (
        <div className="mt-m1">
          <Alert severity="info">
            <strong>Sorry!</strong> Data temporarily unavailable, but we{`'`}re
            working to get it back soon.
          </Alert>
        </div>
      )}

      <PrintArea id="print-area" ref={printAreaElementRef}>
        {!isLoading && !hasCalculateError && highchartOptions && (
          <>
            <div className="mt-m1 lg:grid lg:grid-cols-10 lg:gap-x-s3 lg:items-center">
              <div className="lg:col-span-3">
                <dl id="swp-overview" className="text-sm">
                  <div className="grid grid-cols-3">
                    <dt className="col-span-2">
                      <Typography as="span" variant="footerBody2">
                        {swpOverviewTableFieldLabels?.investmentPeriod}
                      </Typography>
                    </dt>
                    <dd className="col-span-1 text-right">
                      <Typography
                        as="span"
                        variant="footerBody2"
                        className="font-medium"
                      >
                        {getTimePeriodString(
                          calculatorInput?.startDate,
                          calculatorInput?.endDate,
                          locale,
                        )}
                      </Typography>
                    </dd>
                  </div>

                  <div className="mt-s3 grid grid-cols-3">
                    <dt className="col-span-2">
                      <Typography as="span" variant="footerBody2">
                        {swpOverviewTableFieldLabels?.initialInvestment}
                      </Typography>
                    </dt>
                    <dd className="col-span-1 text-right">
                      <Typography
                        as="span"
                        variant="footerBody2"
                        className="font-medium"
                      >
                        {formatLocalizedCurrency(
                          calculatorInput?.initialInvestment,
                          locale,
                          0,
                        )}
                      </Typography>
                    </dd>
                  </div>
                  {variant === 'swp' && (
                    <>
                      <div className="mt-s3 grid grid-cols-3">
                        <dt className="col-span-2">
                          <Typography as="span" variant="footerBody2">
                            {swpOverviewTableFieldLabels?.monthlyWithdrawal}
                          </Typography>
                        </dt>
                        <dd className="col-span-1 text-right">
                          <Typography
                            as="span"
                            variant="footerBody2"
                            className="font-medium"
                          >
                            {formatLocalizedCurrency(
                              calculatorResults?.rows[
                                calculatorResults?.rows?.length - 1
                              ]?.monthlyRedemption,
                              locale,
                              0,
                            )}
                          </Typography>
                        </dd>
                      </div>
                      <div className="mt-s3 grid grid-cols-3">
                        <dt className="col-span-2">
                          <Typography as="span" variant="footerBody2">
                            {swpOverviewTableFieldLabels?.totalPreTaxWithdrawal}
                          </Typography>
                        </dt>
                        <dd className="col-span-1 text-right">
                          <Typography
                            as="span"
                            variant="footerBody2"
                            className="font-medium"
                          >
                            {formatLocalizedCurrency(
                              calculatorResults?.rows[
                                calculatorResults?.rows.length - 1
                              ]?.cumulativeRedemptions,
                              locale,
                              0,
                            )}
                          </Typography>
                        </dd>
                      </div>
                      <div className="mt-s3 grid grid-cols-3">
                        <dt className="col-span-2">
                          <Typography as="span" variant="footerBody2">
                            {swpOverviewTableFieldLabels?.totalTaxesPaid}
                          </Typography>
                        </dt>
                        <dd className="col-span-1 text-right">
                          <Typography
                            as="span"
                            variant="footerBody2"
                            className="font-medium"
                          >
                            {formatLocalizedCurrency(
                              calculatorResults?.rows[
                                calculatorResults?.rows.length - 1
                              ]?.cumulativeTaxLiability,
                              locale,
                              0,
                            )}
                          </Typography>
                        </dd>
                      </div>
                    </>
                  )}

                  <div className="mt-s3 grid grid-cols-3">
                    <dt className="col-span-2">
                      <Typography as="span" variant="footerBody2">
                        {swpOverviewTableFieldLabels?.endingPortfolioValue}
                      </Typography>
                    </dt>
                    <dd className="col-span-1 text-right">
                      <Typography
                        as="span"
                        variant="footerBody2"
                        className="font-medium"
                      >
                        {formatLocalizedCurrency(
                          calculatorResults?.rows[
                            calculatorResults?.rows.length - 1
                          ]?.portfolioPlanValue,
                          locale,
                          0,
                        )}
                      </Typography>
                    </dd>
                  </div>
                  {variant === 'returns' && (
                    <>
                      <div className="mt-s3 grid grid-cols-3">
                        <dt className="col-span-2">
                          <Typography as="span" variant="footerBody2">
                            {swpOverviewTableFieldLabels?.cumulative}
                          </Typography>
                        </dt>
                        <dd className="col-span-1 text-right">
                          <Typography
                            as="span"
                            variant="footerBody2"
                            className="font-medium"
                          >
                            {formatLocalizedNumber(
                              calculatorResults?.cumulative,
                              locale,
                              {
                                style: 'percent',
                              },
                            )}
                          </Typography>
                        </dd>
                      </div>
                      <div className="mt-s3 grid grid-cols-3">
                        <dt className="col-span-2">
                          <Typography as="span" variant="footerBody2">
                            {swpOverviewTableFieldLabels?.annualized}
                          </Typography>
                        </dt>
                        <dd className="col-span-1 text-right">
                          <Typography
                            as="span"
                            variant="footerBody2"
                            className="font-medium"
                          >
                            {formatLocalizedNumber(
                              calculatorResults?.annualized,
                              locale,
                              {
                                style: 'percent',
                              },
                            )}
                          </Typography>
                        </dd>
                      </div>
                    </>
                  )}
                </dl>
              </div>
              <div className="mt-s3 lg:mt-0 lg:col-span-7">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={highchartOptions}
                  ref={highchartsRef}
                />
              </div>
            </div>
            <div
              id="results-table"
              className="mt-m1"
              style={{
                pageBreakBefore: 'always',
              }}
            >
              <TableContainer component="div">
                <Table aria-label="results table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={tableCellHeaderColumnStyle}>
                        {swpResultsTableFieldLabels?.date}
                      </TableCell>
                      {variant === 'swp' && (
                        <>
                          <TableCell
                            align="right"
                            style={tableCellHeaderColumnStyle}
                          >
                            {swpResultsTableFieldLabels?.totalAnnualRedemption}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={tableCellHeaderColumnStyle}
                          >
                            {
                              swpResultsTableFieldLabels?.cumulativeReturnOfCapital
                            }
                          </TableCell>
                          <TableCell
                            align="right"
                            style={tableCellHeaderColumnStyle}
                          >
                            {
                              swpResultsTableFieldLabels?.cumulativeCapitalGainLoss
                            }
                          </TableCell>
                          <TableCell
                            align="right"
                            style={tableCellHeaderColumnStyle}
                          >
                            {
                              swpResultsTableFieldLabels?.cumulativeTotalRedemptions
                            }
                          </TableCell>
                          <TableCell
                            align="right"
                            style={tableCellHeaderColumnStyle}
                          >
                            {
                              swpResultsTableFieldLabels?.reinvestedDistributions
                            }
                          </TableCell>
                          <TableCell
                            align="right"
                            style={tableCellHeaderColumnStyle}
                          >
                            {swpResultsTableFieldLabels?.cumulativeTaxesPaid}
                          </TableCell>
                        </>
                      )}
                      <TableCell
                        align="right"
                        style={tableCellHeaderColumnStyle}
                      >
                        {swpResultsTableFieldLabels?.portfolioValue}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {calculatorResults?.rows &&
                      calculatorResults.rows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={tableCellColumnStyle}
                          >
                            {formatLocalizedDate(
                              getDateWithLocaleOffset(row.date),
                              locale,
                            )}
                          </TableCell>
                          {variant === 'swp' && (
                            <>
                              <TableCell
                                align="right"
                                style={tableCellColumnStyle}
                              >
                                {formatLocalizedCurrency(
                                  row.totalAnnualRedemption,
                                  locale,
                                )}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={tableCellColumnStyle}
                              >
                                {formatLocalizedCurrency(
                                  row.cumulativeROC,
                                  locale,
                                )}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={tableCellColumnStyle}
                              >
                                {formatLocalizedCurrency(
                                  row.cumulativeCapitalGL,
                                  locale,
                                )}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={tableCellColumnStyle}
                              >
                                {formatLocalizedCurrency(
                                  row.cumulativeRedemptions,
                                  locale,
                                )}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={tableCellColumnStyle}
                              >
                                {formatLocalizedCurrency(
                                  row.reinvestedDistributions,
                                  locale,
                                )}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={tableCellColumnStyle}
                              >
                                {formatLocalizedCurrency(
                                  row.cumulativeTaxLiability,
                                  locale,
                                )}
                              </TableCell>
                            </>
                          )}
                          <TableCell align="right" style={tableCellColumnStyle}>
                            {formatLocalizedCurrency(
                              row.portfolioPlanValue,
                              locale,
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
      </PrintArea>
    </section>
  );
};

export default Calculator;
