import React from 'react';
import styled from 'styled-components';
import { ContentfulComponentCalloutCta } from '../../graphql-types';
import { WEBSITE } from '../types/website.enum';
import CalloutCta from './CalloutCta';
import { useGlobalState } from '../hooks/useGlobalState';
import Typography from './Typography';

const CalloutColumn = styled.div<{ site?: string }>`
  position: relative;

  ${(props) => {
    if (props.site === WEBSITE.EDGEPOINT) {
      return `
      @media (min-width: ${props.theme.breakpoint.md}) {
        padding: 0 ${props.theme.spacing.s3};
    
        &:not(:first-of-type):before {
          background: ${props.theme.color.secondary};
          content: '';
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          width: 1px;
        }
      }
    
      @media (min-width: ${props.theme.breakpoint.lg}) {
        padding: 0 ${props.theme.spacing.m3};
      }
      `;
    }
  }}
`;

const CalloutCtaOverride = styled(CalloutCta)`
  // remove padding/margins
  margin: 0 !important;
  padding: 0 !important;
`;

interface CalloutCtaSetProps {
  className?: string;
  title?: string;
  callouts?: ContentfulComponentCalloutCta[];
}
const CalloutCtaSet: React.FC<CalloutCtaSetProps> = (props) => {
  const { className, title, callouts } = props;
  const { site } = useGlobalState();
  return (
    <div className={`${className || ''} container py-m4`}>
      {title && (
        <Typography as="h2" variant="h1">
          {title}
        </Typography>
      )}
      {callouts && callouts.length && (
        <div className="md:flex md:flex-wrap md:gap-y-m4 md:-mx-s3 md:overflow-hidden">
          {callouts.map((callout, index) => (
            <CalloutColumn
              key={`callout-column-${callout?.id || `empty-${index}`}`}
              className="flex flex-col mt-m4 md:w-1/2 md:px-s3 first:mt-0 md:mt-0"
              site={site}
            >
              <CalloutCtaOverride
                title={callout.title}
                subheading={callout.subheading?.subheading}
                buttons={callout.ctaButtons}
                image={callout.image}
                variant={callout.variant}
                // h-full to stretch component to height of cell
                className="h-full"
              />
            </CalloutColumn>
          ))}
        </div>
      )}
    </div>
  );
};

export default CalloutCtaSet;
