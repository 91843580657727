// This object is used often for Typescript throughout the project. A few examples
// are building the DefaultTheme for styled-components and the Typography component.
const theme = {
  site: 'edgepoint',
  breakpoint: {
    xs: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1280px',
    xxl: '1400px',
    super: '2560px',
  },
  color: {
    black: '#000000',
    black2: '#231F20',
    black3: '#555555',
    orange: '#F6B137',
    orange2: '#FF863B',
    orange3: '#FF6200',
    greyText: '#838182',
    greyDark: '#363636',
    greyLight: '#F4F4F4',
    greyLighter: '#FAFAFA',
    white: '#FFFFFF',
    primary: ``,
    secondary: ``,
  },
  container: {
    xs: '100%',
    sm: '100%',
    md: '768px',
    lg: '946px',
    xl: '946px',
    xxl: '946px',
    super: '946px',
  },
  fontFamily: {
    heading: 'montserrat, sans-serif',
    body: 'roboto, sans-serif',
  },
  fontWeight: {
    thin: '200',
    light: '300',
    regular: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
  },
  lineHeight: {
    auto: 'auto',
    xxs: '1.2',
    xs: '1.3',
    sm: '1.35',
    normal: '1.5',
    md: '1.6',
    lg: '1.8',
    xl: '2.2',
  },
  spacing: {
    s1: '',
    s2: '',
    s3: '',
    s4: '',
    m1: '',
    m2: '',
    m3: '',
    m4: '',
    l1: '',
    l2: '',
    l3: '',
    l4: '',
  },
  fontSize: {
    smaller: '',
    small: '',
    body: '',
    caption: '',
    h4: '',
    h3: '',
    h2: '',
    h1: '',
    s1: '',
  },
  typography: {
    h1: ``,
    h2: ``,
    h3: ``,
    h4: ``,
    body: ``,
    callout: ``,
    nav: ``,
    navClick: ``,
    navLink: ``,
    dropDown: ``,
    footerTitle: ``,
    footerBody1: ``,
    footerBody2: ``,
    footerBody3: ``,
    s1: ``,
    s2: ``,
    date: ``,
    caption: ``,
    tag: ``,
    subheading: ``,
    button: ``,
  },
  mobileTypography: {
    s1: ``,
    s2: ``,
    h1: ``,
    h2: ``,
    h3: ``,
    h4: ``,
    subheading: ``,
    callout: ``,
    button: ``,
  },
};
module.exports = theme;
