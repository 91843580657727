import React from 'react';
import styled from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tabs,
  Tab,
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import {
  ContentfulComponentListFootnote,
  ContentfulComponentListItem,
} from '../../graphql-types';
import ContentfulImage from './ContentfulImage';
import Typography from './Typography';
import ComponentSelector from './ComponentSelector';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import { navigate } from 'gatsby';

enum LIST_VARIANT {
  // variants include: 'numbered', 'unnumbered', 'accordion', 'tabs'
  NUMBERED = 'numbered',
  UNNUMBERED = 'unnumbered',
  ACCORDION = 'accordion',
  TABS = 'tabs',
}

const StyledTabs = styled(Tabs)`
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.color.greyLight};
  .MuiTabs-indicator {
    background-color: ${(props) => props.theme.color.secondary};
  }

  .MuiTabs-scrollButtons {
    width: initial;
    &:first-child {
      height: 100%;
      position: absolute;
      left: 0;
      opacity: 1;
      z-index: 1;
    }

    &.MuiTabs-scrollButtonsDesktop {
      display: flex;

      &.Mui-disabled:first-child {
        visibility: hidden;
      }
    }
  }
`;
const StyledTab = styled(Tab)`
  &.MuiTab-root {
    ${(props) => props.theme.typography.body}
    text-transform: none;
    min-width: auto;
    padding-left: 0;
    padding-right: 0;
  }

  &.Mui-selected {
    color: ${(props) => props.theme.color.secondary};
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }

  &:not(:first-of-type) {
    margin-left: ${(props) => props.theme.spacing.m1};
  }
`;

interface ListProps {
  title?: string;
  variant?: string;
  items?: ContentfulComponentListItem[];
  footnote?: ContentfulComponentListFootnote;
  className?: string;
}
const List: React.FC<ListProps> = (props) => {
  const { title, variant, items, footnote, className } = props;

  const footnoteRichTextOptions: Options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography as="p" variant="date">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography as="p" variant="body">
          {children}
        </Typography>
      ),
    },
  };

  const [expandedAccordions, setExpandedAccordion] = React.useState<
    Map<string, boolean>
  >(new Map());

  const handleExpandAccordion = (id: string) => {
    const accordionMap = new Map(expandedAccordions);
    const isExpanded = accordionMap.get(id);
    accordionMap.set(id, !isExpanded);
    setExpandedAccordion(accordionMap);
  };

  // state variables and functions for LIST_VARIANT.TABS
  const [selectedListTab, setSelectedListTab] = React.useState(0);
  const handleListTabChange = (event, newValue) => {
    // add item title to tab parameter so user can copy/paste/share link
    const tabSlug = items?.[newValue]?.title
      ?.replace(new RegExp(' ', 'g'), '-')
      ?.replace(new RegExp('&', 'g'), 'and')
      ?.toLowerCase();
    const url = new URL(location?.href);
    url.searchParams.set('tab', tabSlug);
    window.history.pushState(null, document.title, url);

    // navigate to update url history
    navigate('', {
      state: {
        tab: tabSlug,
      },
      replace: true,
    });

    // set selected bio
    setSelectedListTab(newValue);
  };
  React.useEffect(() => {
    if (variant === LIST_VARIANT.TABS) {
      // if we have tabs, analyze if url contains query parameters
      // to indicate selected tab
      const params = new URLSearchParams(location?.search);
      const tabParameter = params.get('tab');
      if (tabParameter) {
        // find related tab title and set selected tab if found
        const itemIndex = items?.findIndex((item) => {
          const itemTitleSlug = item.title
            ?.replace(new RegExp(' ', 'g'), '-')
            ?.replace(new RegExp('&', 'g'), 'and')
            ?.toLowerCase();
          return itemTitleSlug === tabParameter;
        });

        if (itemIndex) {
          setSelectedListTab(itemIndex);
        }
      }
    }
  }, [variant, items]);

  let listVariant = <></>;
  if (variant === LIST_VARIANT.NUMBERED) {
    listVariant = (
      <section className={`container ${className ?? ''}`}>
        {title && (
          <Typography as="h2" variant="h1" className="mb-m3">
            {title}
          </Typography>
        )}
        {items?.length > 0 && (
          <ol className="flex flex-col">
            {items?.map((item, index) => (
              <li
                key={item.id}
                className="mt-m2 first:mt-0 md:grid grid-cols-10"
              >
                <div className="col-span-2">
                  <div className="flex items-center">
                    <div className="bg-secondary h-[2px] w-1/2 hidden md:block"></div>
                    <div className="flex-none md:w-1/2 md:text-right">
                      <Typography
                        as="div"
                        variant="s1"
                        className="inline-block leading-none border-b-2 border-secondary pb-s1 md:pb-0 md:block md:border-none"
                      >
                        {`${index + 1}`.padStart(2, '0')}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className="col-span-8 mt-s2 md:mt-0 md:pl-s4">
                  {item.title && (
                    <Typography
                      as="h3"
                      variant="h3"
                      className="leading-none mb-2"
                    >
                      {item.title}
                    </Typography>
                  )}
                  {item.subheading && (
                    <Typography as="div" variant="body" className="mt-s1">
                      {item.subheading?.subheading}
                    </Typography>
                  )}
                  {item.body && (
                    <div>
                      {renderContentfulRichText(
                        item.body as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                      )}
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ol>
        )}
      </section>
    );
  } else if (variant === LIST_VARIANT.UNNUMBERED) {
    listVariant = items && items.length > 0 && (
      <section className={`container ${className ?? ''}`}>
        <ul>
          {items?.map((item) => (
            <li
              key={item.id}
              className="mt-m3 first:mt-0 md:grid md:grid-cols-10 md:grid-flow-row-dense md:gap-x-s3"
            >
              <div className="md:col-span-6 md:col-start-5">
                {item.title && (
                  <Typography as="h3" variant="h3" className="leading-none">
                    {item.title}
                  </Typography>
                )}
                {item.subheading && (
                  <Typography as="p" variant="body" className="mt-s2">
                    {item.subheading?.subheading}
                  </Typography>
                )}
                {item.body && (
                  <div>
                    {renderContentfulRichText(
                      item.body as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                    )}
                  </div>
                )}
              </div>
              <div className="mt-s4 md:mt-0 md:col-span-4 md:col-start-1">
                {item.image && (
                  <>
                    {item.externalUrl ? (
                      <a
                        href={item.externalUrl}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <ContentfulImage
                          image={item.image}
                          alt={item.image?.description || ''}
                        />
                        <span className="sr-only">
                          {item.image?.description || item.title}
                        </span>
                      </a>
                    ) : (
                      <ContentfulImage
                        image={item.image}
                        alt={item.image?.description || ''}
                      />
                    )}
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      </section>
    );
  } else if (variant === LIST_VARIANT.ACCORDION) {
    listVariant = (
      <section className={`container ${className ?? ''}`}>
        {title && (
          <Typography as="h2" variant="h2" className="mb-m1 md:mb-m4">
            {title}
          </Typography>
        )}
        {items?.length > 0 && (
          <ul className="flex flex-col">
            {items?.map((item) => (
              <li key={item.id} className="mt-s3 first:mt-0">
                <Accordion
                  expanded={expandedAccordions.get(item.id)}
                  onChange={() => handleExpandAccordion(item.id)}
                  square
                >
                  {item.title && (
                    <AccordionSummary
                      expandIcon={
                        expandedAccordions.get(item.id) ? <Remove /> : <Add />
                      }
                      aria-controls={`${item?.id}-content`}
                      id={`${item.id}-header`}
                    >
                      <Typography as="h3" variant="h4">
                        {item.title}
                      </Typography>
                    </AccordionSummary>
                  )}
                  <AccordionDetails>
                    {item.subheading && (
                      <Typography as="div" variant="body">
                        {item.subheading?.subheading}
                      </Typography>
                    )}
                    {item.body && (
                      <div>
                        {renderContentfulRichText(
                          item.body as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                        )}
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              </li>
            ))}
          </ul>
        )}
        <Typography variant="date" className="flex mt-s2">
          {renderContentfulRichText(
            footnote as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
            footnoteRichTextOptions,
          )}
        </Typography>
      </section>
    );
  } else if (variant === LIST_VARIANT.TABS) {
    listVariant = (
      <>
        <div className={`container ${className ?? ''}`}>
          <StyledTabs
            value={selectedListTab}
            onChange={handleListTabChange}
            aria-label="Category Tabs"
            scrollButtons="auto"
            variant="scrollable"
          >
            {items?.map((item, index) => {
              return (
                <StyledTab
                  key={`list-tab-${item.id}`}
                  label={item.title}
                  id={`list-tab-${index}`}
                  aria-controls={`list-tab-panel-${index}`}
                />
              );
            })}
          </StyledTabs>
        </div>
        {items?.map((item, index) => {
          return (
            <div
              key={`list-tab-panel-${item.id}`}
              role="tabpanel"
              hidden={selectedListTab !== index}
              id={`list-tab-panel-${index}`}
              aria-labelledby={`list-tab-${index}`}
            >
              {item.components?.map((component) => {
                return (
                  <ComponentSelector
                    key={component.id}
                    contentfulComponent={component}
                  />
                );
              })}
            </div>
          );
        })}
      </>
    );
  }

  return listVariant;
};

export default List;
