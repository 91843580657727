import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import { ContentfulGlobalMicrocopyCopy } from '../../graphql-types';
import { Info } from '@material-ui/icons';
import Microcopy from './Microcopy';
import Typography from './Typography';

// Using Material UI's withStyles instead of styled component as the tooltip is a rendered as a global element
const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white',
    border: '1px solid black',
    color: 'black',
    borderRadius: 0,
    maxWidth: 640,
    margin: '-8px 0 0 0',
  },
})(Tooltip);

interface InfoTooltipProps {
  copy: ContentfulGlobalMicrocopyCopy;
}

const InfoTooltip: React.FC<InfoTooltipProps> = (props) => {
  const { copy } = props;

  return (
    <StyledTooltip
      placement="bottom-start"
      title={
        <Typography as="small" variant="footerBody3">
          <Microcopy copy={copy} />
        </Typography>
      }
    >
      <sup>
        <Info fontSize="small" className="text-black" />
      </sup>
    </StyledTooltip>
  );
};

export default InfoTooltip;
