import React from 'react';
import styled from 'styled-components';
import {
  ContentfulAsset,
  ContentfulComponentCtaButton,
  ContentfulComposePage,
} from '../../graphql-types';
import { useLocalization } from 'gatsby-theme-i18n';
import { WEBSITE } from '../types/website.enum';
import { ChevronLeft } from '@material-ui/icons';
import Button from './Button';
import ContentfulImage from './ContentfulImage';
import { useGlobalState } from '../hooks/useGlobalState';
import Typography from './Typography';
import { getTranslation } from '../services/translation.service';
import Link from './Link';

const HeroBannerSplitCymbriaBox = styled.div`
  background-color: ${(props) => props.theme.color.white};
  position: relative;
  z-index: 1;
  -webkit-clip-path: polygon(
    0 0,
    0 100%,
    calc(100% - ${(props) => props.theme.spacing.s4}) 100%,
    100% calc(100% - ${(props) => props.theme.spacing.s4}),
    100% 0
  );
  clip-path: polygon(
    0 0,
    0 100%,
    calc(100% - ${(props) => props.theme.spacing.s4}) 100%,
    100% calc(100% - ${(props) => props.theme.spacing.s4}),
    100% 0
  );

  padding: ${(props) => props.theme.spacing.m4};
`;

interface HeroBannerProps {
  body?: string;
  buttons?: ContentfulComponentCtaButton[];
  className?: string;
  header?: string;
  image?: ContentfulAsset;
  variant?: string;
}
const HeroBanner: React.FC<HeroBannerProps> = (props) => {
  const { body, buttons, className, header, image, variant } = props;
  const { pageContext, site } = useGlobalState();
  const { locale } = useLocalization();
  const backToString = getTranslation('BackTo', locale);

  let heroBannerVariant = <></>;
  if (variant === 'centered') {
    heroBannerVariant = (
      <div
        className={`relative sm:min-h-[573px] flex flex-col sm:flex-row sm:items-center ${
          className || ''
        }`}
      >
        {image && (
          <div className="sm:absolute sm:inset-0 overflow-hidden z-0">
            <ContentfulImage
              image={image}
              alt={image.description || ''}
              style={{ width: '100%', height: '100%' }}
              className="min-h-[268px]"
              loading="eager"
            />
          </div>
        )}
        <div
          className={`relative container ${
            site === WEBSITE.CYMBRIA ? 'max-w-[728px]' : ''
          } -mt-l4 sm:mt-0`}
        >
          <div
            className={`mx-s3 px-s2 py-s4 sm:mx-0 sm:px-0 sm:py-m2 bg-white sm:grid sm:grid-cols-10 ${
              site === WEBSITE.EDGEPOINT ? 'border border-secondary' : ''
            } ${site === WEBSITE.CYMBRIA ? 'clip-corner' : ''}`}
          >
            <div className="sm:col-span-8 sm:col-start-2">
              {header && (
                <Typography as="h1" variant="h1" className={`text-center`}>
                  {header}
                </Typography>
              )}
              {body && (
                <Typography
                  as="p"
                  variant={variant === 'centered' ? 'body' : 'callout'}
                  className={`mt-s2 text-center`}
                >
                  {body}
                </Typography>
              )}
              {buttons && (
                <ul
                  className={`mt-s4 flex flex-col justify-center items-center sm:flex-row`}
                >
                  {buttons?.map((button) => {
                    return (
                      <li
                        key={`button-list-item-${button.id}`}
                        className="mt-s3 first:mt-0 sm:mt-0 sm:ml-s3 first:ml-0 "
                      >
                        <Button
                          variant={button.variant}
                          link={button?.page?.slug || button?.externalUrl}
                          openInNewWindow={button?.openInNewWindow}
                        >
                          {button.text}
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (variant === 'split' && site === WEBSITE.EDGEPOINT) {
    heroBannerVariant = (
      <div className={`${className || ''}`}>
        {image && (
          <div className="h-[270px] md:h-[480px] overflow-hidden">
            <ContentfulImage
              image={image}
              alt={image.description || ''}
              style={{ width: '100%', height: '100%' }}
              loading="eager"
            />
          </div>
        )}
        <div className="container md:grid md:grid-cols-10 md:gap-x-s3">
          <div className="relative mt-[-100px] md:mt-[-200px] px-s1 py-s4 md:p-m3 bg-white border-secondary border md:col-span-6">
            {pageContext?.parentPage && (
              <Link
                language={locale}
                link={{
                  page: pageContext?.parentPage as ContentfulComposePage,
                }}
              >
                <Typography
                  as="div"
                  variant="footerBody3"
                  className="inline-flex items-center"
                >
                  <ChevronLeft className="text-secondary" />
                  {backToString} {pageContext?.parentPage?.title}
                </Typography>
              </Link>
            )}
            {header && (
              <Typography
                as="h1"
                variant="h1"
                className="text-center md:text-left"
              >
                {header}
              </Typography>
            )}
            {body && (
              <Typography
                as="p"
                variant="callout"
                className="mt-s2 text-center md:text-left"
              >
                {body}
              </Typography>
            )}
            {buttons && (
              <ul className={`flex mt-s2 justify-center md:justify-start`}>
                {buttons?.map((button) => {
                  return (
                    <li key={`button-list-item-${button.id}`}>
                      <Button
                        variant={button.variant}
                        link={button?.page?.slug || button?.externalUrl}
                        openInNewWindow={button?.openInNewWindow}
                      >
                        {button.text}
                      </Button>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  } else if (variant === 'split' && site === WEBSITE.CYMBRIA) {
    heroBannerVariant = (
      <div className={`${className || ''} relative`}>
        {image && (
          <div
            style={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              width: '100%',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <ContentfulImage
              image={image}
              alt={image.description || ''}
              style={{
                width: '100%',
                height: '100%',
              }}
              loading="eager"
            />
          </div>
        )}
        <div className={`container py-l3`}>
          <HeroBannerSplitCymbriaBox className="lg:w-80">
            {header && (
              <Typography as="h1" variant="h1">
                {header}
              </Typography>
            )}
            {body && (
              <Typography as="p" variant="callout" className={`mt-s2`}>
                {body}
              </Typography>
            )}
            {buttons && (
              <ul className={`flex mt-s2`}>
                {buttons?.map((button) => {
                  return (
                    <li key={`button-list-item-${button.id}`}>
                      <Button
                        variant={button.variant}
                        link={button?.page?.slug || button?.externalUrl}
                        openInNewWindow={button?.openInNewWindow}
                      >
                        {button.text}
                      </Button>
                    </li>
                  );
                })}
              </ul>
            )}
          </HeroBannerSplitCymbriaBox>
        </div>
      </div>
    );
  }

  return heroBannerVariant;
};

export default HeroBanner;
