import { Auth } from 'aws-amplify';
import { WEBSITE } from '../types/website.enum';
import { isBrowser } from '../utils/isBrowser';

export interface AWSCurrentUserInfo {
  username: string;
  attributes: {
    sub: string;
    email_verified: boolean;
    email: string;
  };
}

export const isLoggedIn = async (): Promise<boolean> => {
  if (!isBrowser() || process.env.GATSBY_GLOBAL_WEBSITE === WEBSITE.EDGEPOINT)
    return false;

  try {
    const currentUserInfo: AWSCurrentUserInfo = await Auth.currentUserInfo();
    return currentUserInfo?.username != null;
  } catch (error) {
    // no current user
    return false;
  }
};

export const getCurrentSessionToken = async (): Promise<string> => {
  if (!isBrowser()) return null;

  try {
    const currentSession = await Auth.currentSession();
    if (!currentSession) {
      return null;
    }
    const jwtToken = currentSession.getAccessToken().getJwtToken();
    return jwtToken;
  } catch (error) {
    // no current user
    return null;
  }
};
