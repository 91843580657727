import React from 'react';
import {
  ContentfulComponentCtaButton,
  ContentfulGlobalMicrocopy,
} from '../../graphql-types';
import SignUpLogin from './SignUpLogin';
import SignUpSubscription from './SignUpSubscription';

interface SignUpProps {
  className?: string;
  title?: string;
  button?: ContentfulComponentCtaButton;
  forgotPasswordLink?: ContentfulComponentCtaButton;
  variant?: string;
  footnote?: string;
  darkMode?: boolean;
  formFieldLabels?: ContentfulGlobalMicrocopy[];
}
const SignUp: React.FC<SignUpProps> = (props) => {
  const { darkMode, variant } = props;
  const darkModeClass = 'bg-greyLight';

  let signUpVariant = <></>;
  switch (variant) {
    case 'sign-up': {
      signUpVariant = <SignUpSubscription {...props} />;
      break;
    }
    case 'login': {
      signUpVariant = <SignUpLogin {...props} />;
      break;
    }
  }

  return darkMode ? (
    <div className={`wide ${darkModeClass}`}>{signUpVariant}</div>
  ) : (
    signUpVariant
  );
};

export default SignUp;
