import React from 'react';
import { useLocalization } from 'gatsby-theme-i18n';
import {
  ContentfulComponentCtaButton,
  ContentfulTopicCategory,
  ContentfulTopicPortfolio,
  ContentfulTopicPortfolioSeries,
} from '../../graphql-types';
import {
  CircularProgress,
  Fade,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import { ArrowRight, ChevronRight, Info } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { Alert } from '@material-ui/lab';
import Button from './Button';
import Typography from './Typography';
import { WEBSITE } from '../types/website.enum';
import Divider from './Divider';
import { useGlobalState } from '../hooks/useGlobalState';
import Microcopy from './Microcopy';
import { getLastBusinessDate } from '../services/date.service';
import {
  CONTENTFUL_FUNDATA_SOURCE_OPTIONS,
  getFundataV2,
} from '../services/fundata-v2.service';
import { PricingResponse } from '../services/fundata-v2.types';
import {
  formatLocalizedCurrency,
  formatLocalizedDate,
} from '../services/localization.service';
import { isLoggedIn, getCurrentSessionToken } from '../services/login.service';
import { getShares } from '../services/shares.service';
import { getTranslation } from '../services/translation.service';
import Link from './Link';

// Using Material UI's withStyles instead of styled component as the tooltip is a rendered as a global element
const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white',
    border: '1px solid black',
    color: 'black',
    borderRadius: 0,
  },
})(Tooltip);

interface FundataPortfolioSetProps {
  className?: string;
  title?: string;
  subheading?: string;
  button?: ContentfulComponentCtaButton;
  cymbriaLoginButton?: ContentfulComponentCtaButton;
  series?: ContentfulTopicCategory[];
  portfolios?: ContentfulTopicPortfolio[];
  variant?: string;
}
const FundataPortfolioSet: React.FC<FundataPortfolioSetProps> = (props) => {
  const {
    className,
    title,
    subheading,
    button,
    cymbriaLoginButton,
    series: seriesCategory,
    portfolios,
    variant,
  } = props;
  const { site } = useGlobalState();
  const { locale } = useLocalization();
  const [showClassJLoginButton, setShowClassJLoginButton] =
    React.useState(false);

  const lastBusinessDay = getLastBusinessDate();
  const formattedDate = formatLocalizedDate(lastBusinessDay, locale, {
    month: 'long',
  });
  const [lastUpdatedDate] = React.useState(formattedDate);

  // state for series selector
  const [selectedSeriesCategoryId, setSelectedSeriesCategoryId] =
    React.useState<string>('');
  const handleChange = (e: React.ChangeEvent<{ value: string }>) => {
    if (selectedSeriesCategoryId) setSelectedSeriesCategoryId(e.target.value);
  };

  const [renderedElements, setRenderedElements] = React.useState<JSX.Element[]>(
    [],
  );

  // data for portfolios
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [pricingMap, setPricingMap] = React.useState<
    Map<string, PricingResponse | void>
  >(new Map());

  const endpoint = CONTENTFUL_FUNDATA_SOURCE_OPTIONS.PRICING;
  const fetchPortfolioPrices = async () => {
    // reset state variables
    setIsLoading(true);
    setIsError(false);

    const lastBusinessDayYYYYMMDD = lastBusinessDay
      ?.toISOString()
      ?.split('T')?.[0];
    const prices: Map<string, PricingResponse | void> = new Map();
    const allSeries = portfolios?.flatMap?.((portfolio) => portfolio?.series);
    const results = await Promise.all(
      allSeries?.map(async (portfolioSeries) => {
        if (portfolioSeries.isGated) {
          // if user is not logged in, skip series
          const isUserLoggedIn = await isLoggedIn();
          if (!isUserLoggedIn) {
            return Promise.resolve();
          }

          // use private shares endpoint to retrieve data
          const sessionToken = await getCurrentSessionToken();
          const result = await getShares(
            endpoint,
            sessionToken,
            lastBusinessDayYYYYMMDD,
          ).catch(() => {
            // swallow errors
          });

          if (!result) {
            // swallow errors so outer promise isn't broken
            return Promise.resolve();
          }

          prices.set(portfolioSeries.instrumentKey, result as PricingResponse);
          return result;
        } else {
          return getFundataV2<PricingResponse>(
            endpoint,
            locale,
            portfolioSeries.instrumentKey,
            lastBusinessDayYYYYMMDD,
          )
            .then((response) => {
              if (!response) {
                // eslint-disable-next-line no-console
                console.warn(
                  `Error fetching pricing for ${portfolioSeries.title}`,
                );
              }
              prices.set(portfolioSeries.instrumentKey, response);
              return response;
            })
            .catch(() => {
              // swallow errors
            });
        }
      }),
    )
      .then((responses) => {
        if (responses.every((response) => response === undefined)) {
          // only show error message when every response is errored
          // otherwise, we will show a dash - where the price is
          setIsError(true);
        }
        return responses;
      })
      .finally(() => {
        setIsLoading(false);
      });

    setPricingMap(prices);
  };

  React.useEffect(() => {
    if (seriesCategory && seriesCategory.length > 0) {
      // set default series
      setSelectedSeriesCategoryId(seriesCategory[0].id);
    }
  }, [seriesCategory]);

  React.useEffect(() => {
    if (portfolios && portfolios.length > 0) {
      fetchPortfolioPrices();
    }
  }, [portfolios]);

  React.useEffect(() => {
    isLoggedIn().then((loggedIn) => {
      const showLoginButton =
        cymbriaLoginButton && site === WEBSITE.CYMBRIA && !loggedIn;
      setShowClassJLoginButton(showLoginButton);
    });
  }, [cymbriaLoginButton, site]);

  React.useEffect(() => {
    // render elements to state based on variant
    let newRenderedElements = [];

    // need to separate out gated series if not logged in
    isLoggedIn().then((isUserLoggedIn) => {
      switch (variant) {
        case 'list': {
          newRenderedElements = portfolios?.map(renderSeriesListItem);
          break;
        }
        case 'split': {
          newRenderedElements = portfolios?.map((portfolio) =>
            portfolio.series
              ?.filter((series) => isUserLoggedIn || !series.isGated)
              ?.map((series, index) =>
                renderSeriesListItemSplit(portfolio, series, index),
              ),
          );
          break;
        }
      }
      setRenderedElements(newRenderedElements);
    });
  }, [selectedSeriesCategoryId, portfolios, pricingMap, variant]);

  const renderSeriesListItem = (
    portfolio: ContentfulTopicPortfolio,
    index: number,
  ) => {
    const selectedSeries = portfolio.series?.find(
      (series) => series?.series?.id === selectedSeriesCategoryId,
    );
    const portfolioPriceResponse = pricingMap.get(
      selectedSeries?.instrumentKey,
    );
    const price =
      portfolioPriceResponse && portfolioPriceResponse?.NAVPS
        ? formatLocalizedCurrency(portfolioPriceResponse?.NAVPS, locale)
        : null;

    return (
      <div
        key={`series-list-item-${index}`}
        className={`flex md:flex-col md:justify-end w-full h-full mt-s1 md:mt-0`}
      >
        {portfolio.title && (
          <Link
            language={locale}
            link={{
              page: portfolio?.compose__page?.[0],
            }}
            key={`portfolio-${portfolio.id}-series-${selectedSeries?.id}`}
            className="no-underline hover:underline"
          >
            <Typography
              variant={`${site === WEBSITE.EDGEPOINT ? 'subheading' : 'tag'}`}
              className="uppercase"
            >
              <span className="">{portfolio.title}</span>
              <ArrowRight className="text-secondary" />
            </Typography>
          </Link>
        )}
        {!price ? (
          <Typography
            as="div"
            variant="s2"
            className="ml-auto pl-m2 md:pl-0 md:ml-0 md:mt-auto"
          >
            -
          </Typography>
        ) : (
          <Typography
            as="div"
            variant="s2"
            className="ml-auto pl-m2 md:pl-0 md:ml-0 md:mt-s3"
          >
            {price}
          </Typography>
        )}
      </div>
    );
  };

  const renderSeriesListItemSplit = (
    portfolio: ContentfulTopicPortfolio,
    series: ContentfulTopicPortfolioSeries,
    index: number,
  ) => {
    const seriesResponse = pricingMap.get(series.instrumentKey);

    const price =
      seriesResponse && seriesResponse?.NAVPS
        ? formatLocalizedCurrency(seriesResponse?.NAVPS, locale)
        : null;

    return (
      <div
        key={`portfolio-${portfolio.id}-series-${series.id}-${index}`}
        className="ml-s4 first:ml-0 lg:ml-0"
      >
        {portfolio.category?.title && (
          <Typography as="div" variant="subheading" className="uppercase">
            {portfolio.category?.title}
          </Typography>
        )}
        {series.title && (
          <Typography
            as="div"
            variant={`${site === WEBSITE.EDGEPOINT ? 'subheading' : 'tag'}`}
            className="mt-s2 uppercase"
          >
            {series.title}
          </Typography>
        )}
        {lastUpdatedDate && (
          <Typography
            as="div"
            variant="date"
            className="mt-s2 text-greyText w-full"
          >
            {!isLoading && (
              <>
                {locale === 'en' && getTranslation('AsAt', locale)}
                {locale === 'fr' &&
                  `${getTranslation('PortfolioValue', locale)} ${getTranslation(
                    'AsAt',
                    locale,
                  )?.toLowerCase()}`}
                {` `}
                {lastUpdatedDate}
              </>
            )}
          </Typography>
        )}
        {!price ? (
          <Typography as="div" variant="s1" className="mt-s2 lg:mt-s4">
            -
          </Typography>
        ) : (
          <Typography as="div" variant="s1" className="mt-s2 lg:mt-s4">
            {price}
            {portfolio.additionalInfo?.copy && (
              <StyledTooltip
                title={
                  <React.Fragment>
                    <Typography as="small" variant="footerBody3">
                      <Microcopy copy={portfolio.additionalInfo?.copy} />
                    </Typography>
                  </React.Fragment>
                }
              >
                <sup>
                  <Info fontSize="small" />
                </sup>
              </StyledTooltip>
            )}
          </Typography>
        )}
      </div>
    );
  };

  let portfolioSetContent = <></>;

  switch (variant) {
    case 'list': {
      portfolioSetContent = (
        <div className={`${className || ''} py-m4`}>
          <div className="container flex flex-col md:flex-row">
            <div className="">
              {title && (
                <Typography
                  as="h2"
                  variant="h1"
                  className="text-center md:text-left"
                >
                  {title}
                </Typography>
              )}
              {subheading && (
                <Typography
                  as="p"
                  variant="body"
                  className="text-center md:text-left"
                >
                  {subheading}
                </Typography>
              )}
              {button && (
                <Button
                  variant={button.variant}
                  link={button?.page?.slug || button?.externalUrl}
                  openInNewWindow={button?.openInNewWindow}
                >
                  {button.text}
                </Button>
              )}
            </div>

            <div className="mt-s2 md:mt-0 md:ml-auto flex flex-col items-center md:w-1/2 lg:w-1/4">
              <Select
                className="w-full max-w-[300px]"
                value={selectedSeriesCategoryId}
                onChange={handleChange}
              >
                {seriesCategory?.map((item, index) => (
                  <MenuItem key={`series-category-${index}`} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
              {lastUpdatedDate && (
                <Typography
                  as="div"
                  variant="date"
                  className="mt-s1 text-greyDark w-full"
                >
                  {!isLoading && (
                    <>
                      {getTranslation('AsAt', locale)}
                      {` `}
                      {lastUpdatedDate}
                    </>
                  )}
                </Typography>
              )}
            </div>
          </div>

          <Fade in={isLoading} unmountOnExit>
            <div className="container pt-l1 flex justify-center">
              <CircularProgress aria-label="Loading data" />
            </div>
          </Fade>
          {!isLoading && renderedElements && renderedElements.length > 0 && (
            <div className="container md:grid md:grid-cols-2 lg:grid-cols-4 md:grid-flow-row md:auto-cols-fr mt-s3 gap-y-s4 md:gap-x-s3">
              {renderedElements}
            </div>
          )}
          {!isLoading && isError && (
            <div className="container mt-m1">
              <Alert severity="info">
                <strong>Sorry!</strong> Data temporarily unavailable, but we
                {`'`}re working to get it back soon.
              </Alert>
            </div>
          )}
        </div>
      );
      break;
    }
    case 'split': {
      portfolioSetContent = (
        <>
          <div className="container flex flex-col lg:flex-row pt-l4 pb-l1">
            <div className="flex-none lg:w-40">
              {title && (
                <Typography as="h2" variant="h2" className="">
                  {title}
                </Typography>
              )}
              {subheading && (
                <Typography as="p" variant="body" className="mt-s2">
                  {subheading}
                </Typography>
              )}
            </div>
            <div className="flex-none lg:w-10"></div>
            <div className="flex-none lg:w-30">
              <Fade in={isLoading} unmountOnExit>
                <div className="container pt-s3 flex justify-center">
                  <CircularProgress aria-label="Loading data" />
                </div>
              </Fade>
              {!isLoading &&
                renderedElements &&
                renderedElements.length > 0 && (
                  <div className="flex flex-row lg:flex-col mt-s3 lg:mt-0">
                    {renderedElements}
                  </div>
                )}
            </div>
            {(button || cymbriaLoginButton) && (
              <div className="flex-none lg:flex lg:flex-col lg:justify-end lg:w-20">
                <div className="mt-s4 lg:mt-auto inline-flex flex-col">
                  {showClassJLoginButton && (
                    <Button
                      variant={cymbriaLoginButton.variant}
                      link={
                        cymbriaLoginButton.page?.slug ||
                        cymbriaLoginButton.externalUrl
                      }
                      openInNewWindow={cymbriaLoginButton.openInNewWindow}
                      parameters={cymbriaLoginButton.urlParameters}
                    >
                      <span>
                        {cymbriaLoginButton.text}
                        <ChevronRight className="ml-1" />
                      </span>
                    </Button>
                  )}
                  {button && (
                    <Button
                      variant={button.variant}
                      link={button.page?.slug || button.externalUrl}
                      openInNewWindow={button.openInNewWindow}
                      className={showClassJLoginButton ? 'mt-s3' : ''}
                      parameters={button.urlParameters}
                    >
                      {button.text}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
          {!isLoading && isError && (
            <div className="container mb-s3">
              <Alert severity="info">
                <strong>Sorry!</strong> Data temporarily unavailable, but we
                {`'`}re working to get it back soon.
              </Alert>
            </div>
          )}
          <Divider color="primary" />
        </>
      );
      break;
    }
  }

  return portfolioSetContent;
};

export default FundataPortfolioSet;
