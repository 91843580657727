import { HttpStatusCodeError } from '../types/httpStatusCodeError';

export async function checkStatusCode<T>(response: Response): Promise<T> {
  // let's make sure to throw an error when status code isn't 200
  if (response.status < 200 || response.status >= 300) {
    const contentType = response.headers.get('content-type');
    let reason = '';
    if (contentType && contentType.indexOf('application/json') !== -1) {
      const json = await response.json();
      reason = json?.message;
    } else {
      reason = await response.text();
    }
    return Promise.reject(new HttpStatusCodeError(reason, response.status));
  }

  return response.json();
}
