import React from 'react';
import {
  ContentfulComponentCtaButton,
  ContentfulComponentFlexibleRichText,
  ContentfulDesignAppearance,
  ContentfulGlobalMicrocopy,
} from '../../graphql-types';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import { IContentfulAsset } from '../types/contentfulAsset.interface';
import ContentfulImage from './ContentfulImage';
import Podcasts from './Podcasts';
import Divider, { HiddenOnOptions } from './Divider';
import SignUp from './SignUp';

interface FlexibleProps {
  layout?: string;
  alignment?: string;
  className?: string;
  richText?: ContentfulComponentFlexibleRichText;
  image?: IContentfulAsset;
  primaryText?: string;
  secondaryText?: string;
  links?: ContentfulComponentCtaButton[];
  appearance?: ContentfulDesignAppearance;
  formVariant?: string;
  formFieldLabels?: ContentfulGlobalMicrocopy[];
}

const Flexible: React.FC<FlexibleProps> = (props) => {
  const {
    alignment,
    className,
    image,
    layout,
    richText,
    primaryText,
    secondaryText,
    links,
    formVariant,
    formFieldLabels,
    appearance,
  } = props;

  const isFullWidth = appearance?.variation === 'Full Width' ?? false;
  const darkMode = appearance?.theme === 'Dark' ?? false;

  switch (layout) {
    case 'TextImageWrap': {
      return (
        <div className={`${className ?? ''}`}>
          <ContentfulImage
            image={image}
            alt={image.description ?? ''}
            className={`mb-s1 ${
              alignment !== 'left' ? 'float-left mr-s3' : 'float-right ml-s3'
            }`}
          />
          {richText && renderContentfulRichText(richText)}
        </div>
      );
    }
    case 'Podcast': {
      return (
        <Podcasts
          title={primaryText}
          subheading={secondaryText}
          applePodcastsUrl={links?.[0]?.externalUrl}
          spotifyUrl={links?.[1]?.externalUrl}
          className={className}
        />
      );
    }
    case 'Divider': {
      return (
        <Divider
          thick={appearance?.thick}
          fullWidth={isFullWidth}
          hiddenOn={appearance?.hiddenOn as HiddenOnOptions}
          className={className}
        />
      );
    }
    case 'SignUp': {
      return (
        <SignUp
          title={primaryText}
          darkMode={darkMode}
          variant={formVariant}
          formFieldLabels={formFieldLabels}
          className={className}
        />
      );
    }
    case 'Default':
    default: {
      return (
        <>
          {richText &&
            renderContentfulRichText(
              richText,
              null,
              `${
                alignment === 'indent'
                  ? 'ml-m4 md:ml-l4'
                  : alignment === 'right'
                  ? 'text-right'
                  : alignment === 'centre'
                  ? 'text-center'
                  : 'text-left'
              }${className != null ? ` ${className}` : ''}`,
            )}
        </>
      );
    }
  }
};

export default Flexible;
