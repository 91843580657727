import React from 'react';
import styled from 'styled-components';
import { typography } from '../themes/base';

export type VariantKey = keyof typeof typography;

interface TypographyContainerProps {
  variant?: VariantKey;
}
export const Container = styled.div<TypographyContainerProps>`
  color: inherit;

  ${(props) => {
    const mobileTypography = props.theme.mobileTypography[props.variant];
    const typography = props.theme.typography[props.variant];

    if (typography) {
      if (mobileTypography) {
        // use mobile typography on smaller screens if it's available
        return `
        ${mobileTypography}

        @media (min-width: ${props.theme.breakpoint.md}) {
          ${typography}
        }
      `;
      } else {
        return typography;
      }
    } else {
      return `
      background-color: red;
      font-size: ${props.theme.fontSize.body};
      `;
    }
  }}
`;
interface TypographyProps {
  as?: keyof JSX.IntrinsicElements;
  variant?: VariantKey;
  children?: React.ReactNode;
  className?: string;
}
const Typography: React.FC<TypographyProps> = ({
  as = 'span',
  variant = 'body',
  children,
  className,
}) => {
  return (
    <Container as={as} variant={variant} className={`${className || ``}`}>
      {children}
    </Container>
  );
};

export default Typography;
