import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'yup-phone';
import { CircularProgress, Fade, TextField } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { WEBSITE } from '../types/website.enum';
import { useGlobalState } from '../hooks/useGlobalState';
import Typography from './Typography';
import Divider from './Divider';
import styled, { ThemeContext } from 'styled-components';
import Button from './Button';
import { submitContactInformation } from '../services/contact.service';

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 0px;
    background-color: ${(props) => props.theme.color.white};

    & fieldset {
      border-color: ${(props) => props.theme.color.primary};
    }
  }
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    white-space: nowrap;
  }
`;

interface ContactUsProps {
  submitButton?: { variant?: string; text?: string };
  className?: string;
  copy?: Map<string, string>;
  businessInfo?: JSX.Element[];
  endpointUrl?: string;
  formId?: string;
  layout?: string;
  title?: string;
  type?: string;
}

const ContactUs: React.FC<ContactUsProps> = (props) => {
  const {
    businessInfo,
    className,
    copy,
    endpointUrl,
    formId,
    layout,
    submitButton,
    title,
    type,
  } = props;

  const theme = React.useContext(ThemeContext);
  const { site } = useGlobalState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [formState, setFormState] = React.useState<
    'initial' | 'success' | 'error'
  >('initial');

  // yup validation schema for formik
  const validationSchema = yup.object({
    name: yup
      .string()
      .min(1, copy?.get('name-error'))
      .required(copy?.get('name-error')),
    email: yup
      .string()
      .email(copy?.get('email-error'))
      .required(copy?.get('email-error')),
    postalCode: yup
      .string()
      .matches(/[A-Za-z]\d[A-Za-z][\s-]?\d[A-Za-z]\d/g, {
        message: copy?.get('postal-code-error'),
        excludeEmptyString: true,
      })
      .notRequired(),
    phoneNumber: yup
      .string()
      .phone('CA', false, copy?.get('phone-number-error'))
      .required(copy?.get('phone-number-error')),
    ext: yup.string(),
    subject: yup.string(),
    organization: yup.string(),
    message: yup.string().required(copy?.get('message-error')),
    recaptchaToken: yup.string().required(),
  });

  // formik object for form fields
  const formik = useFormik<{
    name: string;
    email: string;
    postalCode: string;
    phoneNumber: string;
    ext: string;
    subject: string;
    message: string;
    organization: string;
    recaptchaToken: string;
  }>({
    initialValues: {
      name: '',
      email: '',
      postalCode: '',
      phoneNumber: '',
      ext: '',
      subject: '',
      message: '',
      organization: '',
      recaptchaToken: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setFormState('initial');

      if (!values.recaptchaToken) {
        handleReCaptchaVerify();
        return;
      }

      setIsLoading(true);
      submitContactInformation(
        endpointUrl,
        formId,
        values.name,
        values.email,
        values.postalCode,
        values.phoneNumber,
        values.message,
        values.organization,
        values.recaptchaToken,
        values.subject,
        values.ext,
      )
        .then(() => {
          setFormState('success');

          setTimeout(() => {
            setFormState('initial');
          }, 5000);

          // reset form
          resetForm();
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Could not submit form', error);
          setFormState('error');
        })
        .finally(() => {
          setIsLoading(false);
          formik.setSubmitting(false);
        });
    },
  });

  const handleFormDirty = () => {
    handleReCaptchaVerify();
  };

  // reCAPTCHAv3 state and functions
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      // eslint-disable-next-line no-console
      console.error('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('contact_us');
    formik.setFieldValue('recaptchaToken', token);

    // N.B. reCAPTCHA tokens expire after 2 minutes, so we only
    // fire this function when users click on the email form
  }, [executeRecaptcha]);

  const shouldRenderOrganizationField = type === 'Institutional';

  return (
    <div className={`container pb-m3 ${className || ''}`}>
      {title && (
        <Typography as="h2" variant="h1" className="mt-l2">
          {title}
        </Typography>
      )}

      <Divider fullWidth color={theme.color.secondary} className="mt-s2" />

      {site === WEBSITE.EDGEPOINT && (
        <form
          onSubmit={formik.handleSubmit}
          onClick={handleFormDirty}
          id="contact-form"
          className={`relative`}
        >
          <Fade in={isLoading} unmountOnExit>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 pt-l1 flex justify-center">
              <CircularProgress aria-label="Loading data" />
            </div>
          </Fade>

          {copy?.get('form-title') && (
            <div className="mt-m3">
              <Typography as="h2" variant="h2">
                {copy?.get('form-title')}
              </Typography>
            </div>
          )}

          <fieldset
            className={`${isLoading ? 'opacity-60 transition-opacity' : ''}`}
            disabled={isLoading}
          >
            <div className="lg:grid grid-cols-2 gap-x-s3 lg:mt-m4">
              <div className="mt-s4 lg:mt-0 col-span-1">
                <StyledTextField
                  fullWidth
                  id="name"
                  name="name"
                  variant="outlined"
                  label={copy?.get('name')}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </div>

              <div className="mt-s4 lg:mt-0 col-span-1">
                <StyledTextField
                  fullWidth
                  id="email"
                  name="email"
                  variant="outlined"
                  label={copy?.get('email')}
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
            </div>

            <div className="lg:grid grid-cols-10 gap-x-s3 lg:mt-s4">
              <div className="mt-s4 lg:mt-0 col-span-2">
                <StyledTextField
                  fullWidth
                  id="postalCode"
                  name="postalCode"
                  variant="outlined"
                  label={copy?.get('postal-code')}
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.postalCode &&
                    Boolean(formik.errors.postalCode)
                  }
                  helperText={
                    formik.touched.postalCode && formik.errors.postalCode
                  }
                />
              </div>

              <div className="mt-s4 lg:mt-0 col-span-2">
                <StyledTextField
                  fullWidth
                  id="phoneNumber"
                  name="phoneNumber"
                  variant="outlined"
                  label={copy?.get('phone-number')}
                  type="tel"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />
              </div>

              <div className="mt-s4 lg:mt-0 col-span-1">
                <StyledTextField
                  fullWidth
                  id="ext"
                  name="ext"
                  variant="outlined"
                  label={copy?.get('ext')}
                  value={formik.values.ext}
                  onChange={formik.handleChange}
                  error={formik.touched.ext && Boolean(formik.errors.ext)}
                  helperText={formik.touched.ext && formik.errors.ext}
                />
              </div>

              {shouldRenderOrganizationField && (
                <div className="mt-s4 lg:mt-0 col-span-5">
                  <StyledTextField
                    fullWidth
                    id="organization"
                    name="organization"
                    variant="outlined"
                    label={copy?.get('organization')}
                    value={formik.values.organization}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.organization &&
                      Boolean(formik.errors.organization)
                    }
                    helperText={
                      formik.touched.organization && formik.errors.organization
                    }
                  />
                </div>
              )}

              <div
                className={`mt-s4 ${
                  shouldRenderOrganizationField
                    ? 'col-span-10'
                    : 'col-span-5 lg:mt-0'
                }`}
              >
                <StyledTextField
                  fullWidth
                  id="subject"
                  name="subject"
                  variant="outlined"
                  label={copy?.get('subject')}
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.subject && Boolean(formik.errors.subject)
                  }
                  helperText={formik.touched.subject && formik.errors.subject}
                />
              </div>
            </div>

            <div className="mt-s4">
              <StyledTextField
                fullWidth
                id="message"
                name="message"
                variant="outlined"
                label={copy?.get('message')}
                multiline
                rows={4}
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
            </div>

            <div className="flex flex-col lg:flex-row lg:mt-s4">
              <div className="mt-s4 lg:mt-0">
                <Button
                  type="submit"
                  variant={submitButton.variant}
                  disabled={formik.isSubmitting}
                >
                  <span>{submitButton.text}</span>
                </Button>

                {formState === 'success' && (
                  <div className="mt-s4 lg:mt-0">
                    <Alert severity="success" className="mt-s2">
                      <AlertTitle>
                        {/* TODO: Insert form-success microcopy to contact form
                        and use here! */}
                        Thank you! Your message was sent and we{`'`}ll get back
                        to you soon.
                      </AlertTitle>
                    </Alert>
                  </div>
                )}

                {formState === 'error' && (
                  <div className="mt-s4 lg:mt-0">
                    <Alert severity="error" className="mt-s2">
                      <AlertTitle>
                        {/* TODO: Insert form-error microcopy to contact form
                        and use here! */}
                        Sorry! An error occurred while submitting.
                      </AlertTitle>
                    </Alert>
                  </div>
                )}
              </div>

              {copy?.get('footnote') && (
                <div className="mt-s4 lg:mt-0 lg:ml-auto">
                  {copy?.get('footnote')}
                </div>
              )}
            </div>
          </fieldset>
        </form>
      )}

      {businessInfo && (
        <div className={`flex flex-col lg:flex-row lg:flex-wrap`}>
          {businessInfo.map((child, i) => {
            // We have to use static strings instead of `w-1/${infoColumns}` due to the way
            // tailwindcss works: https://tailwindcss.com/docs/just-in-time-mode#arbitrary-value-support
            const columnClass = layout === '3-Column' ? 'lg:w-1/3' : 'lg:w-1/2';
            return (
              <div
                key={i}
                className={`${
                  site === WEBSITE.EDGEPOINT ? 'mt-m4' : 'mt-m3'
                } ${columnClass}`}
              >
                {child}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ContactUs;
