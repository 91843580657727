import { utils } from 'xlsx';
import {
  ContentfulTopicCell,
  ContentfulTopicRow,
  ContentfulTopicTabularDataSet,
} from '../../graphql-types';
import { getDateWithLocaleOffset } from '../services/date.service';
import { getJSONFromExcel } from './getJSONfromExcel';

export interface TabularDataSetValues {
  type: string;
  value: string | number | Date;
}
export type TabularDataSetRowValues = TabularDataSetValues[];

export enum COLUMN_TYPES {
  TEXT = 'text',
  DOLLAR = 'dollar',
  PERCENT = 'percent',
  DATE = 'date',
}

/**
 * This function parses the values contained in a ContentfulTopicTabularDataSet
 * according to the column types it has received. The returned array is a simplified
 * array containing the type and values.
 *
 * @param tabularDataSet ContentfulTopicTabularDataSet
 * @returns TabularDataSetRowValues[]
 */
export const parseTabularDataSetColumnsAndRows = (
  tabularDataSet: ContentfulTopicTabularDataSet,
): TabularDataSetRowValues[] => {
  if (!tabularDataSet?.rows || !tabularDataSet.rows.length) {
    throw new Error('Tabular Data Set does not contain any defined rows.');
  }
  if (!tabularDataSet?.columns || !tabularDataSet?.columns.length) {
    throw new Error('Tabular Data Set does not contain any defined columns.');
  }

  const newRow: TabularDataSetRowValues[] = [];
  tabularDataSet.rows?.forEach((row) => {
    const newValues: TabularDataSetRowValues = [];

    if (!row.values || !row.values?.length) {
      throw new Error('Row does not contain any values');
    }

    for (let i = 0; i < row.values?.length; i++) {
      const type = tabularDataSet?.columns[i]?.type || COLUMN_TYPES.TEXT;
      let value: string | number | Date = row.values?.[i]?.value;

      // parse data based on column types
      if (type === COLUMN_TYPES.PERCENT || type === COLUMN_TYPES.DOLLAR) {
        value = parseFloat(value);
      } else if (type === COLUMN_TYPES.DATE) {
        try {
          value = getDateWithLocaleOffset(value);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn('Error trying to parse date string: ', value);
        }
      }
      newValues.push({
        type,
        value,
      });
    }

    newRow.push(newValues);
  });

  return newRow;
};

/**
 * This function parses the values contained in a ContentfulTopicTabularDataSet
 * from the data file added to the entry. The returned Promise is the array of
 * ContentfulTopicRows containing the values in for each cell from the file.
 *
 * @param tabularDataSet ContentfulTopicTabularDataSet
 * @returns Promise<ContentfulTopicRow[]>
 */
export const getRowDataFromDataFile = async (
  tabularDataSet: ContentfulTopicTabularDataSet,
): Promise<ContentfulTopicRow[]> => {
  const workbook = await getJSONFromExcel(tabularDataSet.dataFile.file?.url);
  const sheet = workbook.Sheets?.Sheet1;

  const range = utils.decode_range(sheet['!ref']);

  const newRow: ContentfulTopicRow[] = [];
  // Ignoring header row from the file
  for (let R = range.s.r + 1; R <= range.e.r; ++R) {
    const newValues: ContentfulTopicCell[] = [];
    for (let C = range.s.c; C <= range.e.c; ++C) {
      /* find the cell object */
      const cellRef = utils.encode_cell({ c: C, r: R }); // construct A1 reference for cell
      const cell = sheet[cellRef];
      newValues.push({
        // Get formatted text if available
        value: cell?.w || cell?.v,
        valueLink: cell?.l?.Target,
      } as ContentfulTopicCell);
    }
    newRow.push({
      values: newValues,
    } as ContentfulTopicRow);
  }

  return newRow;
};
