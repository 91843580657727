import React from 'react';
import styled from 'styled-components';

// needed to dynamically set the html tag via the `as` property
const Container = styled.div``;

// limits which HTML tags are accepted in the Contentful rich text field
const acceptedTags = ['sup', 'sub', 'small'];
// Simplified: (<(sup|sub|small)>(.*?)</\\2>)
// Regular Expression which has 3 capture groups:
//    1. Entire outer html                - e.g. <sup>TM</sup>
//    2. The text of the opening html tag - e.g. sup
//    3. The inner text value             - e.g. TM
// We use the #2 capture group to match the closing brace by using \2, or else any mismatching html tags would pair up
// The #3 inner text value capture group finds any character
// See regexr.com/69rdd for testing.
const HTMLRegExp = new RegExp(`(<(${acceptedTags.join('|')})>(.*?)</\\2>)`);

const replaceTextWithHtml = (element: any, level = 1) => {
  if (level > 5) return; // safe-guard for some ridiculous html nesting

  if (typeof element === 'string' && HTMLRegExp.test(element)) {
    // text contains html, so we need to split it, then run again
    const parts = element?.split(HTMLRegExp);
    const lead = parts?.splice(0, 1);
    const end = parts?.splice(parts?.length - 1);

    const reducedElements = [];
    for (let i = 0; i < parts?.length; i++) {
      const part = parts[i];
      // test if part has outer HTML capture group, and advance
      // past the tag and innterText capture groups if found
      if (HTMLRegExp.test(part)) {
        const tag = parts[i + 1];
        const innerText = parts[i + 2];
        reducedElements.push(
          <Container as={tag as keyof JSX.IntrinsicElements}>
            {replaceTextWithHtml(innerText, ++level)}
          </Container>,
        );

        // advance index past capture groups
        i += 2;
      } else {
        reducedElements.push(part);
      }
    }

    return [lead, ...reducedElements, end];
  } else {
    return element;
  }
};

/**
 * Replaces any plain text containing any accepted HTML tags with equivalent React element.
 * @param text any[] | string - Passed by renderNode
 * @returns
 */
export const replaceContentfulHtml = (text) =>
  text?.flatMap((item) => replaceTextWithHtml(item));
