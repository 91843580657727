import { checkStatusCode } from '../utils/checkStatusCode';

if (!process.env.GATSBY_AWS_API_URL) {
  // eslint-disable-next-line no-console
  console.error('API was not set.');
}
const AWS_API_URL = process.env.GATSBY_AWS_API_URL;
const SHARES_API_URL = `${AWS_API_URL}/shares`;
const SHARES_CACHE = new Map<string, Promise<unknown>>();

export const ContentfulDatasourceToSharesEndpoint = {
  Pricing: 'pricing',
  'Investment Results': 'performance',
  'Calendar Year Returns': 'calendar',
};

export const getCacheKey = (
  endpoint: string,
  dateYYYYMMDD?: string,
): string => {
  const cacheKey = `${endpoint}${dateYYYYMMDD ? `-date-${dateYYYYMMDD}` : ''}`;
  return cacheKey;
};

/**
 * This function will attempt to fetch shares from the private endpoint.
 *
 * @param endpoint String representing a Fundata endpoint
 * @param dateYYYYMMDD Date in YYYY-MM-DD format
 * @returns Promise with relevant Shares result
 */
export const getShares = async (
  endpoint: string,
  sessionToken: string,
  dateYYYYMMDD?: string,
): Promise<unknown> => {
  const mappedEndpoint = ContentfulDatasourceToSharesEndpoint[endpoint];
  if (!mappedEndpoint) {
    return; // endpoint doesn't exist for pricate shares
  }

  // check and return cache if it exists
  const cacheKey = getCacheKey(mappedEndpoint, dateYYYYMMDD);
  const cachedResult = SHARES_CACHE.get(cacheKey);
  if (cachedResult) {
    return cachedResult;
  }

  const sharesUrl = new URL(SHARES_API_URL);
  sharesUrl.searchParams.append('endpoint', mappedEndpoint);
  if (dateYYYYMMDD) {
    sharesUrl.searchParams.append('date', dateYYYYMMDD);
  }

  // authorization header for jwt token
  const bearer = 'Bearer ' + sessionToken;

  const result = await fetch(sharesUrl.href, {
    method: 'GET',
    headers: {
      Authorization: bearer,
    },
  })
    .then((response) =>
      checkStatusCode<{ success: boolean; data: unknown[] }>(response),
    )
    .then((response) => {
      // cache individual portfolios
      response.data.forEach((series) => {
        const portfolioCacheKey = getCacheKey(mappedEndpoint, dateYYYYMMDD);
        const seriesPromise = Promise.resolve(series);
        SHARES_CACHE.set(portfolioCacheKey, seriesPromise);
      });

      return response.data;
    });

  // shares should be available in cache now
  return SHARES_CACHE.get(cacheKey);
};
