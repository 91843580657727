import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
/**
 * getPlaintTextFromMicrocopy
 * Returns a plain text string of the Contentful rich text object. Useful for
 * labels and text areas that have multiple translations without the needed
 * styling from Contentful.
 *
 * @param raw string - A Globa: Microcopy Contentful 'raw' field.
 * @return string - Plain text version of the microcopy.
 */
export const getPlainTextFromMicrocopy = (raw: string): string => {
  let text = '';
  let json;
  try {
    json = JSON.parse(raw);
    text = documentToPlainTextString(json);
  } catch (e: any) {
    // nothing to do but log error
    // eslint-disable-next-line no-console
    console.warn('Unable to parse JSON string', raw);
  }
  return text;
};
