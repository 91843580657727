import React from 'react';

/**
 * Replaces any plain text containing &nbspl with a space.
 * @param text any[] | string - Passed by renderNode
 * @returns
 */
export const replaceContentfulNonBreakingSpace = (value) =>
  value?.flatMap((element: any) => {
    if (typeof element === 'string' && element.indexOf('&nbsp;') > -1) {
      // text contains &nbsp; so we need to split it
      return element
        ?.split('&nbsp;')
        ?.map((e, i, arr) => (i < arr.length - 1 ? [e, <>&nbsp;</>] : [e]))
        ?.reduce((a, b) => a.concat(b));
    } else {
      return element;
    }
  });
