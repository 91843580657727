import React from 'react';
import styled from 'styled-components';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TableRow from '@material-ui/core/TableRow';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import { Remove, Add } from '@material-ui/icons';
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';
import {
  ContentfulGlobalMicrocopyCopy,
  ContentfulTopicColumn,
  ContentfulTopicRow,
} from '../../graphql-types';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import Typography from '../components/Typography';
import {
  formatLocalizedDate,
  formatLocalizedNumber,
} from './localization.service';
import { getDateWithLocaleOffset } from './date.service';
import { getTranslation } from './translation.service';

const AccordionTextHolder = styled.div`
  display: flex;
  p {
    margin-top: 0 !important;
  }
  span {
    position: relative;
    top: -0.5rem;
    margin-right: 0.5rem;
  }
`;

export const getDefinitionsList = (
  definitions?: ContentfulGlobalMicrocopyCopy[],
  locale = 'en',
): JSX.Element => {
  if (!definitions || definitions.length === 0) {
    return <></>;
  }

  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleExpandAccordion = React.useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <ul className="mt-m3 flex flex-col">
      <li className="mt-s4 first:mt-0">
        <Accordion
          expanded={isExpanded}
          onChange={handleExpandAccordion}
          square
        >
          <AccordionSummary
            expandIcon={isExpanded ? <Remove /> : <Add />}
            aria-controls={`1-content`}
            id={`1-header`}
          >
            <Typography as="h3" variant="h4">
              {getTranslation('ShowFootnotes', locale)}
            </Typography>
          </AccordionSummary>

          {definitions.map((definition, index) => (
            <AccordionDetails key={`definition-${index}`}>
              <Typography as="div" variant="body">
                <AccordionTextHolder>
                  <span>{index + 1}</span>
                  {renderContentfulRichText(
                    definition as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                  )}
                </AccordionTextHolder>
              </Typography>
            </AccordionDetails>
          ))}
        </Accordion>
      </li>
    </ul>
  );
};

const TotalTableRow = styled(TableRow)`
  background-color: rgba(131, 129, 139, 0.3);
  td {
    font-weight: ${(props) => props.theme.fontWeight.bold};
  }
`;

export const getTotalsRow = (
  columns: ContentfulTopicColumn[],
  rows: ContentfulTopicRow[],
  locale: string,
): JSX.Element => {
  if (!columns || columns.length === 0 || !rows || rows.length === 0) {
    return <></>;
  }

  return (
    <TotalTableRow>
      {columns.map((col, index) => {
        const columnStyle = getColumnStyles(col);
        const columnAlign = getColumnAlignment(col);
        if (index === 0) {
          return (
            <TableCell
              style={columnStyle}
              align={columnAlign}
              key={`total-${index}`}
            >
              Total
            </TableCell>
          );
        } else if (
          col.type === 'number' ||
          col.type === 'dollar' ||
          col.type === 'percent'
        ) {
          const total = rows.reduce((a, b) => {
            return (
              a + parseFloat(b?.values?.[index]?.value?.replace?.(/[$%]+/g, ''))
            );
          }, 0);
          return (
            <TableCell
              key={`total-${index}`}
              style={columnStyle}
              align={columnAlign}
            >
              {getDisplayValue(total.toString(), col.type, locale)}
            </TableCell>
          );
        } else {
          return (
            <TableCell
              key={`total-${index}`}
              style={columnStyle}
              align={columnAlign}
            ></TableCell>
          );
        }
      })}
    </TotalTableRow>
  );
};

export const overrideTableFontSize = (
  tableFontSize?: null | 'Same as body text' | 'Default',
) => tableFontSize === 'Same as body text';

export const getColumnStyles = (
  column: ContentfulTopicColumn,
  fontSize?: string,
): TableCellProps['style'] => {
  let columnStyle: TableCellProps['style'] = {};
  if (column.settingForceOneLine) {
    columnStyle = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };
  }
  if (column.settingColumnWidthPercent) {
    columnStyle = {
      ...columnStyle,
      width: `${column.settingColumnWidthPercent}%`,
    };
  } else if (column.settingColumnWidthFixed) {
    columnStyle = {
      ...columnStyle,
      width: `${column.settingColumnWidthFixed}px`,
    };
  }
  if (fontSize) {
    columnStyle = {
      ...columnStyle,
      fontSize,
    };
  }

  return columnStyle;
};

export const getColumnAlignment = (
  column: ContentfulTopicColumn,
): TableCellProps['align'] => {
  let columnAlign: TableCellProps['align'] = 'inherit';
  if (column.settingTextAlign) {
    columnAlign =
      column.settingTextAlign?.toLowerCase() as TableCellProps['align'];
  } else if (column.type === 'dollar' || column.type === 'percent') {
    columnAlign = 'right';
  }

  return columnAlign;
};

export const HorizontalScrollIndicator = styled.div`
  height: 100%;
  width: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0.2) 100%
  );
`;

export const getHorizontalScrollIndicatorState = (
  ScrollableContainerRef: React.RefObject<HTMLDivElement>,
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  // shows a gradient when the table data overflows horizontally
  const showHorizontalIndicatorState = React.useState(false);

  React.useLayoutEffect(() => {
    const hasHorizontalScrollbar =
      ScrollableContainerRef.current?.scrollWidth >
      ScrollableContainerRef.current?.clientWidth;
    showHorizontalIndicatorState[1](hasHorizontalScrollbar);
  }, [ScrollableContainerRef]);

  React.useEffect(() => {
    const handleResize = () => {
      const hasHorizontalScrollbar =
        ScrollableContainerRef.current?.scrollWidth >
        ScrollableContainerRef.current?.clientWidth;
      showHorizontalIndicatorState[1](hasHorizontalScrollbar);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ScrollableContainerRef]);

  return showHorizontalIndicatorState;
};

interface DisplaySettings {
  decimalPlaces?: number;
}
export const getDisplayValue = (
  value: string,
  type: string,
  locale: string,
  settings?: DisplaySettings,
): string => {
  // check settings
  const decimalPlaces =
    settings?.decimalPlaces != null ? settings?.decimalPlaces : 2;
  let displayValue;
  switch (type) {
    case 'dollar': {
      try {
        const numberValue = parseFloat(value);
        if (isNaN(numberValue)) {
          throw new Error(`${value} is not a number.`);
        }
        displayValue = formatLocalizedNumber(numberValue, locale, {
          style: 'currency',
          currency: 'CAD',
          currencyDisplay: 'narrowSymbol',
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error parsing dollar ${value}`, error);
        displayValue = value;
      }
      break;
    }
    case 'percent': {
      try {
        const percentValue = parseFloat(value) / 100;
        if (isNaN(percentValue)) {
          throw new Error(`${value} is not a number.`);
        }
        // number formatter in 'percentage' style takes a fractional
        // number as percentage, so that .9 is actaully 90%
        // we are assuming here that if they input something like 10.12
        // then they want 10.12%
        displayValue = formatLocalizedNumber(percentValue, locale, {
          style: 'percent',
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error parsing percentage ${value}`, error);
        displayValue = value;
      }
      break;
    }
    case 'date': {
      try {
        const dateValue = getDateWithLocaleOffset(value);
        if (isNaN(dateValue?.getTime())) {
          throw new Error(`${value} is not a valid date.`);
        }
        displayValue = formatLocalizedDate(dateValue, locale);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error parsing date ${value}`, error);
        displayValue = value;
      }
      break;
    }
    default: {
      displayValue = value;
      break;
    }
  }

  return displayValue;
};
