import XLSX from 'xlsx';

/**
 * Given a URL to an excel sheet, this function will fetch it, and return an
 * XLSX Workbook object parse from it.
 * @param url URL to Excel file
 * @returns Promise<XLSX.Workbook>
 */
export const getJSONFromExcel = async (url: string): Promise<XLSX.WorkBook> => {
  return fetch(url)
    .then(function (res) {
      /* get the data as a Blob */
      if (!res.ok) throw new Error('fetch failed');
      return res.arrayBuffer();
    })
    .then(function (ab) {
      /* parse the data when it is received */
      const data = new Uint8Array(ab);
      const workbook = XLSX.read(data, { type: 'array', raw: true });
      return workbook;
    });
};
