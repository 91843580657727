import { ApiUrlUndefinedError } from '../types/apiUrlUndefinedError';
import { checkStatusCode } from '../utils/checkStatusCode';

const AWS_API_URL = process.env.GATSBY_AWS_API_URL;

/**
 * Sends a post request to the contact endpoint. A request will return void on success,
 * or a rejected Promise on error.
 *
 * @param name Name from user input
 * @param email Email from user input
 * @param phone Phone from user input
 * @param message Message from user input
 * @param recaptchaToken Recaptch v3 token
 * @param subject Subject from user input
 * @param extension Extension from user input
 * @returns void
 */
export const submitContactInformation = async (
  endpointPath: string,
  formId: string,
  name: string,
  email: string,
  postal: string,
  phone: string,
  message: string,
  organization: string,
  recaptchaToken: string,
  subject?: string,
  extension?: string,
): Promise<void> => {
  const API_FULL_URL = `${AWS_API_URL}${
    endpointPath?.startsWith('/') ? endpointPath : `/${endpointPath}`
  }`;
  if (!API_FULL_URL) {
    return Promise.reject(new ApiUrlUndefinedError());
  }

  const contactApiUrl = new URL(API_FULL_URL);
  let data;

  try {
    data = JSON.stringify({
      formId,
      name,
      email,
      postal,
      phone,
      message,
      recaptchaToken,
      organization,
      subject,
      extension,
    });
  } catch (error) {
    return Promise.reject('Error on JSON.stringify');
  }

  return fetch(contactApiUrl.href, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: data,
  }).then((response) => checkStatusCode<void>(response));
};
