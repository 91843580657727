import 'date-fns';
import React from 'react';
import { CircularProgress, Fade, MenuItem, TextField } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Alert } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import { enCA, frCA, zhCN } from 'date-fns/locale';
import { useLocalization } from 'gatsby-theme-i18n';
import * as XLSX from 'xlsx';
import {
  ContentfulComponentCtaButton,
  ContentfulGlobalMicrocopy,
  ContentfulTopicPortfolio,
  ContentfulTopicPortfolioSeries,
} from '../../graphql-types';
import { getPlainTextFromMicrocopy } from '../utils/getPlainTextFromMicrocopy';
import Typography from './Typography';
import Button from './Button';
import {
  getDateWithLocaleOffset,
  getLastBusinessDate,
  isBusinessDay,
} from '../services/date.service';
import {
  CONTENTFUL_FUNDATA_SOURCE_OPTIONS,
  getFundataV2,
  groupSortTopicSeries,
} from '../services/fundata-v2.service';
import { PricingHistoryResponse } from '../services/fundata-v2.types';
import { formatLocalizedNumber } from '../services/localization.service';

interface CsvFileFieldLabels {
  date?: string;
  closingPrice?: string;
  dailyChangePrice?: string;
  dailyChangePercentage?: string;
  distribution?: string;
  footnote?: string;
}
interface DownloadCsvFieldLabels {
  startDateLabel?: string;
  endDateLabel?: string;
  selectPortfolioGroupLabel?: string;
  selectPortfolioLabel?: string;
}
interface DownloadCsvProps {
  className?: string;
  title?: string;
  subheading?: string;
  footnote?: string;
  startDateLabel?: ContentfulGlobalMicrocopy;
  endDateLabel?: ContentfulGlobalMicrocopy;
  selectPortfolioGroupLabel?: ContentfulGlobalMicrocopy;
  selectPortfolioLabel?: ContentfulGlobalMicrocopy;
  portfolioSeries?: ContentfulTopicPortfolioSeries[];
  groupByPortfolio?: boolean;
  downloadButton?: ContentfulComponentCtaButton;
  csvFieldLabels?: ContentfulGlobalMicrocopy[];
}
const DownloadCsv: React.FC<DownloadCsvProps> = (props) => {
  const {
    className,
    title,
    subheading,
    footnote,
    startDateLabel,
    endDateLabel,
    selectPortfolioGroupLabel,
    selectPortfolioLabel,
    portfolioSeries,
    groupByPortfolio,
    downloadButton,
    csvFieldLabels,
  } = props;

  // various field labels parsed from microcopy
  const downloadCsvFieldLabels: DownloadCsvFieldLabels = {
    startDateLabel: getPlainTextFromMicrocopy(startDateLabel?.copy?.raw),
    endDateLabel: getPlainTextFromMicrocopy(endDateLabel?.copy?.raw),
    selectPortfolioGroupLabel: getPlainTextFromMicrocopy(
      selectPortfolioGroupLabel?.copy?.raw,
    ),
    selectPortfolioLabel: getPlainTextFromMicrocopy(
      selectPortfolioLabel?.copy?.raw,
    ),
  };
  const csvFileFieldLabels: CsvFileFieldLabels = {
    date: getPlainTextFromMicrocopy(
      csvFieldLabels?.find((field) => field.key === 'CsvDateLabel')?.copy?.raw,
    ),
    closingPrice: getPlainTextFromMicrocopy(
      csvFieldLabels?.find((field) => field.key === 'CsvClosingPriceLabel')
        ?.copy?.raw,
    ),
    dailyChangePrice: getPlainTextFromMicrocopy(
      csvFieldLabels?.find((field) => field.key === 'CsvDailyChangePriceLabel')
        ?.copy?.raw,
    ),
    dailyChangePercentage: getPlainTextFromMicrocopy(
      csvFieldLabels?.find(
        (field) => field.key === 'CsvDailyChangePercentageLabel',
      )?.copy?.raw,
    ),
    distribution: getPlainTextFromMicrocopy(
      csvFieldLabels?.find((field) => field.key === 'CsvDistributionLabel')
        ?.copy?.raw,
    ),
    footnote: getPlainTextFromMicrocopy(
      csvFieldLabels?.find((field) => field.key === 'CsvFootnote')?.copy?.raw,
    ),
  };
  const [displayedPortfolios, setDisplayedPortfolios] =
    React.useState<ContentfulTopicPortfolio[]>();
  const [displayedPortfolioSeries, setDisplayedPortfolioSeries] =
    React.useState<ContentfulTopicPortfolioSeries[]>();
  const [selectedPortfolio, setSelectedPortfolio] = React.useState('');

  const [selectedPortfolioSeries, setSelectedPortfolioSeries] =
    React.useState('');

  // setup and state for the date selectors
  const lastBusinessDate = getLastBusinessDate();
  let businessYear = lastBusinessDate.getFullYear();
  // getMonth() returns 0-indexed month, so it represents last month when we use
  // it to set the default selected start date
  let businessMonth = lastBusinessDate.getMonth();

  const businessDay = lastBusinessDate.getDate();

  if (businessMonth === 0) {
    businessMonth = 12;
    businessYear = businessYear - 1;
  }

  const defaultSelectedStartDate = getDateWithLocaleOffset(
    `${businessYear}-${businessMonth}-${
      businessDay < 10 ? '0' + businessDay : businessDay
    }`,
  );

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    defaultSelectedStartDate,
  );
  const [selectedEndDate, setSelectedEndDate] =
    React.useState(lastBusinessDate);

  // various state for controlling buttons
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const { locale } = useLocalization();
  const muiPickerLocale =
    locale === 'en' ? enCA : locale === 'fr' ? frCA : zhCN;

  const handleChangeSelectedPortfolio = (e: any) => {
    const portfolio = displayedPortfolios.find(
      (portfolio) => portfolio.id === e.target.value,
    );
    setSelectedPortfolio(e.target.value);
    setDisplayedPortfolioSeries(portfolio.series);
    setSelectedPortfolioSeries(portfolio.series?.[0]?.id);
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const handleDownload = async (e: React.FormEvent) => {
    // stop page from changing
    e.preventDefault();

    // parse and format dates to YYYY-MM-DD ISO string
    const startDateYYYYMMDD = selectedStartDate.toISOString().substring(0, 10);
    const endDateYYYYMMDD = selectedEndDate.toISOString().substring(0, 10);

    const selectedSeries = portfolioSeries.find(
      (series) => series.id === selectedPortfolioSeries,
    );
    const portfolioTitle = selectedSeries?.topic__portfolio?.[0]?.title;
    const seriesTitle = selectedSeries?.series?.title;
    const fromDate = startDateYYYYMMDD;
    const toDate = endDateYYYYMMDD;
    const instrumentKey = selectedSeries?.instrumentKey;
    const details = `${portfolioTitle} ${seriesTitle} Price History - From: ${fromDate} To: ${toDate}`;

    if (window?.dataLayer) {
      window.dataLayer?.push({
        event: `downloadEvent`,
        value: details,
      });
    }

    setIsLoading(true);
    const result = await getFundataV2<PricingHistoryResponse>(
      CONTENTFUL_FUNDATA_SOURCE_OPTIONS.PRICING_HISTORY,
      locale,
      instrumentKey,
      null,
      startDateYYYYMMDD,
      endDateYYYYMMDD,
    ).catch((error) => {
      // eslint-disable-next-line no-console
      console.warn(
        `Error fetching daily pricing history for ${startDateYYYYMMDD} to ${endDateYYYYMMDD}`,
        error,
      );
    });
    setIsLoading(false);

    if (!result) {
      setHasError(true);
      return;
    }

    // format and normalize data to csv
    const dateLabel = csvFileFieldLabels.date || 'Date';
    const closingPriceLabel =
      csvFileFieldLabels.closingPrice || 'Closing Price (C$)';
    const dailyChangePriceLabel =
      csvFileFieldLabels.dailyChangePrice || '$ Daily Change (C$)';
    const dailyChangePercentageLabel =
      csvFileFieldLabels.dailyChangePercentage || '% Daily Change';
    const distributionLabel =
      csvFileFieldLabels.distribution || 'Distribution (C$)';
    const footnote = csvFileFieldLabels.footnote;

    const csvFormattedData = result.History?.map((row) => {
      // return formatted headers as property names for XLSX
      // Date | Closing Price (C$) |	$ Daily Change (C$) |	% Daily Change | Distribution (C$)
      const formattedRow = {};
      formattedRow[dateLabel] = getDateWithLocaleOffset(row.Date);
      formattedRow[closingPriceLabel] =
        row.NAVPS != null
          ? formatLocalizedNumber(row.NAVPS, locale, {
              style: 'currency',
              currency: 'CAD',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 4,
            })
          : null;
      formattedRow[dailyChangePriceLabel] =
        row.PennyChange != null
          ? formatLocalizedNumber(row.PennyChange, locale, {
              style: 'currency',
              currency: 'CAD',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 4,
            })
          : null;
      formattedRow[dailyChangePercentageLabel] =
        row.PercentChange != null ? `${row.PercentChange}%` : null;
      formattedRow[distributionLabel] =
        row.TotalDistribution != null
          ? formatLocalizedNumber(row.TotalDistribution, locale, {
              style: 'currency',
              currency: 'CAD',
              currencyDisplay: 'narrowSymbol',
              minimumFractionDigits: 4,
            })
          : null;
      return formattedRow;
    });

    // build  worksheet for XLSX
    const worksheet = XLSX.utils.json_to_sheet(
      [
        {
          note: details,
        },
      ],
      {
        skipHeader: true,
      },
    );
    XLSX.utils.sheet_add_json(worksheet, csvFormattedData, {
      origin: 'A2',
      dateNF: 'yyyy-mm-dd',
      cellDates: true,
    });

    // XLSX only supports styled cells in PRO version
    // cf. https://stackoverflow.com/a/50735088
    //
    // It seems adding at least one \n character within the text will
    // force the CSV cell to wrap without having to apply styles to it.
    // 2022-10-07 - EPW would like the copy to remain unwrapped for now.
    // note: `${footnote}\n`,
    // 2022-10-12 - EPW would like to try putting each line on a separate row.
    // cf. https://stackoverflow.com/a/18914770 - splitting strings into sentences
    const disclaimerData = footnote
      ?.replace(/([.?!])\s*(?=[A-Z])/g, '$1|')
      ?.split('|')
      ?.map((line) => {
        return { disclaimer: line };
      });
    XLSX.utils.sheet_add_json(
      worksheet,
      [
        { disclaimer: '' }, // add empty line before disclaimer
        ...disclaimerData,
      ],
      {
        header: ['disclaimer'],
        origin: -1,
        skipHeader: true,
      },
    );
    const csv = XLSX.utils.sheet_to_csv(worksheet);

    // create download link
    const blob = new Blob([csv], { type: 'text/plain;charset=UTF-8' });
    const blobUrl = URL.createObjectURL(blob);
    const filename = `${portfolioTitle?.replace?.(
      new RegExp(' ', 'g'),
      '-',
    )}-${seriesTitle?.replace?.(new RegExp(' ', 'g'), '-')}.csv`;
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = filename;
    a.target = '_blank';
    a.click();

    setHasError(false);
  };

  /**
   * Returns the maximum required start date, which should be at least one day before
   * endDate.
   * @param endDate Date from which start date is calculated
   */
  const getMaximumStartDate = (endDate?: Date) => {
    if (endDate == null) {
      return undefined;
    }

    const year = endDate.getFullYear();
    const month = endDate.getMonth() + 1;
    const day = endDate.getDate();
    const maximumStartDate = getDateWithLocaleOffset(
      `${year}-${month}-${day - 1}`,
    );
    return maximumStartDate;
  };

  /**
   * Returns the minimum required end date, which should be at least one day after
   * startDate.
   * @param startDate Date from which end date is calculated
   */
  const getMinimumEndDate = (startDate?: Date) => {
    if (startDate == null) {
      return undefined;
    }

    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1;
    const day = startDate.getDate();
    const minimumEndDate = getDateWithLocaleOffset(
      `${year}-${month}-${day + 1}`,
    );
    return minimumEndDate;
  };

  React.useEffect(() => {
    // set up default selected portfolio series
    if (groupByPortfolio && portfolioSeries && portfolioSeries[0]?.id) {
      // group series by their portfolios
      const portfolioGroups = portfolioSeries.reduce((acc, series) => {
        if (
          !acc.some(
            (portfolio) => portfolio.id === series.topic__portfolio?.[0]?.id,
          )
        ) {
          const portfolio = series.topic__portfolio[0];
          portfolio.series = portfolioSeries.filter(
            (series) => series.topic__portfolio[0].id === portfolio.id,
          );
          acc.push(series.topic__portfolio[0]);
        }

        return acc;
      }, [] as ContentfulTopicPortfolio[]);

      portfolioGroups.sort((a, b) => a.title.localeCompare(b.title));
      portfolioGroups.forEach((portfolio) => {
        portfolio.series = groupSortTopicSeries(portfolio.series);
      });
      setDisplayedPortfolios(portfolioGroups);
    } else if (portfolioSeries && portfolioSeries[0]?.id) {
      const groupedSeries = groupSortTopicSeries(portfolioSeries);
      setDisplayedPortfolioSeries(groupedSeries);
    }
  }, [portfolioSeries]);

  React.useEffect(() => {
    if (displayedPortfolios) {
      setSelectedPortfolio(displayedPortfolios[0]?.id);
      setDisplayedPortfolioSeries(displayedPortfolios[0].series);
    }
  }, [displayedPortfolios]);

  React.useEffect(() => {
    if (displayedPortfolioSeries) {
      setSelectedPortfolioSeries(displayedPortfolioSeries[0].id);
    }
  }, [displayedPortfolioSeries]);

  return (
    <div className={`container ${className ?? ''}`}>
      {title && (
        <Typography as="h2" variant="h2">
          {title}
        </Typography>
      )}

      {subheading && (
        <Typography as="p" variant="body">
          {subheading}
        </Typography>
      )}

      <form className={`relative`} onSubmit={handleDownload}>
        <Fade in={isLoading} unmountOnExit>
          <div className="absolute z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <CircularProgress aria-label="Loading data" />
          </div>
        </Fade>
        <fieldset
          disabled={isLoading}
          className={`lg:grid lg:grid-cols-12 lg:grid-flow-col-dense lg:gap-x-s3 ${
            isLoading ? 'opacity-60' : ''
          }`}
        >
          {groupByPortfolio &&
            downloadCsvFieldLabels?.selectPortfolioGroupLabel && (
              <div className="mt-m1 lg:col-span-3">
                <TextField
                  select
                  label={downloadCsvFieldLabels.selectPortfolioGroupLabel}
                  value={selectedPortfolio}
                  onChange={handleChangeSelectedPortfolio}
                  fullWidth
                >
                  {displayedPortfolios?.map((portfolio) => {
                    return (
                      <MenuItem key={portfolio.id} value={portfolio.id}>
                        {portfolio.title}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            )}
          {downloadCsvFieldLabels?.selectPortfolioLabel && (
            <div className="mt-m1 lg:col-span-3">
              <TextField
                select
                label={downloadCsvFieldLabels.selectPortfolioLabel}
                value={selectedPortfolioSeries}
                onChange={(e) => setSelectedPortfolioSeries(e.target.value)}
                fullWidth
              >
                {displayedPortfolioSeries?.map((series) => {
                  return (
                    <MenuItem key={series.id} value={series.id}>
                      {series.series?.title}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          )}
          {downloadCsvFieldLabels?.startDateLabel &&
            downloadCsvFieldLabels?.endDateLabel && (
              <MuiPickersUtilsProvider
                locale={muiPickerLocale}
                utils={DateFnsUtils}
              >
                <div className="mt-m1 lg:col-span-3">
                  <KeyboardDatePicker
                    value={selectedStartDate}
                    variant="inline"
                    format={locale === 'fr' ? 'd MMM yyyy' : 'MMM d, yyyy'}
                    margin="normal"
                    id="start-date-picker"
                    label={downloadCsvFieldLabels.startDateLabel}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change start date',
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    disableFuture
                    maxDate={getMaximumStartDate(selectedEndDate)}
                    shouldDisableDate={(date) => !isBusinessDay(date)}
                    autoOk={true}
                    className="mt-0"
                  />
                </div>
                <div className="mt-m1 lg:col-span-3">
                  <KeyboardDatePicker
                    value={selectedEndDate}
                    variant="inline"
                    format={locale === 'fr' ? 'd MMM yyyy' : 'MMM d, yyyy'}
                    margin="normal"
                    id="end-date-picker"
                    label={downloadCsvFieldLabels.endDateLabel}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change end date',
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    disableFuture
                    minDate={getMinimumEndDate(selectedStartDate)}
                    maxDate={lastBusinessDate}
                    shouldDisableDate={(date) => !isBusinessDay(date)}
                    autoOk={true}
                    className="mt-0"
                  />
                </div>
              </MuiPickersUtilsProvider>
            )}
          <div className="mt-m1 lg:col-span-3">
            {downloadButton && (
              <div className="">
                <Button
                  variant={downloadButton.variant}
                  type="submit"
                  className="w-full"
                  disabled={
                    selectedPortfolioSeries === '' ||
                    selectedStartDate === undefined ||
                    selectedEndDate === undefined
                  }
                >
                  {downloadButton.text}
                </Button>
              </div>
            )}
            {footnote && (
              <div className="mt-s3">
                <Typography variant="footerBody3">{footnote}</Typography>
              </div>
            )}
          </div>
        </fieldset>
      </form>

      {!isLoading && hasError && (
        <div className="mt-m1">
          <Alert severity="info">
            <strong>Sorry!</strong> Data temporarily unavailable, but we{`'`}re
            working to get it back soon.
          </Alert>
        </div>
      )}
    </div>
  );
};

export default DownloadCsv;
