import React from 'react';
import { ContentfulGlobalMicrocopyCopy } from '../../graphql-types';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';

interface MicrocopyProps {
  copy: ContentfulGlobalMicrocopyCopy;
}
const Microcopy: React.FC<MicrocopyProps> = (props) => {
  const { copy } = props;
  return <>{renderContentfulRichText(copy)}</>;
};

export default Microcopy;
