/**
 * Helper function for formatting a floating point decimal number to percentage string.
 * @param value Number representing a percentage
 * @returns Formatted percentage string
 */
export const formatDecimalPercentage = (value: number): string => {
  const splitValue = value.toString()?.split('.');
  const decimals = splitValue?.[1]?.substring(0, 4);
  const stringPercentage = parseInt(decimals) / 100 + '%';
  return stringPercentage;
};

const defaultOptions = {
  format: 'whole',
  precision: 2,
  rounded: false,
};
/**
 * Helper function for formatting a number to percentage string with options.
 * @param value Number representing a non-decimal percentage
 * @returns Formatted percentage string
 */
export const formatPercentage = (
  value: number,
  options?: {
    numberType?: 'whole' | 'decimal';
    precision?: number;
    rounded?: boolean;
  },
): string => {
  const formatOptions = {
    ...defaultOptions,
    ...options,
  };

  let floatValue = value;
  if (formatOptions.numberType === 'whole') {
    floatValue /= 100;
  }

  const multiplier = Math.pow(10, formatOptions.precision || 0);
  let roundedValue = floatValue;
  if (formatOptions.rounded) {
    roundedValue =
      Math.round((roundedValue + Number.EPSILON) * multiplier) / multiplier;
  } else {
    roundedValue = parseFloat(roundedValue.toFixed(formatOptions.precision));
  }
  return `${roundedValue}%`;
};

/**
 * Helper function for formatting localized currency.
 * @param value Number representing a currency
 * @param locale Optional. Locale of 'en', 'fr', or 'cn'.
 * @param decimalPlaces Optional. Amount of decimal places to display.
 * @returns Formatted currency string
 */
export const formatLocalizedCurrency = (
  value: number,
  locale = 'en',
  decimalPlaces = 2,
): string => {
  if (value == null) {
    return '';
  }

  const formattedLocale =
    locale === 'en' ? 'en-CA' : locale === 'fr' ? 'fr-CA' : 'zh-CN';
  const intlNumberFormatter = new Intl.NumberFormat(formattedLocale, {
    style: 'currency',
    currency: 'CAD',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: decimalPlaces,
  });
  return intlNumberFormatter.format(value);
};

const defaultDateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
};

/**
 * Helper function for formatting localized dates.
 * @param date String representing a date
 * @param locale Optional. Locale of 'en, 'fr', or 'cn'.
 * @param options Optional. Options to Intl.DateTimeFormatOptions to override from the default
 * @returns Formatted date string
 */
export const formatLocalizedDate = (
  date: Date,
  locale?: string,
  options?: Intl.DateTimeFormatOptions,
): string => {
  if (date == null || !(date instanceof Date)) {
    return '';
  }
  const formattedLocale =
    locale === 'en' ? 'en-CA' : locale === 'fr' ? 'fr-CA' : 'zh-CN';
  const customOptions = {
    ...defaultDateTimeFormatOptions,
    ...(options ?? {}),
  };
  const intlNumberFormatter = new Intl.DateTimeFormat(
    formattedLocale,
    customOptions,
  );
  return intlNumberFormatter.format(date);
};

const defaultLocalizedNumberOptions: Intl.NumberFormatOptions = {
  minimumFractionDigits: 2,
};
/**
 * Helper function for formatting localized numbers.
 * @param value Number
 * @param locale Optional. Locale of 'en', 'fr', or 'cn'.
 * @param options Optional. Additional options for number format.
 * @returns Formatted number string
 */
export const formatLocalizedNumber = (
  value: number,
  locale?: string,
  options?: Intl.NumberFormatOptions,
): string => {
  if (value == null || isNaN(value)) {
    return '';
  }
  const formattedLocale =
    locale === 'en' ? 'en-CA' : locale === 'fr' ? 'fr-CA' : 'zh-CN';
  const customOptions = {
    ...defaultLocalizedNumberOptions,
    ...(options ?? {}),
  };

  const intlNumberFormatter = new Intl.NumberFormat(
    formattedLocale,
    customOptions,
  );

  const format = intlNumberFormatter.format(value);
  return format;
};
