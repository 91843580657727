import { ArrowRight, ChevronRight } from '@material-ui/icons';
import { useLocalization } from 'gatsby-theme-i18n';
import React from 'react';
import styled from 'styled-components';
import { ContentfulComposePage } from '../../graphql-types';
import Link from './Link';
import Typography from './Typography';

const SetContainer = styled.div`
  a:hover h3,
  a:focus h3 {
    text-decoration: underline;
  }
`;

interface OverviewSetProps {
  title?: string;
  items?: ContentfulComposePage[];
  className?: string;
}
const OverviewSet: React.FC<OverviewSetProps> = (props) => {
  const { title, items, className } = props;
  const { locale } = useLocalization();

  return (
    <div className={`container pt-m4 pb-l2 ${className || ''}`}>
      {title && (
        <Typography as="h2" variant="h2">
          {title}
        </Typography>
      )}
      {items && items.length > 0 && (
        <div className="md:grid grid-cols-2 gap-x-s3 gap-y-m3">
          {items.map((item) => {
            return (
              <SetContainer
                key={item.id}
                className={`mt-m2 md:mt-0 md:col-span-1`}
              >
                <Link
                  language={locale}
                  link={{
                    page: item,
                  }}
                  className="no-underline"
                >
                  <div className="">
                    <div className="bg-secondary rounded-full w-[32px] h-[32px]"></div>
                  </div>
                  {item.title && (
                    <Typography as="h3" variant="h3" className="mt-s2">
                      {item.title}
                      <ArrowRight className="text-secondary" />
                    </Typography>
                  )}
                  {item?.shortDescription && (
                    <Typography as="p" variant="body" className="mt-s2">
                      {item?.shortDescription?.shortDescription}
                    </Typography>
                  )}
                </Link>
              </SetContainer>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default OverviewSet;
