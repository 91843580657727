import React from 'react';
import styled from 'styled-components';
import ComponentSelector from './ComponentSelector';

const GRID_TYPE_OPTIONS = {
  EVEN: 'Even',
  ONE_THIRD: '1/3',
  TWO_THIRDS: '2/3',
};

const ComponentSetWrapper = styled.div`
  // for overriding component containers within the component set,
  // as the component set with determine the container
  .container {
    max-width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`;
const Container = styled.div<{
  variant: string;
  showDivider: boolean;
}>`
  ${(props) => {
    let css = ``;
    if (props.variant === 'horizontal' && props.showDivider) {
      if (props.showDivider) {
        css += `
        @media (min-width: ${props.theme.breakpoint.lg}) {
          & > div:not(:first-of-type) {
            border-left: 1px solid ${props.theme.color.secondary};
          }
        }
        `;
      }
    } else if (props.showDivider) {
      css += `
        & > div:not(:first-of-type) {
          border-top: 1px solid ${props.theme.color.secondary};
        }
        `;
    }
    return css;
  }}
`;

interface ComponentSetProps {
  className?: string;
  components?: any[];
  showDivider?: boolean;
  variant?: string;
  gridType?: string;
  isChildComponent?: boolean;
}
const ComponentSet: React.FC<ComponentSetProps> = (props) => {
  const {
    className,
    components,
    showDivider,
    variant,
    gridType,
    isChildComponent,
  } = props;

  let containerClasses = '';
  if (variant === 'horizontal') {
    containerClasses = 'lg:grid lg:grid-flow-col lg:gap-x-s3';
    switch (gridType) {
      case GRID_TYPE_OPTIONS.ONE_THIRD:
      case GRID_TYPE_OPTIONS.TWO_THIRDS: {
        containerClasses += ' lg:grid-cols-3';
        break;
      }
      case GRID_TYPE_OPTIONS.EVEN:
      default: {
        containerClasses += ' lg:auto-cols-fr';
      }
    }
  } else {
    containerClasses = 'flex flex-col';
  }

  return (
    <Container
      variant={variant}
      showDivider={showDivider}
      className={`${className} ${containerClasses} `}
    >
      {components?.map((contentfulComponent, index) => {
        let gridItemClass = '';
        if (gridType === '1/3' && (index + 1) % 2 == 0) {
          gridItemClass = 'col-span-2';
        }
        if (gridType === '2/3' && index === 0) {
          gridItemClass = 'col-span-2';
        }
        return (
          <ComponentSetWrapper
            key={`component-set-${index}`}
            className={gridItemClass}
          >
            <ComponentSelector
              contentfulComponent={contentfulComponent}
              isChildComponent={isChildComponent}
            />
          </ComponentSetWrapper>
        );
      })}
    </Container>
  );
};

export default ComponentSet;
