import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  MenuItem,
  TextField,
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  Select,
} from '@material-ui/core';
import { BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { Alert } from '@material-ui/lab';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import {
  ContentfulComponentDataVisualizationFootnote,
  ContentfulComponentDataVisualizationSubheadingTextNode,
  ContentfulTopicPortfolio,
  ContentfulTopicPortfolioSeries,
  ContentfulTopicTabularDataSet,
} from '../../graphql-types';
import Typography from './Typography';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import {
  getDistributionDates,
  getDistributionResponse,
  groupSortSeries,
} from '../services/fundata-v2.service';
import { useGlobalState } from '../hooks/useGlobalState';
import { isSameDay } from '../services/date.service';
import {
  formatLocalizedCurrency,
  formatLocalizedDate,
} from '../services/localization.service';
import {
  DistributionResponse,
  SeriesResponse,
} from '../services/fundata-v2.types';
import {
  getColumnAlignment,
  getColumnStyles,
  getDefinitionsList,
  getHorizontalScrollIndicatorState,
  HorizontalScrollIndicator,
} from '../services/table.service';
import { getTranslation } from '../services/translation.service';

const TableWrapper = styled.div`
  position: relative;
`;

const footnoteRichTextOptions: Options = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography
        as="small"
        variant="body"
        className="block mt-s2 text-xs leading-relaxed"
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography
        as="span"
        variant="body"
        className="block mt-s2 leading-relaxed"
      >
        {children}
      </Typography>
    ),
  },
};

interface DataVisualizationTableDistributionsProps {
  dataSet: ContentfulTopicTabularDataSet;
  title?: string;
  subheading?: ContentfulComponentDataVisualizationSubheadingTextNode;
  footnote?: ContentfulComponentDataVisualizationFootnote;
  headlineOrientation?: 'stacked' | 'split';
  dateSelectorLabel?: string;
  showPortfolioFilters?: boolean;
  overrideTableFontSize?: boolean;
}

const DataVisualizationTableDistributions: React.FC<
  DataVisualizationTableDistributionsProps
> = (props) => {
  const {
    title,
    subheading,
    dataSet,
    footnote,
    headlineOrientation,
    dateSelectorLabel,
    showPortfolioFilters,
    overrideTableFontSize,
  } = props;
  const { locale } = useLocalization();
  const { portfolio, allPortfolioSeries } = useGlobalState();

  const {
    fontSize: { body },
  } = React.useContext(ThemeContext);

  // derived from dataSet
  const fundataFor = dataSet.funDataFor;
  const tableMinHeight = dataSet.settingMinHeight;
  const tableMaxHeight = dataSet.settingMaxHeight;
  const tableFixedHeaders = dataSet.settingMinHeight;
  const tableColumnData = dataSet?.columns;
  let definitionIndex = 1;
  const definitions = dataSet?.columns
    .concat(dataSet?.rows?.flatMap((row) => row.values))
    .filter((item) => item?.additionalInfo)
    .map((cell) => cell?.additionalInfo?.copy);

  // state
  const [currentSeriesList, setCurrentSeriesList] = React.useState<
    ContentfulTopicPortfolioSeries[]
  >([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [distributionDates, setDistributionDates] = React.useState<Date[]>([]);
  const [selectedDate, setSelectedDate] = React.useState<Date>(null);
  const [selectedDateOption, setSelectedDateOption] =
    React.useState<string>('');
  const [allTableRowData, setAllTableRowData] =
    React.useState<SeriesResponse<DistributionResponse>[]>(null);
  const [currentTableRows, setCurrentTableRows] =
    React.useState<SeriesResponse<DistributionResponse>[]>(null);

  // // shows a gradient when the table data overflows horizontally
  const ScrollableContainerRef = React.createRef<HTMLDivElement>();
  const [showHorizontalIndicator] = getHorizontalScrollIndicatorState(
    ScrollableContainerRef,
  );

  const handleSelectedDateOptionChange = (e) => {
    const value = e.target.value;
    setSelectedDateOption(value);
    const selectedDate = distributionDates.find(
      (distributionDate) => distributionDate.toISOString() === value,
    );
    setSelectedDate(selectedDate);
  };

  // state for allPortfolios filters
  const [portfolioFilters, setPortfolioFilters] = React.useState<
    ContentfulTopicPortfolio[]
  >([]);
  const [selectedFilteredPortfolios, setSelectedFilteredPortfolios] =
    React.useState<string[]>([]);
  const handleSelectedFilteredPortfoliosChange = (event) => {
    setSelectedFilteredPortfolios(event.target.value);
  };
  const [seriesFilters, setSeriesFilters] = React.useState<string[]>([]);
  const [selectedFilteredSeries, setSelectedFilteredSeriesCategory] =
    React.useState<string[]>([]);
  const handleSelectedFilteredSeriesCategoryChange = (event) => {
    setSelectedFilteredSeriesCategory(event.target.value);
  };
  const [HSTFilters, setHSTFilters] = React.useState([]);
  const [selectedFilteredHST, setSelectedFilteredHST] = React.useState<
    string[]
  >([]);
  const handleSelectedFilteredHSTChange = (event) => {
    setSelectedFilteredHST(event.target.value);
  };

  const updateFilteredRowData = () => {
    let filteredData = allTableRowData;
    if (!filteredData) {
      // nothing to do
      return;
    }

    if (selectedFilteredPortfolios.length > 0) {
      filteredData = filteredData.filter(
        (series) =>
          selectedFilteredPortfolios.indexOf(
            series._portfolioSeries?.topic__portfolio?.[0]?.id,
          ) > -1,
      );
    }

    if (selectedFilteredSeries.length > 0) {
      filteredData = filteredData.filter((series) =>
        series._portfolioSeries.filterGroup?.some(
          (filter) => selectedFilteredSeries.indexOf(filter) > -1,
        ),
      );
    }

    if (selectedFilteredHST.length > 0) {
      const filterByHst = selectedFilteredHST.indexOf('hst') > -1;
      const filterByNonHst = selectedFilteredHST.indexOf('non-hst') > -1;
      filteredData = filteredData.filter(
        (series) =>
          (filterByHst && series._portfolioSeries?.nonHst !== true) ||
          (filterByNonHst && series._portfolioSeries?.nonHst === true),
      );
    }

    setCurrentTableRows(filteredData);
  };

  React.useEffect(() => {
    // get portfolio series based on fundataFor - most often we are
    // using the series that are part of the portfolio, however, for pages
    // like the Performance page, we want to show every series associated
    // with the current website
    if (fundataFor === 'individualPortfolio') {
      setCurrentSeriesList(portfolio?.series);
    } else if (fundataFor === 'allPortfolios') {
      setCurrentSeriesList(allPortfolioSeries);
    }
  }, [portfolio, allPortfolioSeries]);

  React.useEffect(() => {
    // get distribution dates once current series are deteremined
    const seriesDistributionDatePromises = currentSeriesList.map((series) =>
      getDistributionDates(series),
    );

    if (!currentSeriesList.length || !seriesDistributionDatePromises.length) {
      return;
    }

    Promise.all(seriesDistributionDatePromises).then(
      (distributionDateResponses) => {
        const uniqueDatesList = [];

        for (const dateList of distributionDateResponses) {
          for (const currentDate of dateList) {
            const hasDate = uniqueDatesList.some((date) =>
              isSameDay(date, currentDate),
            );
            if (!hasDate) {
              uniqueDatesList.push(currentDate);
            }
          }
        }

        // sort descending from most recent to oldest
        uniqueDatesList.sort((a, b) => b.getTime() - a.getTime());
        setDistributionDates(uniqueDatesList);
      },
    );
  }, [currentSeriesList]);

  React.useEffect(() => {
    if (!distributionDates || distributionDates.length === 0) {
      return;
    }

    // set selected date
    // first item should be most recent due to sorting
    setSelectedDate(distributionDates[0]);
    setSelectedDateOption(distributionDates[0].toISOString());
  }, [distributionDates]);

  React.useEffect(() => {
    if (!selectedDate || !currentSeriesList || currentSeriesList.length === 0) {
      return;
    }

    setIsLoading(true);
    setIsError(false);
    const selectedDateYYYYMMDD = selectedDate.toISOString().substring(0, 10);
    // fetch distribution responses
    const distributionResponsePromises = currentSeriesList.map((series) =>
      getDistributionResponse(series, selectedDateYYYYMMDD, locale).catch(
        // catch errors
        // eslint-disable-next-line no-console
        (error) => console.error(error),
      ),
    );

    // group sort and set table row
    Promise.all(distributionResponsePromises)
      .then((distributionResponses) => {
        const sortedSeries = groupSortSeries(
          distributionResponses.flatMap((value) => (value ? [value] : [])),
        );

        // only if we have no data, flag error
        if (!sortedSeries || sortedSeries.length === 0) {
          setIsError(true);
        }

        setAllTableRowData(sortedSeries);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching distribution results.', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentSeriesList, selectedDate]);

  React.useEffect(() => {
    updateFilteredRowData();
  }, [allTableRowData]);

  React.useEffect(() => {
    // determine the available options for filters from all the
    // series available
    const availablePortfolios = allPortfolioSeries
      .flatMap((series) => {
        return series.topic__portfolio;
      })
      ?.filter(
        // remove duplicates
        (portfolio, index, array) =>
          index ===
          array.findIndex((_portfolio) => _portfolio.id === portfolio.id),
      )
      ?.sort(function (a, b) {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    const availableSeriesFilters = allPortfolioSeries
      ?.flatMap((series) => {
        return series.filterGroup;
      })
      ?.filter(
        // remove empty items
        (seriesFilter) => seriesFilter != null,
      )
      ?.filter(
        // remove duplicates
        (seriesFilter, index, array) =>
          index === array.findIndex((sf) => sf === seriesFilter),
      )
      ?.sort(function (a, b) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
    const availableHST = [
      {
        id: 'hst',
        title: getTranslation('Hst', locale),
      },
      {
        id: 'non-hst',
        title: getTranslation('NonHst', locale),
      },
    ];

    setPortfolioFilters(availablePortfolios);
    setSeriesFilters(availableSeriesFilters);
    setHSTFilters(availableHST);
  }, [allPortfolioSeries]);

  // updates the filtered data
  React.useEffect(() => {
    updateFilteredRowData();
  }, [selectedFilteredPortfolios, selectedFilteredSeries, selectedFilteredHST]);

  const tableFontSize = overrideTableFontSize ? body : '';

  return (
    <div className="container">
      <div className="lg:grid gap-s3 grid-cols-10">
        <div
          className={`${
            headlineOrientation === 'split' ? 'lg:col-span-4' : 'lg:col-span-12'
          }`}
        >
          <div className="md:flex justify-between items-start">
            {title && (
              <Typography as="div" variant="h1">
                {title}
              </Typography>
            )}
            <TextField
              select
              label={dateSelectorLabel ?? getTranslation('Date', locale)}
              value={selectedDateOption}
              onChange={handleSelectedDateOptionChange}
              className="md:ml-auto mt-s2 md:mt-0 w-full max-w-[200px] md:max-w-1/4"
            >
              {distributionDates?.map((date) => {
                const key = date.getTime();
                const value = date.toISOString();
                return (
                  <MenuItem key={key} value={value}>
                    {formatLocalizedDate(date, locale, {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    })}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
          {subheading && (
            <Typography as="div" variant="body" className="mt-s2">
              {subheading.subheading}
            </Typography>
          )}
        </div>

        {showPortfolioFilters && (
          <div
            className={`mb-s4 flex flex-col md:grid md:grid-cols-4 md:gap-x-s1 ${
              headlineOrientation === 'split'
                ? 'lg:col-span-6'
                : 'lg:col-span-12 lg:w-full'
            }`}
          >
            {portfolioFilters && portfolioFilters.length && (
              <FormControl className="mt-s2 md:mt-0">
                <InputLabel id="filtered-portfolios-checkbox-label">
                  {getTranslation('FilterBy', locale)}{' '}
                  {getTranslation('Portfolio', locale)}
                </InputLabel>
                <Select
                  labelId="filtered-portfolios-checkbox-label"
                  id="filtered-portfolios-checkbox"
                  multiple
                  value={selectedFilteredPortfolios}
                  onChange={handleSelectedFilteredPortfoliosChange}
                  renderValue={(selected: string[]) => {
                    return portfolioFilters
                      ?.filter((filter) => selected?.indexOf(filter.id) > -1)
                      ?.map((filter) => filter.title)
                      ?.join(', ');
                  }}
                  input={<Input />}
                >
                  {portfolioFilters?.map((filter) => (
                    <MenuItem key={filter.id} value={filter.id}>
                      <Checkbox
                        checked={
                          selectedFilteredPortfolios.indexOf(filter.id) > -1
                        }
                      />
                      <ListItemText primary={filter.title} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {seriesFilters && seriesFilters.length > 0 && (
              <FormControl className="mt-s2 md:mt-0">
                <InputLabel id="filtered-series-category-checkbox-label">
                  {getTranslation('FilterBy', locale)}{' '}
                  {getTranslation('Series', locale)}
                </InputLabel>
                <Select
                  labelId="filtered-series-category-checkbox-label"
                  id="filtered-series-category-checkbox"
                  multiple
                  value={selectedFilteredSeries}
                  onChange={handleSelectedFilteredSeriesCategoryChange}
                  renderValue={(selected: string[]) => {
                    return seriesFilters
                      ?.filter((filter) => selected?.indexOf(filter) > -1)
                      ?.join(', ');
                  }}
                  input={<Input />}
                >
                  {seriesFilters?.map((filter) => (
                    <MenuItem key={filter} value={filter}>
                      <Checkbox
                        checked={selectedFilteredSeries.indexOf(filter) > -1}
                      />
                      <ListItemText
                        primary={filter.replace(
                          'Series',
                          getTranslation('Series', locale),
                        )}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl className="mt-s2 md:mt-0">
              <InputLabel id="filtered-hst-checkbox-label">
                {getTranslation('FilterBy', locale)}{' '}
                {getTranslation('Hst', locale)} /{' '}
                {getTranslation('NonHst', locale)}
              </InputLabel>
              <Select
                labelId="filtered-hst-checkbox-label"
                id="filtered-hst-checkbox"
                multiple
                value={selectedFilteredHST}
                onChange={handleSelectedFilteredHSTChange}
                renderValue={(selected: string[]) => {
                  return HSTFilters?.filter(
                    (filter) => selected?.indexOf(filter.id) > -1,
                  )
                    ?.map((filter) => filter.title)
                    ?.join(', ');
                }}
                input={<Input />}
              >
                {HSTFilters?.map((filter) => (
                  <MenuItem key={filter.id} value={filter.id}>
                    <Checkbox
                      checked={selectedFilteredHST.indexOf(filter.id) > -1}
                    />
                    <ListItemText primary={filter.title} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}

        <div
          className={
            headlineOrientation === 'split'
              ? 'lg:col-span-6'
              : 'lg:col-span-12 lg:w-full'
          }
        >
          {!isLoading && (
            <TableWrapper>
              {showHorizontalIndicator && <HorizontalScrollIndicator />}

              <TableContainer
                ref={ScrollableContainerRef}
                style={{
                  minHeight: tableMinHeight ? `${tableMinHeight}px` : 'auto',
                  maxHeight: tableMaxHeight ? `${tableMaxHeight}px` : 'auto',
                }}
                component="div"
              >
                <Table
                  stickyHeader={tableFixedHeaders ? true : false}
                  aria-label={title}
                >
                  <TableHead>
                    <TableRow>
                      {tableColumnData?.map((column, index) => {
                        const columnStyle = getColumnStyles(
                          column,
                          tableFontSize,
                        );
                        const columnAlign = getColumnAlignment(column);

                        return (
                          <TableCell
                            style={columnStyle}
                            align={columnAlign}
                            key={`column-${column.id}-${index}`}
                          >
                            {column.additionalInfo && (
                              <sup>{definitionIndex++}</sup>
                            )}
                            {column.title}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentTableRows &&
                      currentTableRows.length > 0 &&
                      currentTableRows.map((row, rowIndex) => {
                        return (
                          row != null && (
                            <TableRow key={`row-${rowIndex}`}>
                              {tableColumnData?.map((column, columnIndex) => {
                                const columnStyle = getColumnStyles(
                                  column,
                                  tableFontSize,
                                );
                                const columnAlign = getColumnAlignment(column);

                                let tableCellValue: string | JSX.Element = '-';
                                if (columnIndex === 0) {
                                  const slug =
                                    row?._portfolioSeries?.topic__portfolio?.[0]
                                      ?.compose__page?.[0].slug;
                                  const seriesTitle =
                                    row._portfolioSeries?.title;
                                  tableCellValue = slug ? (
                                    // link will always be internal
                                    // eslint-disable-next-line react/jsx-no-undef
                                    <LocalizedLink
                                      language={locale}
                                      to={`/${slug}`}
                                      className="underline"
                                    >
                                      {seriesTitle}
                                    </LocalizedLink>
                                  ) : (
                                    seriesTitle
                                  );
                                } else {
                                  let value = row[column.key];
                                  if (!value) {
                                    value = 0;
                                  }
                                  const decimalPlaces =
                                    column.settingDecimalPlaces != null
                                      ? column.settingDecimalPlaces
                                      : 4;
                                  tableCellValue = formatLocalizedCurrency(
                                    value,
                                    locale,
                                    decimalPlaces,
                                  );
                                }

                                return (
                                  <TableCell
                                    style={columnStyle}
                                    align={columnAlign}
                                    key={`row-${rowIndex}-cell-${columnIndex}`}
                                  >
                                    {tableCellValue}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          )
                        );
                      })}
                  </TableBody>
                </Table>

                {currentTableRows && currentTableRows.length === 0 && (
                  <Typography as="div" variant="body" className="my-s2">
                    <em>No results</em>
                  </Typography>
                )}
              </TableContainer>
            </TableWrapper>
          )}
          {footnote && (
            <Typography
              as="small"
              variant="body"
              className="block mt-s2 text-xs leading-relaxed"
            >
              {renderContentfulRichText(
                footnote as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                footnoteRichTextOptions,
              )}
            </Typography>
          )}
          {isLoading && (
            <div className="container pt-l1 flex justify-center">
              <CircularProgress aria-label="Loading data" />
            </div>
          )}
          {!isLoading && isError && (
            <Alert severity="info" className="mt-s3">
              <strong>Sorry!</strong> Data temporarily unavailable, but we
              {`'`}re working to get it back soon.
            </Alert>
          )}
        </div>
      </div>

      {getDefinitionsList(definitions, locale)}
    </div>
  );
};

export default DataVisualizationTableDistributions;
