import React from 'react';
import {
  ContentfulAsset,
  ContentfulComponentCtaButton,
  ContentfulTopicCategory,
} from '../../graphql-types';
import { useGlobalState } from '../hooks/useGlobalState';
import { WEBSITE } from '../types/website.enum';
import Button from './Button';
import ContentfulImage from './ContentfulImage';
import Typography from './Typography';

interface MediaSetProps {
  className?: string;
  title?: string;
  button?: ContentfulComponentCtaButton;
  variant?: string;
  images?: ContentfulAsset[];
  categories?: ContentfulTopicCategory[];
}
const MediaSet: React.FC<MediaSetProps> = (props) => {
  const { className, title, button, variant, images, categories } = props;
  const { site } = useGlobalState();

  // variants: 'horizontal' | 'vertical'
  // default horizontal
  let mediaSetVariantElement = (
    <div className="container pb-m4">
      <div className="flex flex-col lg:flex-row">
        {title && (
          <Typography
            as="h2"
            variant="h2"
            className={site === WEBSITE.EDGEPOINT ? 'uppercase' : ''}
          >
            {title}
          </Typography>
        )}
        {button && (
          <div className="ml-auto">
            <Button
              link={button?.page?.slug || button?.externalUrl}
              openInNewWindow={button?.openInNewWindow}
            >
              {button.text}
            </Button>
          </div>
        )}
      </div>
      {images && images.length > 0 && (
        <ul className="mt-s3 lg:grid grid-cols-12 gap-x-s3 gap-y-s3">
          {images.map((image) => {
            // need static strings for TW to interpolate
            const columnClass =
              images.length === 1
                ? 'col-span-1'
                : images.length === 2
                ? 'col-span-6'
                : images.length === 3
                ? 'col-span-4'
                : 'col-span-3';
            return (
              <li key={image.id} className={`${columnClass}`}>
                <ContentfulImage image={image} alt={image.description || ''} />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );

  if (variant === 'vetical') {
    mediaSetVariantElement = (
      <div className="container p-s2 bg-red-100">
        TODO: MediaSet - Vertical variant
      </div>
    );
  }

  return <div className={`${className || ''}`}>{mediaSetVariantElement}</div>;
};

export default MediaSet;
