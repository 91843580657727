import React from 'react';
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';
import {
  ContentfulAsset,
  ContentfulComponentCtaButton,
  ContentfulComponentTextBlockBody,
} from '../../graphql-types';
import Button from './Button';
import Typography from './Typography';
import ContentfulImage from './ContentfulImage';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';

interface TextBlockProps {
  title?: string;
  body?: ContentfulComponentTextBlockBody;
  buttons?: ContentfulComponentCtaButton[];
  variant?: string;
  image?: ContentfulAsset;
  darkMode?: boolean;
  className?: string;
  isChildComponent?: boolean;
}

const TextBlock: React.FC<TextBlockProps> = (props) => {
  const { title, body, buttons, variant, image, className } = props;

  // default variant is 'split'
  let textBlockVariant = (
    <div className="lg:grid gap-s3 grid-cols-10">
      <div className="lg:col-span-4">
        {title && (
          <Typography variant="h1" as="h2" className="mb-4">
            {title}
          </Typography>
        )}
        {image && (
          <ContentfulImage
            image={image}
            alt={image?.description || ''}
            style={{ width: '90%' }}
            objectPosition="top left"
            objectFit="contain"
          />
        )}
      </div>
      <div className="mt-s4 lg:mt-0 lg:col-span-6">
        {body && (
          <Typography as="div" variant="body">
            {renderContentfulRichText(
              body as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
            )}
          </Typography>
        )}
        {buttons?.length > 0 && (
          <ul className="flex flex-col md:flex-row">
            {buttons?.map((button, index) => (
              <li
                key={`button-list-item-${button?.id}-${index}`}
                className="md:ml-s3 first:ml-0 mt-s4 flex items-center"
              >
                <Button
                  key={button?.id}
                  variant={button?.variant}
                  link={button?.page?.slug || button?.externalUrl}
                  openInNewWindow={button?.openInNewWindow}
                >
                  {button?.text}
                </Button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );

  if (variant === 'stacked') {
    textBlockVariant = (
      <div className="">
        {title && (
          <Typography variant="h1" as="h2" className="mb-4">
            {title}
          </Typography>
        )}
        {body && (
          <Typography as="div" variant="body">
            {renderContentfulRichText(
              body as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
            )}
          </Typography>
        )}
        {buttons?.length > 0 && (
          <ul className="flex mt-s4">
            {buttons?.map((button, index) => (
              <li
                key={`button-list-item-${button?.id}-${index}`}
                className="ml-s3 first:ml-0 flex items-center"
              >
                <Button
                  key={button?.id}
                  variant={button?.variant}
                  link={button?.page?.slug || button?.externalUrl}
                  openInNewWindow={button.openInNewWindow}
                >
                  {button?.text}
                </Button>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }

  return (
    <section className={`container ${className}`}>{textBlockVariant}</section>
  );
};

export default TextBlock;
