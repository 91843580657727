import React from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormikConfig, useFormik } from 'formik';
import * as yup from 'yup';
import { ContentfulGlobalMicrocopy } from '../../graphql-types';
import Button from './Button';
import Typography from './Typography';
import { getPlainTextFromMicrocopy } from '../utils/getPlainTextFromMicrocopy';
import { submitEmailSubscription } from '../services/email-subscription.service';
import { Alert, AlertTitle } from '@material-ui/lab';

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 0px;
    background-color: ${(props) => props.theme.color.white};

    & fieldset {
      border-color: ${(props) => props.theme.color.primary};
    }
  }
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    white-space: nowrap;
  }
`;

enum FORM_STATE {
  INITIAL,
  SUCCESS,
  ERROR,
}

interface SignUpProps {
  className?: string;
  title?: string;
  variant?: string;
  formFieldLabels?: ContentfulGlobalMicrocopy[];
}
interface SignUpLabels {
  SignUpButtonLabel?: string;
  SignUpEmail?: string;
  SignUpEmailValidation?: string;
  SignUpEmailRequired?: string;
  SignUpSuccess?: string;
  SignUpError?: string;
}
const SignUpSubscription: React.FC<SignUpProps> = (props) => {
  const { className, title, formFieldLabels } = props;

  // setup form labels, validation, and formik options
  const [formState, setFormState] = React.useState(FORM_STATE.INITIAL);
  const labels: SignUpLabels = {};
  formFieldLabels?.forEach((formField) => {
    labels[formField.key] = getPlainTextFromMicrocopy(formField.copy?.raw);
  });

  const signUpFormSchema = yup.object().shape({
    email: yup
      .string()
      .email(
        labels.SignUpEmailValidation || 'Please enter a valid email address',
      )
      .required(labels.SignUpEmailRequired || 'This field is mandatory'),
    recaptchaToken: yup.string().required(),
  });
  const signUpFormikOptions: FormikConfig<{
    email: string;
    recaptchaToken: string;
  }> = {
    initialValues: {
      email: '',
      recaptchaToken: '',
    },
    validationSchema: signUpFormSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!values.recaptchaToken) {
        handleReCaptchaVerify();
        return;
      }

      submitEmailSubscription(values.email, values.recaptchaToken)
        .then(() => {
          setFormState(FORM_STATE.SUCCESS);

          // unset success message afterwards
          setTimeout(() => {
            setFormState(FORM_STATE.INITIAL);
          }, 5000);

          // reset form
          resetForm();
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Could not submit form', error);
          setFormState(FORM_STATE.ERROR);
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  };
  const formik = useFormik(signUpFormikOptions);

  // reCAPTCHAv3 state and functions
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      // eslint-disable-next-line no-console
      console.error('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('sign_up');
    formik.setFieldValue('recaptchaToken', token);

    // N.B. reCAPTCHA tokens expire after 2 minutes, so we only
    // fire this function when users click on the email form
  }, [executeRecaptcha]);

  return (
    <div className="">
      {title && (
        <Typography
          as="div"
          variant="callout"
          className={`${className || 'text-center md:text-left'}`}
        >
          {title}
        </Typography>
      )}
      {formik && (
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className="flex flex-wrap gap-y-s2 items-bottom mt-s2">
            <StyledTextField
              id="email"
              name="email"
              type="email"
              label={labels.SignUpEmail || '* Email'}
              variant="outlined"
              placeholder="yourname@email.com"
              className="flex-1 min-w-[150px]"
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
              onClick={handleReCaptchaVerify}
            />

            <Button
              type="submit"
              variant={`primary`}
              className="flex-none min-w-[150px]"
            >
              {labels.SignUpButtonLabel || `Sign Up`}
            </Button>
          </div>
          {formState === FORM_STATE.SUCCESS && (
            <Alert severity="success" className="mt-s2">
              <AlertTitle>
                {labels.SignUpSuccess ||
                  'Thank you! Your subscription was successful.'}
              </AlertTitle>
            </Alert>
          )}
          {formState === FORM_STATE.ERROR && (
            <Alert severity="error" className="mt-s2">
              <AlertTitle>
                {labels.SignUpError ||
                  'Sorry! An error occurred while submitting.'}
              </AlertTitle>
            </Alert>
          )}
        </form>
      )}
    </div>
  );
};

export default SignUpSubscription;
