import React from 'react';
import { CircularProgress, Fade } from '@material-ui/core';
import { useLocalization } from 'gatsby-theme-i18n';
import { Alert } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { enCA, frCA, zhCN } from 'date-fns/locale';
import {
  ContentfulTopicTabularDataSet,
  ContentfulTopicPortfolioSeries,
} from '../../graphql-types';
import Typography from './Typography';
import {
  getDateWithLocaleOffset,
  getLastBusinessDate,
  isBusinessDay,
} from '../services/date.service';
import { useGlobalState } from '../hooks/useGlobalState';
import { getFundataV2 } from '../services/fundata-v2.service';
import { PricingResponse } from '../services/fundata-v2.types';
import { getCurrentSessionToken, isLoggedIn } from '../services/login.service';
import { getShares } from '../services/shares.service';
import { getDisplayValue } from '../services/table.service';
import { getTranslation } from '../services/translation.service';

interface DataVisualizationStatsFundataProps {
  title?: string;
  showDateSelector?: boolean;
  dateSelectorLabel?: string;
  dataSet: ContentfulTopicTabularDataSet;
}

const DataVisualizationStatsFundata: React.FC<
  DataVisualizationStatsFundataProps
> = (props) => {
  const { title, dataSet, showDateSelector, dateSelectorLabel } = props;
  const { locale } = useLocalization();
  const muiPickerLocale =
    locale === 'en' ? enCA : locale === 'fr' ? frCA : zhCN;
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [renderedStatsList, setRenderedStatsList] =
    React.useState<JSX.Element>(null);

  const fundataSource = dataSet?.funDataSource;

  // setup and state for the date selector
  const lastBusinessDate = getLastBusinessDate();
  const [selectedClosingDate, setSelectedClosingDate] =
    React.useState(lastBusinessDate);
  const [minDate] = React.useState<Date>(
    getDateWithLocaleOffset('2008-11-04'), // arbitrary cut off
  );
  const [pricingMap, setPricingMap] = React.useState<
    Map<string, PricingResponse>
  >(new Map());

  const { portfolio } = useGlobalState();

  const handleClosingDateChange = (date) => {
    setSelectedClosingDate(date);
  };

  const fetchPortfolioData = async () => {
    // reset state variables
    setIsLoading(true);
    setIsError(false);

    const closingDateString = selectedClosingDate
      ?.toISOString()
      ?.substring(0, 10);

    const seriesList: Map<string, PricingResponse> = new Map();
    // get each portfolio series data
    if (portfolio?.series && portfolio?.series.length > 0) {
      for (const series of portfolio?.series) {
        if (series.isGated) {
          // if user is not logged in, skip series
          const isUserLoggedIn = await isLoggedIn();
          if (!isUserLoggedIn) {
            continue;
          }

          // use private shares endpoint to retrieve data
          const sessionToken = await getCurrentSessionToken();
          const result = await getShares(
            fundataSource,
            sessionToken,
            closingDateString,
          ).catch(() => {
            // swallow errors
          });

          if (!result) {
            // eslint-disable-next-line no-console
            console.warn(`Error fetching pricing for ${series.title}`);
            setIsError(true);
            continue;
          }

          seriesList.set(series.instrumentKey, result as PricingResponse);
        } else if (series.instrumentKey) {
          const result = await getFundataV2<PricingResponse>(
            fundataSource,
            locale,
            series.instrumentKey,
            closingDateString,
          ).catch(() => {
            // swallow errors
          });

          if (!result) {
            // eslint-disable-next-line no-console
            console.warn(`Error fetching pricing for ${series.title}`);
            setIsError(true);
            continue;
          }

          seriesList.set(series.instrumentKey, result);
        }
      }
    }

    setIsLoading(false);
    setPricingMap(seriesList);
  };

  const renderStatsList = (series: ContentfulTopicPortfolioSeries[]) => {
    const headerRow = (
      <li
        key={`series-column-titles`}
        className="hidden sm:flex flex-row mb-s4"
      >
        {dataSet?.columns?.map((column, columnIndex) => (
          <Typography
            key={`column-${columnIndex}`}
            as="div"
            variant="subheading"
            className="uppercase flex-1"
          >
            {column.title}
          </Typography>
        ))}
      </li>
    );

    const seriesElements = series?.map((portfolioSeries, i) => {
      const seriesPricing = pricingMap.get(portfolioSeries.instrumentKey);
      return seriesPricing ? (
        <li
          key={`series-${portfolioSeries.id}-${i}`}
          className={`${i === 0 ? 'mt-0' : 'mt-s4'} flex flex-col sm:flex-row`}
        >
          {dataSet?.columns?.map((column, columnIndex) => {
            let displayValue = '';
            if (columnIndex === 0) {
              displayValue = portfolioSeries.title.slice(-1);
            } else {
              displayValue = getDisplayValue(
                seriesPricing?.[column.key],
                column.type,
                locale,
                { decimalPlaces: column.settingDecimalPlaces },
              );
            }

            return (
              <div
                className="flex sm:flex-col justify-between flex-1 mt-s4 first:mt-0 sm:mt-0"
                key={`series-${portfolioSeries.id}-column-${columnIndex}`}
              >
                <Typography
                  as="p"
                  variant="subheading"
                  className="uppercase sm:hidden"
                >
                  {column.title}
                </Typography>
                <Typography as="p" variant="s2">
                  {displayValue}
                </Typography>
              </div>
            );
          })}
        </li>
      ) : (
        <React.Fragment key={`no-data-${i}`}></React.Fragment>
      );
    });

    return (
      <ul>
        {headerRow}
        {seriesElements}
      </ul>
    );
  };

  React.useEffect(() => {
    if (portfolio) {
      fetchPortfolioData();
    }
  }, [portfolio, selectedClosingDate]);

  React.useEffect(() => {
    setRenderedStatsList(renderStatsList(portfolio?.series));
  }, [portfolio, pricingMap]);

  return (
    <div className="container">
      {title && (
        <Typography as="h2" variant="h2">
          {title}
        </Typography>
      )}
      <div className="mt-m2 flex flex-col justify-between lg:flex-row">
        {showDateSelector && (
          <div className="mr-m2 max-w-xs">
            <MuiPickersUtilsProvider
              locale={muiPickerLocale}
              utils={DateFnsUtils}
            >
              <KeyboardDatePicker
                value={selectedClosingDate}
                variant="inline"
                format={locale === 'fr' ? 'd MMM yyyy' : 'MMM d, yyyy'}
                margin="normal"
                id="start-date-picker"
                label={dateSelectorLabel || getTranslation('Date', locale)}
                onChange={handleClosingDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change closing anav date',
                }}
                InputProps={{
                  readOnly: true,
                }}
                disableFuture
                shouldDisableDate={(date) => !isBusinessDay(date)}
                minDate={minDate}
                maxDate={lastBusinessDate}
                fullWidth
                autoOk={true}
                className="flex-1 mt-0"
              />
            </MuiPickersUtilsProvider>
          </div>
        )}
        <div className="flex-grow mt-m1 lg:mt-0">
          {!isLoading && renderedStatsList}
          <Fade in={isLoading} unmountOnExit>
            <div className="flex justify-center">
              <CircularProgress aria-label="Loading data" />
            </div>
          </Fade>
          {!isLoading && isError && (
            <div className="flex justify-start flex-grow mt-m1">
              <Alert severity="info">
                <strong>Sorry!</strong> Data temporarily unavailable, but we
                {`'`}re working to get it back soon.
              </Alert>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataVisualizationStatsFundata;
