import Highcharts from 'highcharts';
import { utils } from 'xlsx';
import {
  ContentfulAsset,
  ContentfulTopicRow,
  ContentfulTopicTabularDataSet,
} from '../../graphql-types';
import { getJSONFromExcel } from '../utils/getJSONfromExcel';
import {
  getRowDataFromDataFile,
  parseTabularDataSetColumnsAndRows,
} from '../utils/parseTabularDataSet';
import { formatLocalizedCurrency } from './localization.service';

export interface HighchartsPieChartSeries {
  name: string;
  y: number;
}

export const HIGHCHARTS_DEFAULT_OPTIONS = {
  chart: {
    style: {
      fontFamily: 'roboto,sans-serif',
      fontSize: '12px',
    },
  },
  title: {
    style: {
      fontFamily: 'montserrat,sans-serif',
      fontSize: '22px',
      fontWeight: '500',
      textTransform: 'uppercase',
    },
  },
};

export const HIGHCHARTS_FR_OPTIONS = {
  ...HIGHCHARTS_DEFAULT_OPTIONS,
  lang: {
    decimalPoint: ',',
    months: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
    weekdays: [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
    ],
  },
};
export const HIGHCHARTS_EN_OPTIONS = {
  ...HIGHCHARTS_DEFAULT_OPTIONS,
  lang: {
    decimalPoint: '.',
  },
};

export const DATA_VISUALIZATION_VARIANTS = {
  TABLE: 'table',
  PIE_CHART: 'pie-chart',
  PIE_CHART_ALT: 'pie-chart-alt',
};

export const DEFAULT_COLOURS = [
  '#FF863B',
  '#918F8F',
  '#FFB689',
  '#FFCFB1',
  '#7B7979',
  '#A7A5A6',
  '#FF9E62',
  '#BDBCBC',
  '#231F20',
  '#656263',
  '#D3D2D2',
  '#4F4C4D',
  '#393536',
];

export const PIE_CHART_OPTIONS: Highcharts.Options = {
  colors: DEFAULT_COLOURS,
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    spacing: [1, 1, 1, 1],
  },
  title: {
    text: '',
  },
  tooltip: {},
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      clip: false,
      showInLegend: true,
      size: '75%',
      center: ['70%', '60%'],
      point: {
        events: {
          legendItemClick: function () {
            return false;
          },
        },
      },
      dataLabels: {
        enabled: false,
        padding: 0,
        style: {
          fontSize: '11px',
          textOverflow: 'clip',
        },
        distance: 10,
      },
    },
  },
  legend: {
    enabled: false,
    floating: true,
    layout: 'vertical',
    align: 'left',
    verticalAlign: 'top',
    margin: 0,
    padding: 0,
  },
  series: [],
};

export const getPieChartOptions = (
  variant: string,
  locale: string,
  title: string,
  data: Highcharts.PointOptionsObject[],
): Highcharts.Options => {
  const options: Highcharts.Options = JSON.parse(
    JSON.stringify(PIE_CHART_OPTIONS),
  );
  const dataLabels = options.plotOptions.pie
    .dataLabels as Highcharts.SeriesPieDataLabelsOptionsObject;
  switch (variant) {
    case DATA_VISUALIZATION_VARIANTS.PIE_CHART: {
      dataLabels.enabled = true;
      options.legend.enabled = false;
      options.plotOptions.pie.size = '50%';
      options.plotOptions.pie.center = ['50%', '50%'];
      break;
    }
    case DATA_VISUALIZATION_VARIANTS.PIE_CHART_ALT: {
      options.legend.enabled = true;
      dataLabels.enabled = false;
      options.plotOptions.pie.size = '75%';
      options.plotOptions.pie.center = ['70%', '60%'];
      break;
    }
  }

  options.tooltip.pointFormat =
    locale === 'fr'
      ? '<b>{point.percentage:.1f} %</b>'
      : '<b>{point.percentage:.1f}%</b>';
  options.legend.labelFormat = `<b>{name}</b>${
    locale === 'fr' ? ' : {percentage:.1f} %' : ': {percentage:.1f}%'
  }`;
  dataLabels.format = `<b>{point.name}</b>${
    locale === 'fr'
      ? ' : {point.percentage:.1f} %'
      : ': {point.percentage:.1f}%'
  }`;
  options.series.push({
    type: 'pie',
    name: title,
    colorByPoint: true,
    data: data,
  });

  return options;
};

export interface HighchartsSeriesValue {
  x: string | number;
  y: string | number;
}

export const ChartTypeMap = {
  'pie-chart': 'pie',
  'pie-chart-alt': 'pie',
  'line-chart': 'spline',
  'bar-chart': 'bar',
  'column-chart': 'column',
};

export const getSeriesFromRowData = (
  rowData: ContentfulTopicRow[],
): HighchartsSeriesValue[] => {
  return rowData?.map((item) => ({
    x: +item.values[0].value,
    y: +item.values[1].value,
  }));
};

export const getSeriesData = (
  dataSets: ContentfulTopicTabularDataSet[],
  type: string,
): Promise<Highcharts.SeriesOptionsType>[] => {
  return dataSets.map(
    async (dataSet): Promise<Highcharts.SeriesOptionsType> => {
      const seriesName = dataSet.title;
      let seriesData = [];

      if (dataSet.dataFile) {
        // data is from an excel file in the dataFile field
        const rowData = await getRowDataFromDataFile(dataSet);
        seriesData = getSeriesFromRowData(rowData);
      } else if (dataSet.columns && dataSet.rows) {
        // data is from the columns/rows fields
        const parsedDataSet = parseTabularDataSetColumnsAndRows(dataSet);
        // map the correct values to the expected Pie Chart series array
        seriesData = parsedDataSet?.map((item) => {
          return {
            x: +item[0].value,
            y: +item[1].value,
          };
        });
      } else if (dataSet.funDataSource) {
        // TODO: Is this needed?
        // data is from the fundataSource field
        // seriesData = await getSeriesFromFunData(dataSet.funDataSource).catch(
        //   // eslint-disable-next-line no-console
        //   (error) => console.log(error),
        // );
      } else {
        // eslint-disable-next-line no-console
        console.error(
          'Tabular Data Set does not have fields dataFile, columns, rows, or funDataSource for use on DataVisualization pie-chart variant',
        );
      }

      return {
        type: ChartTypeMap[type],
        name: seriesName,
        data: seriesData,
      };
    },
  );
};

export const getSeriesFromDataFile = async (
  dataFile: ContentfulAsset,
): Promise<HighchartsPieChartSeries[]> => {
  const workbook = await getJSONFromExcel(dataFile.file?.url);
  const sheet = workbook.Sheets?.Sheet1;

  const seriesData: { name: string; y: number }[] = [];
  const totalRows = utils.decode_range(sheet['!ref'])?.e?.r;
  for (let i = 0; i < totalRows; i++) {
    // since A1 and B1 is the header, we look for row 2 onwards
    const excelRow = i + 2;

    // map the correct values to the expected Pie Chart series array
    const name = sheet[`A${excelRow}`].v as string;
    const y = sheet[`B${excelRow}`].v as number;

    seriesData.push({
      name,
      y,
    });
  }

  return seriesData;
};

export const getHighchartsOptions = (
  series: any,
  xAxisTitle: string,
  yAxisTitle: string,
  yAxisFormatter: string,
  locale: string,
): Highcharts.Options => {
  return {
    colors: DEFAULT_COLOURS,
    title: {
      text: '',
    },
    xAxis: {
      // type: '',
      // allowDecimals: false,
      title: {
        text: xAxisTitle,
      },
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: yAxisTitle,
      },
      labels: {
        formatter: (options) => {
          return yAxisFormatter === 'dollar'
            ? formatLocalizedCurrency(+options.value, locale, 0)
            : Highcharts.numberFormat(+options.value, 0);
        },
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      floating: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    tooltip: {
      pointFormat:
        "<span style='color:{point.color}'>●</span> {series.name}: <b>${point.y:,.0f}</b><br/>",
    },
    accessibility: {
      description: `Image description: A chart for ${xAxisTitle}`,
    },
    series: series,
  };
};
