import React from 'react';
import {
  ContentfulComponentCalloutCta,
  ContentfulComponentCalloutCtaSet,
  ContentfulComponentArticleSet,
  ContentfulComponentComponentSet,
  ContentfulComponentDownload,
  ContentfulComponentHeroBanner,
  ContentfulComponentMediaCopy,
  ContentfulComponentPortfolioSet,
  ContentfulComponentStatsSet,
  ContentfulComponentTextBlock,
  ContentfulComponentList,
  ContentfulTopicPortfolio,
  ContentfulComponentOverviewSet,
  ContentfulComponentTeams,
  ContentfulComponentContactUs,
  ContentfulComponentCtaButton,
  ContentfulGlobalMicrocopy,
  ContentfulComponentMediaSet,
  ContentfulComponentDataVisualization,
  ContentfulComponentCalculator,
  ContentfulComponentPortfolioInformation,
  ContentfulComponentDownloadCsv,
  ContentfulComponentFlexible,
} from '../../graphql-types';
import { ContentfulComponent } from '../types/contentfulComponent';
import CalloutCta from './CalloutCta';
import CalloutCtaSet from './CalloutCtaSet';
import ContactUs from './ContactUs';
import ArticleSet from './ArticleSet';
import ComponentSet from './ComponentSet';
import Download from './Download';
import HeroBanner from './HeroBanner';
import MediaCopy from './MediaCopy';
import PortfolioInformation from './PortfolioInformation';
import FundataPortfolioSet from './PortfolioSet';
import StatsSet from './StatsSet';
import Typography from './Typography';
import TextBlock from './TextBlock';
import List from './List';
import OverviewSet from './OverviewSet';
import Teams from './Teams';
import Button from './Button';
import Microcopy from './Microcopy';
import MediaSet from './MediaSet';
import DataVisualization from './DataVisualization';
import Calculator from './Calculator';
import DownloadCsv from './DownloadCsv';
import Appearance from './Appearance';
import Flexible from './Flexible';
import { getPlainTextFromMicrocopy } from '../utils/getPlainTextFromMicrocopy';

interface ComponentSelectorProps {
  contentfulComponent: ContentfulComponent;
  className?: string;
  isChildComponent?: boolean;
}
const ComponentSelector: React.FC<ComponentSelectorProps> = (props) => {
  const { contentfulComponent, className = '', isChildComponent } = props;

  // determine which component to return
  let component = <></>;
  switch (contentfulComponent?.__typename) {
    case 'ContentfulComponentArticleSet': {
      const articleSet: ContentfulComponentArticleSet = contentfulComponent;
      component = (
        <ArticleSet
          button={articleSet.ctaButton}
          categories={articleSet.categories}
          featuredArticles={articleSet.promotedArticles}
          curatedArticles={articleSet.otherCuratedArticles}
          headlineAlignment={articleSet.headlineAlignment}
          limit={articleSet.limit}
          subheading={articleSet.subheading?.subheading}
          title={articleSet.title}
          variant={articleSet.variant}
          showFilters={articleSet.showFilters}
          filterSortOptions={articleSet.filterSortOptions}
          groupByCategory={articleSet.groupByCategory}
          allCategoriesLabel={articleSet.allCategoriesLabel}
          isButtonForLoadMore={articleSet.isButtonForLoadMore}
          className={className}
        />
      );
      break;
    }
    case 'ContentfulComponentCalloutCta': {
      const calloutCta: ContentfulComponentCalloutCta = contentfulComponent;
      component = (
        <CalloutCta
          title={calloutCta.title}
          subheading={calloutCta.subheading?.subheading}
          buttons={calloutCta.ctaButtons}
          className={className}
        />
      );
      break;
    }
    case 'ContentfulComponentCalloutCtaSet': {
      const calloutCtaSet: ContentfulComponentCalloutCtaSet =
        contentfulComponent;
      component = (
        <CalloutCtaSet
          callouts={calloutCtaSet.callouts}
          className={className}
          // title={calloutCtaSet.title}
        />
      );
      break;
    }
    case 'ContentfulComponentCalculator': {
      const calculator: ContentfulComponentCalculator = contentfulComponent;
      component = (
        <Calculator
          title={calculator.title}
          subheading={calculator.subheading?.subheading}
          footnote={calculator.footnote?.footnote}
          withdrawalAmountLabel={calculator.withdrawalAmountLabel}
          withdrawalAmountValidationMessage={
            calculator.withdrawalAmountValidationMessage
          }
          initialInvestmentLabel={calculator.initialInvestmentLabel}
          initialInvestmentValidationMessage={
            calculator.initialInvestmentValidationMessage
          }
          startDateLabel={calculator.startDateLabel}
          endDateLabel={calculator.endDateLabel}
          dateValidationMessage={calculator.dateValidationMessage}
          selectPortfolioLabel={calculator.selectPortfolioLabel}
          resultsFieldLabels={calculator.resultsFieldLabels}
          portfolioSeries={calculator.portfolioSeries}
          calculateButton={calculator.calculateButton}
          printButton={calculator.printButton}
          variant={calculator.variant}
          className={className}
        />
      );
      break;
    }
    case 'ContentfulComponentCtaButton': {
      const ctaButton: ContentfulComponentCtaButton = contentfulComponent;
      component = (
        <Button
          variant={ctaButton.variant}
          link={ctaButton.page?.slug || ctaButton.externalUrl}
          openInNewWindow={ctaButton.openInNewWindow}
          className={className}
        >
          {ctaButton.text}
        </Button>
      );
      break;
    }
    case 'ContentfulComponentContactUs': {
      // convert Contentful data structures to ContactUs expected data
      const contactUs: ContentfulComponentContactUs = contentfulComponent;
      const copy = new Map();
      const businessInfo = [];
      contactUs.content
        .filter(
          (item): item is ContentfulGlobalMicrocopy =>
            item?.__typename === 'ContentfulGlobalMicrocopy',
        )
        ?.forEach((item) => {
          if (item.key?.startsWith('business')) {
            businessInfo.push(<Microcopy copy={item.copy} />);
          } else {
            const key = item.key;
            const text = getPlainTextFromMicrocopy(item?.copy?.raw);
            copy.set(key, text);
          }
        });
      const submitButton = contactUs.content
        ?.filter(
          (item): item is ContentfulComponentCtaButton =>
            item?.__typename === 'ContentfulComponentCtaButton',
        )
        ?.map((button) => ({
          key: button.key,
          text: button.text,
          variant: button.variant,
        }))
        ?.find((button) => button.key === 'submit-button');
      component = (
        <ContactUs
          businessInfo={businessInfo}
          className={className}
          copy={copy}
          endpointUrl={contactUs.endpointUrl}
          formId={contactUs.formId}
          layout={contactUs.layout}
          submitButton={submitButton}
          title={contactUs.title}
          type={contactUs.type}
        />
      );
      break;
    }
    case 'ContentfulComponentComponentSet': {
      const componentSet: ContentfulComponentComponentSet = contentfulComponent;
      component = (
        <div className="container">
          {/* container must be outside component so that the internals can layout themselves */}
          <ComponentSet
            components={componentSet.components}
            showDivider={componentSet.showDivider}
            variant={componentSet.variant}
            gridType={componentSet.gridType}
            className={className}
            isChildComponent={isChildComponent}
          />
        </div>
      );
      break;
    }
    case 'ContentfulComponentDataVisualization': {
      const dataVisualization: ContentfulComponentDataVisualization =
        contentfulComponent;
      component = (
        <DataVisualization {...dataVisualization} className={className} />
      );
      break;
    }
    case 'ContentfulComponentDownload': {
      const download: ContentfulComponentDownload = contentfulComponent;
      component = (
        <Download
          documentSets={download.documentSets}
          title={download.title}
          subheading={download.subheading?.subheading}
          fieldLabel={download.fieldLabel}
          secondaryFieldLabel={download.secondaryFieldLabel}
          button={download.ctaButton}
          variant={download.variant}
          darkMode={download.darkerBackground}
          listColumns={download.listColumns}
          className={className}
        />
      );
      break;
    }
    case 'ContentfulComponentDownloadCsv': {
      const downloadCsv: ContentfulComponentDownloadCsv = contentfulComponent;
      component = (
        <DownloadCsv
          title={downloadCsv.title}
          subheading={downloadCsv.subheading?.subheading}
          footnote={downloadCsv.footnote?.footnote}
          startDateLabel={downloadCsv.startDateLabel}
          endDateLabel={downloadCsv.endDateLabel}
          selectPortfolioGroupLabel={downloadCsv.selectPortfolioGroupLabel}
          selectPortfolioLabel={downloadCsv.selectPortfolioLabel}
          portfolioSeries={downloadCsv.portfolioSeries}
          groupByPortfolio={downloadCsv.groupByPortfolio}
          downloadButton={downloadCsv.downloadButton}
          csvFieldLabels={downloadCsv.csvFields}
          className={className}
        />
      );
      break;
    }
    case 'ContentfulComponentFlexible': {
      const flexible: ContentfulComponentFlexible = contentfulComponent;
      component = (
        <Flexible
          alignment={flexible.alignment}
          layout={flexible.layout}
          image={flexible.featureImage}
          richText={flexible.richText}
          primaryText={flexible.primaryText}
          secondaryText={flexible.secondaryText}
          links={flexible.links}
          appearance={flexible.appearance}
          formFieldLabels={flexible.formFieldLabels}
          formVariant={flexible.formVariant}
        />
      );
      break;
    }
    case 'ContentfulComponentHeroBanner': {
      const heroBanner: ContentfulComponentHeroBanner = contentfulComponent;
      component = (
        <HeroBanner
          body={heroBanner.subheading?.subheading}
          header={heroBanner.title}
          image={heroBanner.image}
          buttons={heroBanner.ctaButtons}
          variant={heroBanner.variant}
          className={className}
        />
      );
      break;
    }
    case 'ContentfulComponentList': {
      const list: ContentfulComponentList = contentfulComponent;
      component = (
        <List
          title={list.title}
          variant={list.variant}
          items={list.items}
          footnote={list.footnote}
          className={className}
        />
      );
      break;
    }
    case 'ContentfulComponentMediaCopy': {
      const mediaCopy: ContentfulComponentMediaCopy = contentfulComponent;
      component = (
        <MediaCopy
          title={mediaCopy.title}
          subheading={mediaCopy.subheading?.subheading}
          media={mediaCopy.image}
          imageFocalPoint={mediaCopy.imageFocalPoint}
          mediaPosition={mediaCopy.mediaPosition}
          darkMode={mediaCopy.darkerBackground}
          videoUrl={mediaCopy.videoUrl}
          variant={mediaCopy.variant}
          buttons={mediaCopy.ctaButtons}
          footnote={mediaCopy.footnote}
          eyebrow={mediaCopy.eyebrow}
          className={className}
          isChildComponent={isChildComponent}
        />
      );
      break;
    }
    case 'ContentfulComponentMediaSet': {
      const mediaSet: ContentfulComponentMediaSet = contentfulComponent;
      component = (
        <MediaSet
          title={mediaSet.title}
          button={mediaSet.ctaButton}
          variant={mediaSet.variant}
          // videoUrls={mediaSet.videoUrLs}
          className={className}
          // TODO: When CMS has content, these should be added
          images={mediaSet.images}
          // categories={mediaSet.categories}
        />
      );
      break;
    }
    case 'ContentfulComponentOverviewSet': {
      const overviewSet: ContentfulComponentOverviewSet = contentfulComponent;
      component = (
        <OverviewSet
          // title={overviewSet.title}
          items={overviewSet.pages}
          className={className}
        />
      );
      break;
    }
    case 'ContentfulComponentPortfolioInformation': {
      const portfolioInformation: ContentfulComponentPortfolioInformation =
        contentfulComponent;
      component = (
        <PortfolioInformation
          title={portfolioInformation.title}
          subheading={portfolioInformation.subheading?.subheading}
          stats={portfolioInformation.stats}
          statsColumns={portfolioInformation.statsColumns}
          riskProfile={portfolioInformation.riskProfile}
          footnote={portfolioInformation.footnote}
          className={className}
        />
      );
      break;
    }
    case 'ContentfulComponentPortfolioSet': {
      const portfolioSet: ContentfulComponentPortfolioSet = contentfulComponent;
      component = (
        <FundataPortfolioSet
          title={portfolioSet.title}
          subheading={portfolioSet.subheading?.subheading}
          button={portfolioSet.ctaButton}
          cymbriaLoginButton={portfolioSet.cymbriaLoginButton}
          series={portfolioSet.seriesSelector}
          portfolios={portfolioSet.portfolios?.map(
            (portfolio) => portfolio.content as ContentfulTopicPortfolio,
          )}
          variant={portfolioSet.variant}
          className={className}
        />
      );
      break;
    }
    case 'ContentfulComponentStatsSet': {
      const statsSet: ContentfulComponentStatsSet = contentfulComponent;
      component = (
        <StatsSet
          backgroundImage={statsSet.backgroundImage}
          button={statsSet.ctaButton}
          columns={statsSet.statsColumns}
          items={statsSet.stats}
          title={statsSet.title}
          subheading={statsSet.subheading?.subheading}
          variant={statsSet.variant}
          className={className}
        />
      );
      break;
    }
    case 'ContentfulComponentTeams': {
      const teams: ContentfulComponentTeams = contentfulComponent;
      component = (
        <Teams
          columns={teams.columns}
          showFilters={teams.showFilters}
          allFilterLabel={teams.allFilterLabel}
          subtitle={teams.otherTeamMembersTitle}
          teams={teams.teams}
          sortMembersBy={teams.sortMembersBy}
          className={className}
        />
      );
      break;
    }
    case 'ContentfulComponentTextBlock': {
      const textBlock: ContentfulComponentTextBlock = contentfulComponent;
      component = (
        <TextBlock
          title={textBlock.title}
          body={textBlock.body}
          buttons={textBlock.ctaButtons}
          variant={textBlock.variant}
          image={textBlock.image}
          className={className}
          isChildComponent={isChildComponent}
        />
      );
      break;
    }
    case 'ContentfulGlobalMicrocopy': {
      const microcopy: ContentfulGlobalMicrocopy = contentfulComponent;
      component = <Microcopy copy={microcopy.copy} />;
      break;
    }
    default: {
      component = (
        <Typography
          as="div"
          variant="body"
          className="p-s2 text-black bg-secondary"
        >
          Unknown component: {contentfulComponent?.__typename}
        </Typography>
      );
      break;
    }
  }

  return (
    <Appearance appearance={contentfulComponent.appearance}>
      {component}
    </Appearance>
  );
};

export default ComponentSelector;
