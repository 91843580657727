import React from 'react';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { ContentfulComposePage } from '../../graphql-types';
import { LinkOff } from '@material-ui/icons';

export interface ILink {
  page?: ContentfulComposePage;
  externalUrl?: string;
  openInNewWindow?: boolean;
  urlParameters?: string;
}
interface LinkProps {
  language?: string;
  link: ILink;
  [properties: string]: any;
}
/**
 * This component takes a nav item of type ContentfulNavItem and determines
 * whether it should use a LocalizedLink or plain <a> element as a link.
 */
const Link: React.FC<LinkProps> = (props) => {
  const { children, language, link, ...properties } = props;
  const { locale } = useLocalization();

  // normalize url parameters with ? at beginning if it exists
  const normalizedUrlParameters = link?.urlParameters
    ? link?.urlParameters?.startsWith('?')
      ? link?.urlParameters
      : `?${link?.urlParameters}`
    : '';

  let linkWrapper = <></>;
  if (link.externalUrl != null) {
    const targetProperties = {
      target: link.openInNewWindow === false ? '' : '_blank',
      rel: link.openInNewWindow === false ? '' : 'noopener noreferrer',
    };
    linkWrapper = (
      <a
        href={`${link.externalUrl}${normalizedUrlParameters}`}
        {...targetProperties}
        {...properties}
      >
        {children}
      </a>
    );
  } else if (link.page != null) {
    const normalizedFrontSlashSlug = link.page.slug?.startsWith('/')
      ? link.page.slug
      : `/${link.page.slug}`;

    const normalizedEndSlashSlug = normalizedFrontSlashSlug?.endsWith('/')
      ? normalizedFrontSlashSlug
      : `${normalizedFrontSlashSlug}/`;

    linkWrapper = (
      <LocalizedLink
        language={language || locale}
        to={`${normalizedEndSlashSlug}${normalizedUrlParameters}`}
        {...properties}
      >
        {children}
      </LocalizedLink>
    );
  } else {
    // broken link
    linkWrapper = (
      <span {...properties} title="This link is broken">
        {children} <LinkOff className="absolute text-red-500" />
      </span>
    );
  }

  return linkWrapper;
};
export default Link;
