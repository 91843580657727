import React from 'react';
import { Helmet } from 'react-helmet';
import { ContentfulComposeSeo } from '../../graphql-types';
interface SEOProps {
  data?: ContentfulComposeSeo;
  location?: Location;
}

const SEO: React.FC<SEOProps> = (props) => {
  const { data, location } = props;

  // add pathname class to body for conditional styling on certain pages
  const bodyClassName =
    location.pathname.replace(/\//g, '-').substring(1) || 'home';

  return (
    <Helmet
      title={data?.title || 'Edgepoint'}
      bodyAttributes={{
        class: bodyClassName,
      }}
    >
      <html lang="en" />
      {data?.no_index && <meta name="robots" content="noindex" />}
      {data?.no_follow && <meta name="robots" content="nofollow" />}
      <meta name="description" content={data?.description || ''} />
      <meta property="og:title" content={data?.title || ''} />
      <meta property="og:description" content={data?.description || ''} />
      {/* <meta property="og:type" content={type || ''} />
      <meta property="og:image" content={image || ''} /> */}
      <meta property="og:site_name" content={data?.title || ''} />

      {/* <meta property="twitter:card" content={card || ''} />
      <meta property="twitter:creator" content={card || ''} /> */}
    </Helmet>
  );
};

export default SEO;
