import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  MenuItem,
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  Select,
} from '@material-ui/core';
import { BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { Alert } from '@material-ui/lab';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import {
  ContentfulComponentDataVisualizationFootnote,
  ContentfulComponentDataVisualizationSubheadingTextNode,
  ContentfulTopicPortfolio,
  ContentfulTopicPortfolioSeries,
  ContentfulTopicTabularDataSet,
} from '../../graphql-types';
import Typography from './Typography';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import {
  CONTENTFUL_FUNDATA_SOURCE_OPTIONS,
  getFundataV2,
  sortAndGroupFundataResults,
  SERIES_KEYS,
} from '../services/fundata-v2.service';
import { useGlobalState } from '../hooks/useGlobalState';
import { getDateWithLocaleOffset } from '../services/date.service';
import { isLoggedIn, getCurrentSessionToken } from '../services/login.service';
import { getShares } from '../services/shares.service';
import {
  getColumnAlignment,
  getColumnStyles,
  getDefinitionsList,
  getDisplayValue,
  getHorizontalScrollIndicatorState,
  HorizontalScrollIndicator,
} from '../services/table.service';
import { getTranslation } from '../services/translation.service';
import {
  PerformanceCalendarNormalizedResponse,
  SeriesResponse,
} from '../services/fundata-v2.types';

const TableWrapper = styled.div`
  position: relative;
`;
const footnoteRichTextOptions: Options = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography
        as="small"
        variant="body"
        className="block mt-s2 text-xs leading-relaxed"
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography
        as="span"
        variant="body"
        className="block mt-s2 leading-relaxed"
      >
        {children}
      </Typography>
    ),
  },
};

interface DataVisualizationTableCalendarYearReturnsProps {
  className?: string;
  variant?: string;
  title?: string;
  subheading?: ContentfulComponentDataVisualizationSubheadingTextNode;
  footnote?: ContentfulComponentDataVisualizationFootnote;
  dataSet?: ContentfulTopicTabularDataSet;
  headlineOrientation?: 'stacked' | 'split';
  removeTSeriesFunds?: boolean;
  showPortfolioFilters?: boolean;
  overrideTableFontSize?: boolean;
}

const DataVisualizationTableCalendarYearReturns: React.FC<
  DataVisualizationTableCalendarYearReturnsProps
> = (props) => {
  const {
    title,
    subheading,
    dataSet,
    footnote,
    headlineOrientation,
    removeTSeriesFunds,
    showPortfolioFilters,
    overrideTableFontSize,
  } = props;
  const { locale } = useLocalization();
  const { portfolio, allPortfolioSeries } = useGlobalState();
  const {
    fontSize: { body },
  } = React.useContext(ThemeContext);

  // datasource state
  const fundataFor = dataSet?.funDataFor;
  const [portfolioSeries, setPortfolioSeries] = React.useState<
    ContentfulTopicPortfolioSeries[]
  >([]);

  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  // // shows a gradient when the table data overflows horizontally
  const ScrollableContainerRef = React.createRef<HTMLDivElement>();
  const [showHorizontalIndicator] = getHorizontalScrollIndicatorState(
    ScrollableContainerRef,
  );

  // table state
  const [tableMinHeight] = React.useState(dataSet?.settingMinHeight);
  const [tableMaxHeight] = React.useState(dataSet?.settingMaxHeight);
  const [tableFixedHeaders] = React.useState(dataSet?.settingFixedHeaders);
  const [tableColumnData] = React.useState(dataSet?.columns);
  const [allTableRowData, setAllTableRowData] =
    React.useState<SeriesResponse<PerformanceCalendarNormalizedResponse>[]>(
      null,
    );
  const [displayedTableRowData, setDisplayedTableRowData] =
    React.useState<SeriesResponse<PerformanceCalendarNormalizedResponse>[]>(
      null,
    );

  // definitions
  const definitions = dataSet.columns
    .concat(dataSet.rows?.flatMap((row) => row.values))
    .filter((item) => item?.additionalInfo)
    .map((cell) => cell?.additionalInfo?.copy);
  let definitionIndex = 1;

  // state for allPortfolios filters
  const [portfolioFilters, setPortfolioFilters] = React.useState<
    ContentfulTopicPortfolio[]
  >([]);
  const [selectedFilteredPortfolios, setSelectedFilteredPortfolios] =
    React.useState<string[]>([]);
  const handleSelectedFilteredPortfoliosChange = (event) => {
    setSelectedFilteredPortfolios(event.target.value);
  };
  const [seriesFilters, setSeriesFilters] = React.useState<string[]>([]);
  const [selectedFilteredSeries, setSelectedFilteredSeriesCategory] =
    React.useState<string[]>([]);
  const handleSelectedFilteredSeriesCategoryChange = (event) => {
    setSelectedFilteredSeriesCategory(event.target.value);
  };
  const [HSTFilters, setHSTFilters] = React.useState([]);
  const [selectedFilteredHST, setSelectedFilteredHST] = React.useState<
    string[]
  >([]);
  const handleSelectedFilteredHSTChange = (event) => {
    setSelectedFilteredHST(event.target.value);
  };

  const updateFilteredRowData = () => {
    let filteredData = allTableRowData;
    if (!filteredData) {
      // nothing to do
      return;
    }

    if (selectedFilteredPortfolios.length > 0) {
      filteredData = filteredData.filter(
        (series) =>
          selectedFilteredPortfolios.indexOf(
            series._portfolioSeries?.topic__portfolio?.[0]?.id,
          ) > -1,
      );
    }

    if (selectedFilteredSeries.length > 0) {
      filteredData = filteredData.filter((series) =>
        series._portfolioSeries.filterGroup?.some(
          (filter) => selectedFilteredSeries.indexOf(filter) > -1,
        ),
      );
    }

    if (selectedFilteredHST.length > 0) {
      const filterByHst = selectedFilteredHST.indexOf('hst') > -1;
      const filterByNonHst = selectedFilteredHST.indexOf('non-hst') > -1;
      filteredData = filteredData.filter(
        (series) =>
          (filterByHst && series._portfolioSeries?.nonHst !== true) ||
          (filterByNonHst && series._portfolioSeries?.nonHst === true),
      );
    }

    setDisplayedTableRowData(filteredData);
  };

  const getPortfolioSeriesData = async () => {
    if (!portfolioSeries || portfolioSeries.length === 0) {
      return; // do nothing
    }

    setIsError(false);
    setIsLoading(true);

    // parse and format dates to YYYY-MM-DD ISO string
    const now = getDateWithLocaleOffset();
    const dateYYYYMMDD = now.toISOString().substring(0, 10);

    // create, fetch and wait for all promises to finish
    const portfolioPromises = portfolioSeries.map(async (series) => {
      // when series is gates, use private shares endpoint
      if (series.isGated) {
        // if user is not logged in, skip series
        const isUserLoggedIn = await isLoggedIn();
        if (!isUserLoggedIn) {
          return Promise.resolve();
        }

        // use private shares endpoint to retrieve data
        const sessionToken = await getCurrentSessionToken();
        return await getShares(
          CONTENTFUL_FUNDATA_SOURCE_OPTIONS.CALENDAR_YEAR_RETURNS,
          sessionToken,
          dateYYYYMMDD,
        )
          .then((response) => {
            const tableRowData: SeriesResponse<PerformanceCalendarNormalizedResponse> =
              {
                ...(response as any),
                _portfolioSeries: series,
              };
            return tableRowData;
          })
          .catch((error) => {
            // swallow errors so outer promise isn't broken
            // eslint-disable-next-line no-console
            console.error('Shares error', error);
          });
      } else {
        return await getFundataV2(
          CONTENTFUL_FUNDATA_SOURCE_OPTIONS.CALENDAR_YEAR_RETURNS,
          locale,
          series.instrumentKey,
          dateYYYYMMDD,
        )
          .then((response) => {
            const tableRowData: SeriesResponse<PerformanceCalendarNormalizedResponse> =
              {
                ...(response as any),
                _portfolioSeries: series,
              };
            return tableRowData;
          })
          .catch((error) => {
            // swallow errors so outer promise isn't broken
            // eslint-disable-next-line no-console
            console.error('Fundata V2 error', error);
          });
      }
    });

    const results = await Promise.all(portfolioPromises)
      .then((promiseResponses) => {
        const sanitizedData = promiseResponses.filter(
          // filter out any errored promises
          (value) => value && value.Instrumentkey,
        ) as SeriesResponse<PerformanceCalendarNormalizedResponse>[];
        return sanitizedData;
      })
      .catch(() => {
        // no op
      });

    setIsLoading(false);
    if (!results || results.length === 0) {
      setIsError(true);
      return;
    }

    const sortedAndGroupedResults = sortAndGroupFundataResults(results);

    setAllTableRowData(sortedAndGroupedResults);
    setDisplayedTableRowData(sortedAndGroupedResults);
  };

  React.useEffect(() => {
    // determine the available options for filters from all the
    // series available
    const availablePortfolios = allPortfolioSeries
      .flatMap((series) => {
        return series.topic__portfolio;
      })
      ?.filter(
        // remove duplicates
        (portfolio, index, array) =>
          index ===
          array.findIndex((_portfolio) => _portfolio.id === portfolio.id),
      )
      ?.sort(function (a, b) {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    const availableSeriesFilters = allPortfolioSeries
      ?.flatMap((series) => {
        return series.filterGroup;
      })
      ?.filter(
        // remove empty items
        (seriesFilter) => seriesFilter != null,
      )
      ?.filter(
        // remove duplicates
        (seriesFilter, index, array) =>
          index === array.findIndex((sf) => sf === seriesFilter),
      )
      ?.sort(function (a, b) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
    const availableHST = [
      {
        id: 'hst',
        title: getTranslation('Hst', locale),
      },
      {
        id: 'non-hst',
        title: getTranslation('NonHst', locale),
      },
    ];

    setPortfolioFilters(availablePortfolios);
    setSeriesFilters(availableSeriesFilters);
    setHSTFilters(availableHST);
  }, [allPortfolioSeries]);

  React.useEffect(() => {
    // get portfolio series based on fundataFor - most often we are
    // using the series that are part of the portfolio, however, for pages
    // like the Performance page, we want to show every series associated
    // with the current website
    let seriesList: ContentfulTopicPortfolioSeries[] = [];
    if (fundataFor === 'individualPortfolio') {
      seriesList = portfolio?.series;
    } else if (fundataFor === 'allPortfolios') {
      seriesList = allPortfolioSeries;
    }

    // remove T Series if necessary
    if (removeTSeriesFunds === true) {
      seriesList = seriesList.filter((series) => {
        return !series.filterGroup.includes(SERIES_KEYS.SERIES_T);
      });
    }

    setPortfolioSeries(seriesList);
  }, [portfolio, allPortfolioSeries]);

  React.useEffect(() => {
    getPortfolioSeriesData();
  }, [portfolioSeries]);

  // updates the filtered data
  React.useEffect(() => {
    updateFilteredRowData();
  }, [selectedFilteredPortfolios, selectedFilteredSeries, selectedFilteredHST]);

  const tableFontSize = overrideTableFontSize ? body : '';

  return (
    <div className="container">
      <div className="lg:grid gap-s3 grid-cols-10">
        {(subheading || title) && (
          <div
            className={`${
              headlineOrientation === 'split'
                ? 'lg:col-span-4'
                : 'lg:col-span-12'
            }`}
          >
            <div className="flex justify-between items-start">
              <div>
                {title && (
                  <Typography as="div" variant="h1">
                    {title}
                  </Typography>
                )}
              </div>
            </div>
            {subheading && (
              <div>
                <Typography variant="body">{subheading.subheading}</Typography>
              </div>
            )}
          </div>
        )}

        {showPortfolioFilters && (
          <div
            className={`mb-s4 flex flex-col md:grid md:grid-cols-4 md:gap-x-s1 ${
              headlineOrientation === 'split'
                ? 'lg:col-span-6'
                : 'lg:col-span-12 lg:w-full'
            }`}
          >
            {portfolioFilters && portfolioFilters.length && (
              <FormControl className="mt-s2 md:mt-0">
                <InputLabel id="filtered-portfolios-checkbox-label">
                  {getTranslation('FilterBy', locale)}{' '}
                  {getTranslation('Portfolio', locale)}
                </InputLabel>
                <Select
                  labelId="filtered-portfolios-checkbox-label"
                  id="filtered-portfolios-checkbox"
                  multiple
                  value={selectedFilteredPortfolios}
                  onChange={handleSelectedFilteredPortfoliosChange}
                  renderValue={(selected: string[]) => {
                    return portfolioFilters
                      ?.filter((filter) => selected?.indexOf(filter.id) > -1)
                      ?.map((filter) => filter.title)
                      ?.join(', ');
                  }}
                  input={<Input />}
                >
                  {portfolioFilters?.map((filter) => (
                    <MenuItem key={filter.id} value={filter.id}>
                      <Checkbox
                        checked={
                          selectedFilteredPortfolios.indexOf(filter.id) > -1
                        }
                      />
                      <ListItemText primary={filter.title} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {seriesFilters && seriesFilters.length > 0 && (
              <FormControl className="mt-s2 md:mt-0">
                <InputLabel id="filtered-series-category-checkbox-label">
                  {getTranslation('FilterBy', locale)}{' '}
                  {getTranslation('Series', locale)}
                </InputLabel>
                <Select
                  labelId="filtered-series-category-checkbox-label"
                  id="filtered-series-category-checkbox"
                  multiple
                  value={selectedFilteredSeries}
                  onChange={handleSelectedFilteredSeriesCategoryChange}
                  renderValue={(selected: string[]) => {
                    return seriesFilters
                      ?.filter((filter) => selected?.indexOf(filter) > -1)
                      ?.join(', ');
                  }}
                  input={<Input />}
                >
                  {seriesFilters?.map((filter) => (
                    <MenuItem key={filter} value={filter}>
                      <Checkbox
                        checked={selectedFilteredSeries.indexOf(filter) > -1}
                      />
                      <ListItemText
                        primary={filter.replace(
                          'Series',
                          getTranslation('Series', locale),
                        )}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl className="mt-s2 md:mt-0">
              <InputLabel id="filtered-hst-checkbox-label">
                {getTranslation('FilterBy', locale)}{' '}
                {getTranslation('Hst', locale)} /{' '}
                {getTranslation('NonHst', locale)}
              </InputLabel>
              <Select
                labelId="filtered-hst-checkbox-label"
                id="filtered-hst-checkbox"
                multiple
                value={selectedFilteredHST}
                onChange={handleSelectedFilteredHSTChange}
                renderValue={(selected: string[]) => {
                  return HSTFilters?.filter(
                    (filter) => selected?.indexOf(filter.id) > -1,
                  )
                    ?.map((filter) => filter.title)
                    ?.join(', ');
                }}
                input={<Input />}
              >
                {HSTFilters?.map((filter) => (
                  <MenuItem key={filter.id} value={filter.id}>
                    <Checkbox
                      checked={selectedFilteredHST.indexOf(filter.id) > -1}
                    />
                    <ListItemText primary={filter.title} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}

        <div
          className={
            headlineOrientation === 'split'
              ? 'lg:col-span-6'
              : 'lg:col-span-12 lg:w-full'
          }
        >
          {!isLoading && !isError && (
            <TableWrapper>
              {showHorizontalIndicator && <HorizontalScrollIndicator />}

              <TableContainer
                ref={ScrollableContainerRef}
                style={{
                  minHeight: tableMinHeight ? `${tableMinHeight}px` : 'auto',
                  maxHeight: tableMaxHeight ? `${tableMaxHeight}px` : 'auto',
                }}
                component="div"
              >
                <Table
                  stickyHeader={tableFixedHeaders ? true : false}
                  aria-label={title}
                >
                  <TableHead>
                    <TableRow>
                      {tableColumnData?.map((column, index) => {
                        const columnStyle = getColumnStyles(
                          column,
                          tableFontSize,
                        );
                        const columnAlign = getColumnAlignment(column);
                        return (
                          <TableCell
                            style={columnStyle}
                            align={columnAlign}
                            key={`column-${column.id}-${index}`}
                          >
                            {column.additionalInfo && (
                              <sup>{definitionIndex++}</sup>
                            )}
                            {column.title}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {displayedTableRowData &&
                      displayedTableRowData.length > 0 &&
                      displayedTableRowData.map((row, rowIndex) => {
                        return (
                          row != null && (
                            <TableRow key={`row-${row.id}-${rowIndex}`}>
                              {tableColumnData?.map((column, columnIndex) => {
                                const columnStyle = getColumnStyles(
                                  column,
                                  tableFontSize,
                                );
                                const columnAlign = getColumnAlignment(column);

                                let tableCellValue: string | JSX.Element = '-';
                                const value = row[column.key];
                                const type = column.type;
                                if (columnIndex === 0) {
                                  const slug =
                                    row?._portfolioSeries?.topic__portfolio?.[0]
                                      ?.compose__page?.[0].slug;
                                  const seriesTitle =
                                    row._portfolioSeries?.title;
                                  tableCellValue = slug ? (
                                    // link will always be internal
                                    <LocalizedLink
                                      language={locale}
                                      to={`/${slug}`}
                                      className="underline"
                                    >
                                      {seriesTitle}
                                    </LocalizedLink>
                                  ) : (
                                    seriesTitle
                                  );
                                } else if (value != null) {
                                  tableCellValue = getDisplayValue(
                                    value as unknown as string,
                                    type,
                                    locale,
                                    {
                                      decimalPlaces:
                                        column.settingDecimalPlaces,
                                    },
                                  );
                                }

                                return (
                                  <TableCell
                                    style={columnStyle}
                                    align={columnAlign}
                                    key={`row-${rowIndex}-cell-${columnIndex}`}
                                  >
                                    {tableCellValue}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          )
                        );
                      })}
                  </TableBody>
                </Table>

                {displayedTableRowData &&
                  displayedTableRowData.length === 0 && (
                    <Typography as="div" variant="body" className="my-s2">
                      <em>No results</em>
                    </Typography>
                  )}
              </TableContainer>
            </TableWrapper>
          )}

          {footnote && (
            <Typography
              as="small"
              variant="body"
              className="block mt-s2 text-xs leading-relaxed"
            >
              {renderContentfulRichText(
                footnote as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                footnoteRichTextOptions,
              )}
            </Typography>
          )}

          {isLoading && (
            <div className="container pt-l1 flex justify-center">
              <CircularProgress aria-label="Loading data" />
            </div>
          )}

          {isError && (
            <Alert severity="info" className="mt-s3">
              <strong>Sorry!</strong> Data temporarily unavailable, but we
              {`'`}re working to get it back soon.
            </Alert>
          )}
        </div>
      </div>

      {getDefinitionsList(definitions, locale)}
    </div>
  );
};

export default DataVisualizationTableCalendarYearReturns;
