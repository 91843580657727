import React from 'react';

import { Switch } from '@material-ui/core';
import {
  ContentfulComponentCookie,
  ContentfulComponentCtaButton,
} from '../../graphql-types';
import Button from './Button';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import { useGlobalState } from '../hooks/useGlobalState';
import { WEBSITE } from '../types/website.enum';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';

interface CookiePreferencesProps {
  id: string;
  title: string;
  description: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  manageConsentPreferencesTitle: string;
  cookieDuration: number;
  cookies: ContentfulComponentCookie[];
  acceptAllCookiesButton: ContentfulComponentCtaButton;
  rejectAllCookiesButton: ContentfulComponentCtaButton;
  manageCookiePreferencesButton: ContentfulComponentCtaButton;
  acceptChoosenPreferencesButton: ContentfulComponentCtaButton;
  location: Location;
}

const CookiePreferences: React.FC<CookiePreferencesProps> = (props) => {
  const {
    id,
    title,
    description,
    manageConsentPreferencesTitle,
    cookies,
    cookieDuration,
    acceptAllCookiesButton,
    rejectAllCookiesButton,
    manageCookiePreferencesButton,
    acceptChoosenPreferencesButton,
    location,
  } = props;

  const { site } = useGlobalState();
  const cookiePrefix = site === WEBSITE.EDGEPOINT ? 'epw' : 'cym';
  const cookieKeyName = `${cookiePrefix}-cookie-preferences-${id}`;

  //google tag manager cookie name
  const gtmCookieName = `${cookiePrefix}-gatsby-consent-google-tagmanager`;
  const analyticsCookieName = `${cookiePrefix}-gatsby-consent-google-analytics`;
  const marketingCookieName = `${cookiePrefix}-gatsby-consent-marketing`;
  const performanceCookieName = `${cookiePrefix}-gatsby-consent-performance`;

  // modal state and actions
  const [open, setOpen] = React.useState(false);
  const [openManagePreferences, setOpenManagePreferences] =
    React.useState(false);

  // essential cookies don't need to be managed, always true
  const [acceptAnalyticsCookies, setAcceptAnalyticsCookies] =
    React.useState(false);
  const [acceptMarketingCookies, setAcceptMarketingCookies] =
    React.useState(false);
  const [acceptPerformanceCookies, setAcceptPerformanceCookies] =
    React.useState(false);

  // cookie variables
  const cookiePath = 'Path=/;';

  // Set cookie function
  const setCookie = (
    cookieName: string,
    value: string,
    maxAgeInDays: number,
    isSessionCookie: boolean,
  ) => {
    const maxAgeInSeconds = maxAgeInDays * 24 * 60 * 60; // Convert days to seconds
    const expires = new Date();
    expires.setTime(expires.getTime() + maxAgeInSeconds * 1000); // Convert seconds to milliseconds
    document.cookie = `${cookieName}=${value}; max-age=${maxAgeInSeconds}; expires=${
      isSessionCookie ? '0' : expires.toUTCString()
    }; ${cookiePath}`;
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleClose = (event?: object, reason?: string) => {
    if (reason === 'backdropClick') {
      // do not close on backdrop click
      return;
    }
    setOpen(false);
  };

  const handleSavePreferences = () => {
    const cookieTypeValues = `analytics:${acceptAnalyticsCookies}&marketing:${acceptMarketingCookies}&performance:${acceptPerformanceCookies}`;
    setCookie(cookieKeyName, cookieTypeValues, cookieDuration, false);
    handleClose();
  };

  /**
   * Handler for when user chooses to Accept all cookie terms. If the checkbox for
   * remembering their decision is checked, it will store a cookie to prevent
   * this modal from auto-matically opening in the future.
   */
  const handleAcceptAll = () => {
    setAcceptAnalyticsCookies(true);
    setAcceptMarketingCookies(true);
    setAcceptPerformanceCookies(true);
    // enable GTM container
    setCookie(gtmCookieName, `true`, cookieDuration, false);
    setCookie(analyticsCookieName, `true`, cookieDuration, false);
    setCookie(marketingCookieName, `true`, cookieDuration, false);
    setCookie(performanceCookieName, `true`, cookieDuration, false);

    const cookieTypeValues = `analytics:true&marketing:true&performance:true`;
    setCookie(cookieKeyName, cookieTypeValues, cookieDuration, false);
    handleClose();
  };

  /**
   * Handler for when user chooses to close the banner without selecting an option. This will set any
   * non-essentialcookie values to false
   */
  const handleRejectAll = () => {
    // expire cookie if user declines and close cookie banner
    setAcceptAnalyticsCookies(false);
    setAcceptMarketingCookies(false);
    setAcceptPerformanceCookies(false);

    setCookie(analyticsCookieName, `false`, cookieDuration, false);
    setCookie(marketingCookieName, `false`, cookieDuration, false);
    setCookie(performanceCookieName, `false`, cookieDuration, false);

    const cookieTypeValues = `analytics:false&marketing:false&performance:false`;
    setCookie(cookieKeyName, cookieTypeValues, cookieDuration, false);

    //disable GTM container
    handleClose();
  };

  /**
   * Handler for when user chooses to manage their cookie preferences.This will toggle the manage cookie preferences section
   */
  const handleManagePreferences = () => {
    setOpenManagePreferences(!openManagePreferences);
  };

  /**
   * Handler for when a user toggles cookie preference
   * @param event
   */
  const handlePreferencesChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const cookieTypeName = event.target.name;

    switch (cookieTypeName) {
      case 'analytics':
        setAcceptAnalyticsCookies(event.target.checked);

        setCookie(
          analyticsCookieName,
          `${event.target.checked ? 'true' : 'false'}`,
          cookieDuration,
          false,
        );

        break;
      case 'marketing':
        setAcceptMarketingCookies(event.target.checked);

        setCookie(
          marketingCookieName,
          `${event.target.checked ? 'true' : 'false'}`,
          cookieDuration,
          false,
        );

        break;
      case 'performance':
        setAcceptPerformanceCookies(event.target.checked);

        setCookie(
          performanceCookieName,
          `${event.target.checked ? 'true' : 'false'}`,
          cookieDuration,
          false,
        );

        break;
      default:
        setAcceptAnalyticsCookies(false);
        setAcceptMarketingCookies(false);
        setAcceptPerformanceCookies(false);
        break;
    }
  };

  React.useEffect(() => {
    // get cookie and cookie preferences if they have not accepted the agreement
    const cookieValue = document.cookie
      ?.split('; ')
      ?.find((row) => row.startsWith(`${cookieKeyName}=`))
      ?.split('=')[1];

    if (cookieValue) {
      const keyValuePairs = cookieValue.split('&');

      const cookieData: any = {};

      for (let i = 0; i < keyValuePairs.length; i++) {
        const keyValue = keyValuePairs[i].split(':');
        const key = decodeURIComponent(keyValue[0]);
        const value = decodeURIComponent(keyValue[1]);
        cookieData[key] = value;
      }

      // Step 3: Access individual values and convert to booleans
      const analytics = cookieData.analytics === 'true';
      const marketing = cookieData.marketing === 'true';
      const performance = cookieData.performance === 'true';

      setAcceptAnalyticsCookies(analytics);
      setAcceptMarketingCookies(marketing);
      setAcceptPerformanceCookies(performance);
    } else {
      cookies?.map((cookie) => {
        if (cookie?.category === 'analytics') {
          setAcceptAnalyticsCookies(cookie?.isEnabledByDefault ? true : false);
          setCookie(
            analyticsCookieName,
            `${cookie?.isEnabledByDefault ? 'true' : 'false'}`,
            cookieDuration,
            false,
          );
        }

        if (cookie?.category === 'marketing') {
          setAcceptMarketingCookies(cookie?.isEnabledByDefault ? true : false);
          setCookie(
            marketingCookieName,
            `${cookie?.isEnabledByDefault ? 'true' : 'false'}`,
            cookieDuration,
            false,
          );
        }

        if (cookie?.category === 'performance') {
          setAcceptPerformanceCookies(
            cookie?.isEnabledByDefault ? true : false,
          );
          setCookie(
            performanceCookieName,
            `${cookie?.isEnabledByDefault ? 'true' : 'false'}`,
            cookieDuration,
            false,
          );
        }
      });
    }

    // get search params
    const urlParams = new URLSearchParams(location.search);
    const manageCookies = urlParams.get('manageCookies');

    // enable GTM container
    setCookie(gtmCookieName, `true`, cookieDuration, false);

    if (!cookieValue || cookieValue === 'false' || manageCookies === 'true') {
      setOpen(true);
    }
  }, []);

  return (
    <section
      className={`fixed max-w-md p-4 mx-auto bg-white border border-gray-200 dark:bg-gray-800 left-2 bottom-2 right-2 md:left-6 md:bottom-6 md:right-auto dark:border-gray-700 shadow-md z-50 transition-all duration-500 ${
        open ? 'block' : 'hidden'
      }`}
    >
      {title && (
        <h2 className="font-semibold text-gray-800 dark:text-white text-base">
          {title}
        </h2>
      )}

      {description && (
        <div className="mt-4 mb-6 [&>p]:text-sm text-gray-600 dark:text-gray-300">
          {renderContentfulRichText(description)}
        </div>
      )}

      <div
        className={`manage-consent-preferences transition-all duration-500 ease-in-out overflow-hidden ${
          openManagePreferences ? 'max-h-96 mb-4' : 'max-h-0 mb-0'
        }`}
      >
        {manageConsentPreferencesTitle && (
          <h2 className="font-semibold text-gray-800 dark:text-white text-base">
            {manageConsentPreferencesTitle}
          </h2>
        )}

        {cookies &&
          cookies?.map((cookie) => {
            return (
              <div
                className={`cookie-type flex justify-between w-full ${
                  cookie?.category === 'essential' ? `my-2` : `my-4`
                } gap-2`}
                key={cookie.id}
              >
                <div className="cookie-description w-4/5">
                  {cookie?.title && <h3 className="text-sm">{cookie.title}</h3>}

                  {cookie?.description?.description && (
                    <p className="text-sm text-gray-500">
                      {cookie?.description?.description}
                    </p>
                  )}
                </div>
                <div className="flex items-start w-1/5">
                  {cookie?.category === 'essential' ? (
                    <Switch
                      aria-label={`${cookie?.category}} cookies switch - always enabled`}
                      disabled
                      defaultChecked
                      className="[&>.MuiSwitch-colorPrimary.Mui-disabled]:text-amber-600"
                      name={cookie?.category}
                    />
                  ) : (
                    <Switch
                      aria-label={`${cookie?.category}} cookies switch`}
                      disabled={cookie?.canBeToggled ? false : true}
                      checked={acceptAnalyticsCookies}
                      onChange={handlePreferencesChange}
                      name={cookie?.category}
                    />
                  )}
                </div>
              </div>
            );
          })}
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4 shrink-0 [&>button]:py-1">
        {acceptAllCookiesButton && (
          <Button
            variant={acceptAllCookiesButton.variant}
            className="py-1 accept-all-cookies-button"
            onClick={handleAcceptAll}
          >
            {acceptAllCookiesButton.text}
          </Button>
        )}

        {acceptChoosenPreferencesButton && (
          <Button
            variant={acceptChoosenPreferencesButton.variant}
            onClick={handleSavePreferences}
            className="py-1 accept-choosen-cookies-button"
          >
            {acceptChoosenPreferencesButton.text}
          </Button>
        )}

        {manageCookiePreferencesButton && (
          <Button
            variant={manageCookiePreferencesButton.variant}
            onClick={handleManagePreferences}
          >
            {manageCookiePreferencesButton.text}
          </Button>
        )}

        {rejectAllCookiesButton && (
          <Button
            variant={rejectAllCookiesButton.variant}
            className="py-1 reject-all-cookies-button"
            onClick={handleRejectAll}
          >
            {rejectAllCookiesButton.text}
          </Button>
        )}
      </div>
    </section>
  );
};

export default CookiePreferences;
