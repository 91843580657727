import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import {
  ContentfulAsset,
  ContentfulComponentCtaButton,
  ContentfulComponentMediaCopyFootnote,
  ContentfulTopicCategory,
} from '../../graphql-types';
import { useGlobalState } from '../hooks/useGlobalState';
import { WEBSITE } from '../types/website.enum';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import Button from './Button';
import ContentfulImage from './ContentfulImage';
import Typography from './Typography';

const StyledReactPlayer = styled(ReactPlayer)`
  padding-top: 56.25%; // Percentage ratio for 16:9
  position: relative; // Set to relative
  & > div {
    position: absolute; // Scaling will occur since parent is relative now
    top: 0;
    left: 0;
  }
`;

export type ImageFocalPointOptions =
  | 'top left'
  | 'top center'
  | 'top right'
  | 'center left'
  | 'center center'
  | 'center right'
  | 'bottom left'
  | 'bottom center'
  | 'bottom right';

interface MediaCopyProps {
  className?: string;
  title?: string;
  eyebrow?: ContentfulTopicCategory;
  subheading?: string;
  buttons?: ContentfulComponentCtaButton[];
  media?: ContentfulAsset;
  imageFocalPoint?: string;
  mediaPosition?: string;
  variant?: string;
  videoUrl?: string;
  darkMode?: boolean;
  footnote?: ContentfulComponentMediaCopyFootnote;
  isChildComponent?: boolean;
}

const MediaCopy: React.FC<MediaCopyProps> = (props) => {
  const {
    className,
    title,
    eyebrow,
    subheading,
    buttons,
    media,
    mediaPosition = 'right',
    imageFocalPoint,
    variant = 'standard',
    videoUrl,
    darkMode,
    footnote,
    isChildComponent,
  } = props;

  const footnoteRichTextOptions: Options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography as="p" variant="footerBody3">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography as="p" variant="body">
          {children}
        </Typography>
      ),
    },
  };
  const { site } = useGlobalState();

  let mediaElement = <></>;
  if (media) {
    mediaElement = (
      <ContentfulImage
        image={media}
        alt={media.description || ''}
        style={{
          display: 'block',
          maxWidth: '100%',
          height: '100%',
        }}
        objectPosition={imageFocalPoint ? imageFocalPoint : 'center center'}
      />
    );
  } else {
    mediaElement = (
      <StyledReactPlayer
        url={videoUrl}
        className="react-player"
        width="100%"
        height="100%"
      />
    );
  }

  let mediaPositionElement = <></>;
  if (mediaPosition === 'bottom') {
    mediaPositionElement = (
      <div className="md:container md:grid md:grid-cols-10">
        {(title || subheading || buttons?.length) && (
          <div className="container mt-m2 md:mt-m4 md:col-span-8 md:col-start-2">
            {title && (
              <Typography as="h2" variant="h1" className="text-center">
                {title}
              </Typography>
            )}
            {subheading && (
              <Typography as="p" variant="body" className="mt-s2 text-center">
                {subheading}
              </Typography>
            )}
            {buttons?.length > 0 && (
              <ul className="flex justify-center mt-s2">
                {buttons?.map((button) => (
                  <li
                    key={`button-list-item-${button?.id}`}
                    className="ml-s3 first:ml-0 flex items-center"
                  >
                    <Button
                      key={button?.id}
                      variant={button?.variant}
                      link={button?.page?.slug || button?.externalUrl}
                      openInNewWindow={button?.openInNewWindow}
                    >
                      {button?.text}
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        <div
          className={`flex justify-center ${
            isChildComponent ? 'mt-s3' : 'mt-m3 pb-m2 md:pb-m4'
          } md:col-span-8 md:col-start-2`}
        >
          {mediaElement}
        </div>
        {footnote && (
          <Typography
            variant="footerBody3"
            className="container flex mt-1 md:col-span-10 md:col-start-1"
          >
            {renderContentfulRichText(
              footnote as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
              footnoteRichTextOptions,
            )}
          </Typography>
        )}
      </div>
    );
  } else if (mediaPosition === 'left' || mediaPosition === 'right') {
    mediaPositionElement = (
      <div
        className={`relative flex items-center min-h-[150px] ${
          variant === 'hero-banner' ? 'lg:min-h-[650px]' : ''
        }`}
      >
        <div
          className={`relative z-10 w-full p-s3 m-s3 bg-white  md:bg-transparent md:mx-auto md:grid md:grid-cols-10 md:gap-x-s3 md:container ${
            variant === 'hero-banner' ? 'wide' : ''
          } ${
            site === WEBSITE.EDGEPOINT
              ? 'border border-secondary md:border-0'
              : ''
          } ${site === WEBSITE.CYMBRIA ? 'clip-corner' : ''}`}
        >
          <div
            className={`md:col-span-4 ${
              mediaPosition === 'left' ? 'md:col-start-7' : ''
            }`}
          >
            {eyebrow && (
              <Typography
                as="div"
                variant="tag"
                className={`mb-s2 ${
                  site === WEBSITE.EDGEPOINT ? 'text-center md:text-left' : ''
                }`}
              >
                {eyebrow.title}
              </Typography>
            )}
            {title && (
              <Typography
                as="h2"
                variant="h1"
                className={`${
                  site === WEBSITE.EDGEPOINT ? 'text-center md:text-left' : ''
                }`}
              >
                {title}
              </Typography>
            )}
            {subheading && (
              <Typography
                as="p"
                variant="body"
                className={`mt-s2 ${
                  site === WEBSITE.EDGEPOINT ? 'text-center md:text-left' : ''
                }`}
              >
                {subheading}
              </Typography>
            )}
            {buttons?.length > 0 && (
              <ul
                className={`flex mt-s3 ${
                  site === WEBSITE.EDGEPOINT
                    ? 'justify-center md:justify-start'
                    : ''
                }`}
              >
                {buttons?.map((button) => (
                  <li
                    key={`button-list-item-${button?.id}`}
                    className="ml-s3 first:ml-0 flex items-center"
                  >
                    <Button
                      key={button?.id}
                      variant={button?.variant}
                      link={button?.page?.slug || button?.externalUrl}
                      openInNewWindow={button?.openInNewWindow}
                    >
                      {button?.text}
                    </Button>
                  </li>
                ))}
              </ul>
            )}
            {footnote && (
              <Typography variant="footerBody3" className="flex mt-s2">
                {renderContentfulRichText(
                  footnote as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                  footnoteRichTextOptions,
                )}
              </Typography>
            )}
          </div>
        </div>
        <div
          className={`absolute inset-0 ${
            mediaPosition === 'left' ? 'md:right-1/2' : 'md:left-1/2'
          } ${variant === 'hero-banner' ? 'w-7/12 ' : ''}`}
        >
          {mediaElement}
        </div>
      </div>
    );
  } else if (mediaPosition === 'top' || !mediaPosition) {
    mediaPositionElement = (
      <div className="md:container md:grid md:grid-cols-10">
        {(title || subheading || buttons?.length) && (
          <div className="container mt-m2 md:mt-m4 md:col-span-8 md:col-start-2">
            {title && (
              <Typography as="h2" variant="h1" className="text-center">
                {title}
              </Typography>
            )}
            {subheading && (
              <Typography as="p" variant="body" className="mt-s2 text-center">
                {subheading}
              </Typography>
            )}
            {buttons?.length > 0 && (
              <ul className="flex justify-center mt-s2">
                {buttons?.map((button) => (
                  <li
                    key={`button-list-item-${button?.id}`}
                    className="ml-s3 first:ml-0 flex items-center"
                  >
                    <Button
                      key={button?.id}
                      variant={button?.variant}
                      link={button?.page?.slug || button?.externalUrl}
                      openInNewWindow={button?.openInNewWindow}
                    >
                      {button?.text}
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        <div className="container flex justify-center mt-m2 pb-m2 md:col-span-10 md:col-start-1">
          {mediaElement}
        </div>
        {footnote && (
          <Typography
            variant="footerBody3"
            className="container flex mt-1 md:col-span-10 md:col-start-1"
          >
            {renderContentfulRichText(
              footnote as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
              footnoteRichTextOptions,
            )}
          </Typography>
        )}
      </div>
    );
  }

  return (
    <div className={`${darkMode ? 'bg-greyLight' : ''} ${className || ''}`}>
      {mediaPositionElement}
    </div>
  );
};

export default MediaCopy;
