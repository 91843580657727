import { ContentfulTopicPortfolioSeries } from '../../graphql-types';
import { checkStatusCode } from '../utils/checkStatusCode';
import { getDateWithLocaleOffset } from './date.service';
import {
  DistributionHistoryResponse,
  DistributionResponse,
  FundataGenericResponseFields,
  SeriesResponse,
} from './fundata-v2.types';

if (!process.env.GATSBY_AWS_API_URL) {
  // eslint-disable-next-line no-console
  console.error('API was not set.');
}
const AWS_API_URL = process.env.GATSBY_AWS_API_URL;

/** Fundata V2 */
const FUNDATA_V2_ENDPOINT = '/fundata/v2';
const FUNDATA_V2_API_URL = `${AWS_API_URL}${FUNDATA_V2_ENDPOINT}`;
const FUNDATA_V2_CACHE = new Map<string, Promise<unknown>>();

export const SERIES_KEYS = {
  SERIES_A: 'Series A',
  SERIES_F: 'Series F',
  SERIES_T: 'Series T',
};

export const HST_KEYS = {
  NON_HST: 'Non-HST',
  HST: 'HST',
};

export interface FundataPortfolioSeriesUnion
  extends FundataGenericResponseFields {
  _portfolioSeries: ContentfulTopicPortfolioSeries;
}

export const getCacheKey = (
  endpoint: string,
  locale = 'en',
  instrumentKey?: string,
  dateYYYYMMDD?: string,
  fromDateYYYYMMDD?: string,
  toDateYYYYMMDD?: string,
): string => {
  const cacheKey = `${endpoint}-${locale}${
    instrumentKey ? `-instrument-${instrumentKey}` : ''
  }${dateYYYYMMDD ? `-date-${dateYYYYMMDD}` : ''}${
    fromDateYYYYMMDD ? `-from-${fromDateYYYYMMDD}` : ''
  }${toDateYYYYMMDD ? `-to-${toDateYYYYMMDD}` : ''}`;
  return cacheKey;
};

export interface BulkRequestGenericResponse {
  Instrumentkey: number;
}

export const CONTENTFUL_FUNDATA_SOURCE_OPTIONS = {
  PRICING: 'Pricing',
  PRICING_HISTORY: 'Daily Pricing History',
  INVESTMENT_RESULTS: 'Investment Results',
  CALENDAR_YEAR_RETURNS: 'Calendar Year Returns',
  DISTRIBUTIONS: 'Distributions',
};

export const FundataV2ContentfulSourceApiEndpointMap = {
  Pricing: 'pricing',
  'Daily Pricing History': 'pricingHistory',
  'Investment Results': 'performance',
  'Calendar Year Returns': 'calendar',
  Distributions: 'distributionHistory',
};

const getBulkResponse = async (
  endpoint: string,
  locale = 'en',
  dateYYYYMMDD: string,
): Promise<unknown> => {
  // check if this bulk request has already been cached
  const bulkCacheKey = getCacheKey(endpoint, locale, null, dateYYYYMMDD);
  const bulkCachedPromise = FUNDATA_V2_CACHE.get(bulkCacheKey);
  if (bulkCachedPromise) {
    return await bulkCachedPromise;
  }

  const apiUrl = new URL(FUNDATA_V2_API_URL);
  apiUrl.searchParams.append('endpoint', endpoint);
  apiUrl.searchParams.append('locale', locale);
  // only pricing endpoint has bulk date option
  if (
    endpoint === 'pricing' ||
    endpoint === 'distribution' ||
    endpoint === 'performance'
  ) {
    apiUrl.searchParams.append('date', dateYYYYMMDD);
  }
  const bulkPromise = fetch(apiUrl.href)
    .then((response) =>
      checkStatusCode<{ success: boolean; data: BulkRequestGenericResponse[] }>(
        response,
      ),
    )
    .then((response) => {
      // cache individual portfolios
      response.data.forEach((series) => {
        const portfolioCacheKey = getCacheKey(
          endpoint,
          locale,
          series.Instrumentkey.toString(),
          dateYYYYMMDD,
        );

        const seriesPromise = Promise.resolve(series);
        FUNDATA_V2_CACHE.set(portfolioCacheKey, seriesPromise);
      });
      return response.data;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.warn(
        `Error fetching bulk Fundata api: ${endpoint}, ${locale}, ${dateYYYYMMDD}`,
      );
      return Promise.reject(error);
    });

  // cache and return promise
  FUNDATA_V2_CACHE.set(bulkCacheKey, bulkPromise);
  return bulkPromise;
};

/**
 * This function will attempt to fetch Fundata in the most economical way it can
 * for each endpoint. Since some endpoints can return bulk data, when this function
 * sees a call for an individual portfolio it will determine whether it can fetch
 * the bulk data which will contain that portfolio to save massive amounts of requests.
 *
 * @param endpoint String representing a Fundata endpoint
 * @param locale Locale of data to fetch
 * @param instrumentKey Portfolio series instrument key
 * @param dateYYYYMMDD Date in YYYY-MM-DD format
 * @param fromDateYYYYMMDD Date in YYYY-MM-DD format
 * @param toDateYYYYMMDD Date in YYYY-MM-DD format
 * @returns Promise with relevant Fundata result
 */
export const getFundataV2 = async <T>(
  endpoint: string,
  locale = 'en',
  instrumentKey?: string,
  dateYYYYMMDD?: string,
  fromDateYYYYMMDD?: string,
  toDateYYYYMMDD?: string,
): Promise<T> => {
  const funDataApiEndpoint = FundataV2ContentfulSourceApiEndpointMap[endpoint];

  // check and return cache if it exists
  const cacheKey = getCacheKey(
    funDataApiEndpoint,
    locale,
    instrumentKey,
    dateYYYYMMDD,
    fromDateYYYYMMDD,
    toDateYYYYMMDD,
  );
  const cachedResult = FUNDATA_V2_CACHE.get(cacheKey);
  if (cachedResult) {
    return cachedResult as Promise<T>;
  }

  // for some endpoints we want to avoid fetching individual portfolios,
  // as the base endpoint will return much more data faster than dozens of
  // individual requests
  switch (endpoint) {
    case CONTENTFUL_FUNDATA_SOURCE_OPTIONS.PRICING: {
      await getBulkResponse(funDataApiEndpoint, locale, dateYYYYMMDD);
      // check again now that bulk request has been made
      const cachedResultAlt = FUNDATA_V2_CACHE.get(cacheKey);
      if (cachedResultAlt) {
        return cachedResultAlt as Promise<T>;
      }

      break;
    }
    case CONTENTFUL_FUNDATA_SOURCE_OPTIONS.INVESTMENT_RESULTS:
    case CONTENTFUL_FUNDATA_SOURCE_OPTIONS.CALENDAR_YEAR_RETURNS:
    case CONTENTFUL_FUNDATA_SOURCE_OPTIONS.DISTRIBUTIONS: {
      await getBulkResponse(funDataApiEndpoint, locale, dateYYYYMMDD);
      // check again now that bulk request has been made
      const cachedResultAlt = FUNDATA_V2_CACHE.get(cacheKey);
      if (cachedResultAlt) {
        return cachedResultAlt as Promise<T>;
      }
      break;
    }
    case CONTENTFUL_FUNDATA_SOURCE_OPTIONS.PRICING_HISTORY: {
      return fetchFundata(
        funDataApiEndpoint,
        locale,
        instrumentKey,
        dateYYYYMMDD,
        fromDateYYYYMMDD,
        toDateYYYYMMDD,
      ) as Promise<T>;
    }
  }
};

const fetchFundata = async (
  endpoint: string,
  locale = 'en',
  instrumentKey?: string,
  dateYYYYMMDD?: string,
  fromDateYYYYMMDD?: string,
  toDateYYYYMMDD?: string,
) => {
  const funDataApiUrl = new URL(FUNDATA_V2_API_URL);
  funDataApiUrl.searchParams.append('endpoint', endpoint);
  if (instrumentKey)
    funDataApiUrl.searchParams.append('instrumentKey', instrumentKey);
  if (locale) funDataApiUrl.searchParams.append('locale', locale);
  if (dateYYYYMMDD) funDataApiUrl.searchParams.append('date', dateYYYYMMDD);
  if (fromDateYYYYMMDD)
    funDataApiUrl.searchParams.append('fromDate', fromDateYYYYMMDD);
  if (toDateYYYYMMDD)
    funDataApiUrl.searchParams.append('toDate', toDateYYYYMMDD);

  const result = fetch(funDataApiUrl.href)
    .then((response) =>
      checkStatusCode<{ success: boolean; data: unknown }>(response),
    )
    .then((response) => response.data);

  // set cache
  const cacheKey = getCacheKey(
    endpoint,
    locale,
    instrumentKey,
    dateYYYYMMDD,
    fromDateYYYYMMDD,
    toDateYYYYMMDD,
  );
  FUNDATA_V2_CACHE.set(cacheKey, result);

  return result;
};

/**
 * EdgePoint sorting should be alphabetical, with the T Series being placed last.
 * @param results
 * @returns
 */
export const sortAndGroupFundataResults = <T>(
  results: SeriesResponse<T>[],
): SeriesResponse<T>[] => {
  // sort titles alphabetically
  results?.sort(function (a, b) {
    if (a._portfolioSeries?.title < b._portfolioSeries?.title) {
      return -1;
    }
    if (a._portfolioSeries?.title > b._portfolioSeries?.title) {
      return 1;
    }
    return 0;
  });

  // group by portfolio and series groups
  const groupedSeries = results?.reduce((acc, series) => {
    // determine groups
    const portfolioGroup =
      series._portfolioSeries?.topic__portfolio?.[0]?.title;
    const seriesGroup = series._portfolioSeries?.filterGroup?.includes(
      SERIES_KEYS.SERIES_T,
    )
      ? SERIES_KEYS.SERIES_T
      : series._portfolioSeries?.filterGroup?.includes(SERIES_KEYS.SERIES_A)
      ? SERIES_KEYS.SERIES_A
      : SERIES_KEYS.SERIES_F;
    const hstGroup = series._portfolioSeries?.nonHst
      ? HST_KEYS.NON_HST
      : HST_KEYS.HST;

    // init empty groups
    if (!acc[portfolioGroup]) {
      acc[portfolioGroup] = {};
    }
    if (!acc[portfolioGroup][seriesGroup]) {
      acc[portfolioGroup][seriesGroup] = {};
    }
    if (!acc[portfolioGroup][seriesGroup][hstGroup]) {
      acc[portfolioGroup][seriesGroup][hstGroup] = [];
    }

    acc[portfolioGroup][seriesGroup][hstGroup].push(series);

    return acc;
  }, {});

  // flatten group
  const sortedAndGroupedResults: SeriesResponse<T>[] = [];
  for (const portfolioKey of Object.keys(groupedSeries)) {
    // Series A, F, then T
    // Non-hst then HST
    const portfolioGroup = groupedSeries[portfolioKey];
    sortedAndGroupedResults.push(
      ...(portfolioGroup[SERIES_KEYS.SERIES_A]?.[HST_KEYS.HST] || []),
    );
    sortedAndGroupedResults.push(
      ...(portfolioGroup[SERIES_KEYS.SERIES_A]?.[HST_KEYS.NON_HST] || []),
    );
    sortedAndGroupedResults.push(
      ...(portfolioGroup[SERIES_KEYS.SERIES_F]?.[HST_KEYS.HST] || []),
    );
    sortedAndGroupedResults.push(
      ...(portfolioGroup[SERIES_KEYS.SERIES_F]?.[HST_KEYS.NON_HST] || []),
    );

    // special sorting for putting A/T before F/T
    const atGroup: SeriesResponse<T>[] = [
      ...(portfolioGroup[SERIES_KEYS.SERIES_T]?.[HST_KEYS.HST]?.filter(
        (series) =>
          series._portfolioSeries?.filterGroup?.includes(SERIES_KEYS.SERIES_A),
      ) || []),
      ...(portfolioGroup[SERIES_KEYS.SERIES_T]?.[HST_KEYS.NON_HST].filter(
        (series) =>
          series._portfolioSeries?.filterGroup?.includes(SERIES_KEYS.SERIES_A),
      ) || []),
    ];
    sortedAndGroupedResults.push(...atGroup);

    const ftGroup: SeriesResponse<T>[] = [
      ...(portfolioGroup[SERIES_KEYS.SERIES_T]?.[HST_KEYS.HST].filter(
        (series) =>
          series._portfolioSeries?.filterGroup?.includes(SERIES_KEYS.SERIES_F),
      ) || []),
      ...(portfolioGroup[SERIES_KEYS.SERIES_T]?.[HST_KEYS.NON_HST].filter(
        (series) =>
          series._portfolioSeries?.filterGroup?.includes(SERIES_KEYS.SERIES_F),
      ) || []),
    ];
    sortedAndGroupedResults.push(...ftGroup);
  }

  return sortedAndGroupedResults;
};

const DistributionHistoryCache = new Map<string, DistributionHistoryResponse>();

const getDistributionHistory = async (
  series: ContentfulTopicPortfolioSeries,
  locale = 'en',
) => {
  const cachedDistributionResponse = DistributionHistoryCache.get(
    series.instrumentKey,
  );
  if (cachedDistributionResponse) {
    return cachedDistributionResponse;
  }

  const funDataApiUrl = new URL(FUNDATA_V2_API_URL);
  const endpoint =
    FundataV2ContentfulSourceApiEndpointMap[
      CONTENTFUL_FUNDATA_SOURCE_OPTIONS.DISTRIBUTIONS
    ];
  const fromDateYYYYMMDD = series.inceptionDate.substring(0, 10);
  const now = getDateWithLocaleOffset();
  const toDateYYYYMMDD = now.toISOString().substring(0, 10);

  funDataApiUrl.searchParams.append('endpoint', endpoint);
  funDataApiUrl.searchParams.append('instrumentKey', series.instrumentKey);
  funDataApiUrl.searchParams.append('locale', locale);
  funDataApiUrl.searchParams.append('fromDate', fromDateYYYYMMDD);
  funDataApiUrl.searchParams.append('toDate', toDateYYYYMMDD);

  const result = await fetch(funDataApiUrl.href).then((response) =>
    checkStatusCode<{ success: boolean; data: DistributionHistoryResponse }>(
      response,
    ),
  );

  if (!result || !result.success) {
    return {} as DistributionHistoryResponse;
  }
  DistributionHistoryCache.set(series.instrumentKey, result.data);
  return result.data;
};

const DistributionsFetchCache = new Map<string, boolean>();
const DistributionsCache = new Map<
  string,
  SeriesResponse<DistributionResponse> | null
>();
export const getDistributionResponse = async (
  series: ContentfulTopicPortfolioSeries,
  dateYYYYMMDD: string,
  locale = 'en',
): Promise<SeriesResponse<DistributionResponse>> => {
  if (!series) {
    return Promise.reject(`No series provided.`);
  }
  if (!dateYYYYMMDD) {
    return Promise.reject(`No date provided.`);
  }

  // check cache for response
  const distributionResponseKey = `${series.instrumentKey}-${locale}-${dateYYYYMMDD}`;
  const cachedDistributionResponse = DistributionsCache.get(
    distributionResponseKey,
  );
  if (cachedDistributionResponse) {
    return cachedDistributionResponse;
  }

  // check if we've fetched this before
  const fetchResponseKey = `${series.instrumentKey}-${locale}`;
  const fetchResponseCache = DistributionsFetchCache.get(fetchResponseKey);
  if (fetchResponseCache != undefined) {
    // this means we've already tried finding data and it doesn't exist
    return Promise.reject(
      `Already attempted and found no data for ${series.instrumentKey}`,
    );
  }

  const data = await getDistributionHistory(series, locale);

  if (!data.History) {
    DistributionsFetchCache.set(fetchResponseKey, false);
    return Promise.reject(`No distribution data for ${series.instrumentKey}`);
  }
  DistributionsFetchCache.set(fetchResponseKey, true);

  // collect and cache all distribution dates and responses
  const dates = [];
  for (const distributionResponse of data.History) {
    const dateYYYYMMDD = distributionResponse.Date.substring(0, 10);
    const normalizedDate = getDateWithLocaleOffset(dateYYYYMMDD);
    dates.push(normalizedDate);

    const responseKey = `${series.instrumentKey}-${locale}-${dateYYYYMMDD}`;
    const distributionResponseCache = DistributionsCache.get(responseKey);
    if (!distributionResponseCache) {
      // copy InterestIncome to DividendIncome
      if (
        !distributionResponse.DividendIncome &&
        distributionResponse.InterestIncome
      ) {
        distributionResponse.DividendIncome =
          distributionResponse.InterestIncome;
      }

      // Create ReturnOfCapital property
      distributionResponse.ReturnOfCapital = 0;

      // if T Series, and date is between January-November, transfer all CapitalGains
      // to ReturnOfCapital field (CapitalGain should be 0 afterwards)
      if (
        series.filterGroup.indexOf('Series T') > -1 &&
        normalizedDate.getMonth() >= 0 && // January
        normalizedDate.getMonth() <= 10 // November
      ) {
        distributionResponse.ReturnOfCapital =
          distributionResponse.CapitalGains;
        distributionResponse.CapitalGains = 0;
      }

      const seriesDistributionResponse: SeriesResponse<DistributionResponse> = {
        _portfolioSeries: series,
        Instrumentkey: data.Instrumentkey,
        LegalName: data.LegalName,
        Language: data.Language,
        ...distributionResponse,
      };
      DistributionsCache.set(responseKey, seriesDistributionResponse);
    }
  }

  // sort descending from most recent to oldest
  dates.sort((a, b) => b.getTime() - a.getTime());

  // set distribution date cache
  const distributionDatesCacheKey = `${series.instrumentKey}-${locale}`;
  DistrbutionDatesCache.set(distributionDatesCacheKey, dates);

  // now try to get cache again
  // if it doesn't exist now, we don't have anything
  const cachedResult = DistributionsCache.get(distributionResponseKey);
  if (!cachedResult) {
    return Promise.reject(
      `No distribution data for ${series.instrumentKey} on ${dateYYYYMMDD}`,
    );
  }

  return cachedResult;
};

const DistrbutionDatesCache = new Map<string, Date[]>();
export const getDistributionDates = async (
  series: ContentfulTopicPortfolioSeries,
  locale = 'en',
): Promise<Date[]> => {
  if (!series) {
    return [];
  }

  const distributionDatesCacheKey = `${series.instrumentKey}-${locale}`;
  // check if we've retrieved these dates before
  const cachedDates = DistrbutionDatesCache.get(distributionDatesCacheKey);
  if (cachedDates) {
    return cachedDates;
  }

  try {
    const data = await getDistributionHistory(series, locale);

    // collect distribution dates and cache all distribution results
    const dates = [];
    for (const distributionResponse of data?.History) {
      const dateYYYYMMDD = distributionResponse.Date.substring(0, 10);
      const normalizedDate = getDateWithLocaleOffset(distributionResponse.Date);
      dates.push(normalizedDate);

      const responseKey = `${series.instrumentKey}-${locale}-${dateYYYYMMDD}`;
      const distributionResponseCache = DistributionsCache.get(responseKey);
      if (!distributionResponseCache) {
        // copy InterestIncome to DividendIncome
        if (
          !distributionResponse.DividendIncome &&
          distributionResponse.InterestIncome
        ) {
          distributionResponse.DividendIncome =
            distributionResponse.InterestIncome;
        }

        // Create ReturnOfCapital property
        distributionResponse.ReturnOfCapital = 0;

        // if T Series, and date is between January-November, transfer all CapitalGains
        // to ReturnOfCapital field (CapitalGain should be 0 afterwards)
        if (
          series.filterGroup.indexOf('Series T') > -1 &&
          normalizedDate.getMonth() >= 0 && // January
          normalizedDate.getMonth() <= 10 // November
        ) {
          distributionResponse.ReturnOfCapital =
            distributionResponse.CapitalGains;
          distributionResponse.CapitalGains = 0;
        }

        const seriesDistributionResponse: SeriesResponse<DistributionResponse> =
          {
            _portfolioSeries: series,
            ...distributionResponse,
          };
        DistributionsCache.set(responseKey, seriesDistributionResponse);
      }
    }

    // sort descending from most recent to oldest
    dates.sort((a, b) => b.getTime() - a.getTime());

    // set cache
    DistrbutionDatesCache.set(distributionDatesCacheKey, dates);

    return dates;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching distribution dates.', error);
    return [];
  }
};

/**
 * EdgePoint sorting should be alphabetical, with the T Series being placed last.
 * @param series
 * @returns
 */
export const groupSortSeries = <T>(
  series: SeriesResponse<T>[],
): SeriesResponse<T>[] => {
  // sort titles alphabetically
  series?.sort(function (a, b) {
    if (a._portfolioSeries?.title < b._portfolioSeries?.title) {
      return -1;
    }
    if (a._portfolioSeries?.title > b._portfolioSeries?.title) {
      return 1;
    }
    return 0;
  });

  // group by portfolio and series groups
  const groupedSeries = series?.reduce((acc, series) => {
    // determine groups
    const portfolioGroup =
      series._portfolioSeries?.topic__portfolio?.[0]?.title;
    const seriesGroup = series._portfolioSeries?.filterGroup?.includes(
      SERIES_KEYS.SERIES_T,
    )
      ? SERIES_KEYS.SERIES_T
      : series._portfolioSeries?.filterGroup?.includes(SERIES_KEYS.SERIES_A)
      ? SERIES_KEYS.SERIES_A
      : SERIES_KEYS.SERIES_F;
    const hstGroup = series._portfolioSeries?.nonHst
      ? HST_KEYS.NON_HST
      : HST_KEYS.HST;

    // init empty groups
    if (!acc[portfolioGroup]) {
      acc[portfolioGroup] = {};
    }
    if (!acc[portfolioGroup][seriesGroup]) {
      acc[portfolioGroup][seriesGroup] = {};
    }
    if (!acc[portfolioGroup][seriesGroup][hstGroup]) {
      acc[portfolioGroup][seriesGroup][hstGroup] = [];
    }

    acc[portfolioGroup][seriesGroup][hstGroup].push(series);

    return acc;
  }, {});

  // flatten group
  const sortedAndGroupedResults: SeriesResponse<T>[] = [];
  for (const portfolioKey of Object.keys(groupedSeries)) {
    // Series A, F, then T
    // Non-hst then HST
    const portfolioGroup = groupedSeries[portfolioKey];
    sortedAndGroupedResults.push(
      ...(portfolioGroup[SERIES_KEYS.SERIES_A]?.[HST_KEYS.HST] || []),
    );
    sortedAndGroupedResults.push(
      ...(portfolioGroup[SERIES_KEYS.SERIES_A]?.[HST_KEYS.NON_HST] || []),
    );
    sortedAndGroupedResults.push(
      ...(portfolioGroup[SERIES_KEYS.SERIES_F]?.[HST_KEYS.HST] || []),
    );
    sortedAndGroupedResults.push(
      ...(portfolioGroup[SERIES_KEYS.SERIES_F]?.[HST_KEYS.NON_HST] || []),
    );

    // special sorting for putting A/T before F/T
    const atGroup: SeriesResponse<T>[] = [
      ...(portfolioGroup[SERIES_KEYS.SERIES_T]?.[HST_KEYS.HST]?.filter(
        (series) =>
          series._portfolioSeries?.filterGroup?.includes(SERIES_KEYS.SERIES_A),
      ) || []),
      ...(portfolioGroup[SERIES_KEYS.SERIES_T]?.[HST_KEYS.NON_HST].filter(
        (series) =>
          series._portfolioSeries?.filterGroup?.includes(SERIES_KEYS.SERIES_A),
      ) || []),
    ];
    sortedAndGroupedResults.push(...atGroup);

    const ftGroup: SeriesResponse<T>[] = [
      ...(portfolioGroup[SERIES_KEYS.SERIES_T]?.[HST_KEYS.HST].filter(
        (series) =>
          series._portfolioSeries?.filterGroup?.includes(SERIES_KEYS.SERIES_F),
      ) || []),
      ...(portfolioGroup[SERIES_KEYS.SERIES_T]?.[HST_KEYS.NON_HST].filter(
        (series) =>
          series._portfolioSeries?.filterGroup?.includes(SERIES_KEYS.SERIES_F),
      ) || []),
    ];
    sortedAndGroupedResults.push(...ftGroup);
  }

  return sortedAndGroupedResults;
};

/**
 * EdgePoint sorting should be alphabetical, with the T Series being placed last.
 * @param series
 * @returns
 */
export const groupSortTopicSeries = (
  series: ContentfulTopicPortfolioSeries[],
): ContentfulTopicPortfolioSeries[] => {
  // Series A, F, then T
  // Non-hst then HST
  const sortHST = (
    a: ContentfulTopicPortfolioSeries,
    b: ContentfulTopicPortfolioSeries,
  ) => {
    if (!a.nonHst && b.nonHst) {
      return -1;
    } else if (a.nonHst && !b.nonHst) {
      return 1;
    } else {
      return 0;
    }
  };
  const aSeries = series
    .filter(
      (series) =>
        series.filterGroup.includes(SERIES_KEYS.SERIES_A) &&
        !series.filterGroup.includes(SERIES_KEYS.SERIES_T),
    )
    .sort(sortHST);
  const fSeries = series
    .filter(
      (series) =>
        series.filterGroup.includes(SERIES_KEYS.SERIES_F) &&
        !series.filterGroup.includes(SERIES_KEYS.SERIES_T),
    )
    .sort(sortHST);
  const atSeries = series
    .filter(
      (series) =>
        series.filterGroup.includes(SERIES_KEYS.SERIES_A) &&
        series.filterGroup.includes(SERIES_KEYS.SERIES_T),
    )
    .sort(sortHST);
  const ftSeries = series
    .filter(
      (series) =>
        series.filterGroup.includes(SERIES_KEYS.SERIES_F) &&
        series.filterGroup.includes(SERIES_KEYS.SERIES_T),
    )
    .sort(sortHST);
  return [...aSeries, ...fSeries, ...atSeries, ...ftSeries];
};
