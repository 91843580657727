import React from 'react';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import {
  createGlobalStyle,
  ThemeProvider,
  DefaultTheme,
} from 'styled-components';
import { WEBSITE } from '../types/website.enum';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.black};
    ${(props) => props.theme.typography.body}
    margin: 0 auto;
    max-width: ${(props) => props.theme.breakpoint.super};
  }

  a {
    text-decoration: none;
    &:hover, &:focus, &:active {
      text-decoration: underline;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${(props) => props.theme.fontFamily.heading};
  }

  h1 {
    ${(props) => props.theme.typography.h1};
  }
  h2 {
    ${(props) => props.theme.typography.h2};
  }
  h3 {
    ${(props) => props.theme.typography.h3};
  }
  h4 {
    ${(props) => props.theme.typography.h4};
  }

  .container.wide,
  .sm${`\\`}:container.wide,
  .md${`\\`}:container.wide,
  .lg${`\\`}:container.wide,
  .xl${`\\`}:container.wide {
    @media (min-width: ${(props) => props.theme.breakpoint.lg}) {
      max-width: calc(1140px + (${(props) =>
        props.theme.spacing.s3} * 2)) !important;
    }
  }

  // conditional styling for Edgepoint and Cymbria pages
  ${(props) => {
    if (props.theme.site === WEBSITE.EDGEPOINT) {
      return `
      // all containers on Edgepoint home are wide
      .home .container,
      .home .sm${`\\`}:container,
      .home .md${`\\`}:container,
      .home .lg${`\\`}:container,
      .home .xl${`\\`}:container {
        @media (min-width: ${props.theme.breakpoint.lg}) {
          max-width: calc(1140px + (${props.theme.spacing.s3} * 2));
        }
      }
      `;
    } else {
      return `
      `;
    }
  }}
`;

interface LayoutProps {
  theme: DefaultTheme;
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = (props) => {
  const { children, theme } = props;

  // Override Material UI theme with current theme
  // We need access to the theme object which is why
  // this is inside the component
  const muiTheme = createTheme({
    palette: {
      primary: {
        main: theme.color.primary,
      },
      secondary: {
        main: theme.color.secondary,
      },
    },
    typography: {
      fontFamily: theme.fontFamily.body,
    },
    overrides: {
      MuiFormLabel: {
        root: {
          fontFamily: theme.fontFamily.body,
          fontSize: theme.fontSize.smaller,
          fontWeight: parseInt(theme.fontWeight.medium),
          color: theme.color.black,
          textTransform: 'uppercase',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(0px, 0px) scale(1)',
          },
        },
      },
      MuiInputBase: {
        root: {
          fontFamily: theme.fontFamily.body,
          fontSize: theme.fontSize.body,
          fontWeight: parseInt(theme.fontWeight.light),
          color: theme.color.black,
        },
      },
      MuiTableRow: {
        root: {
          '&:hover': {
            backgroundColor: theme.color.greyLight,
          },
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: `1px solid ${theme.color.primary}`,
          padding: theme.spacing.s1,
        },
        head: {
          fontFamily: theme.fontFamily.body,
          fontSize: theme.fontSize.smaller,
          fontWeight: parseInt(theme.fontWeight.semiBold),
          color: theme.color.black,
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
        },
        body: {
          fontFamily: theme.fontFamily.body,
          fontSize: theme.fontSize.smaller,
          color: theme.color.black,
        },
      },
      MuiAccordion: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none !important',
        },
      },
      MuiAccordionSummary: {
        root: {
          borderBottom: `1px solid ${theme.color.secondary}`,
          margin: 0,
          minHeight: 'auto',
          padding: 0,
          paddingBottom: theme.spacing.s1,
          '&.Mui-expanded': {
            'min-height': 'auto !important',
          },
        },
        content: {
          margin: 0,
          minHeight: 'auto',
          padding: 0,
          '&.Mui-expanded': {
            margin: 0,
            'min-height': 'auto !important',
          },
        },
        expandIcon: {
          margin: 0,
          padding: 0,
          minHeight: 'auto',
        },
      },
      MuiAccordionDetails: {
        root: {
          padding: 0,
          paddingTop: theme.spacing.s2,
          margin: 0,
          minHeight: 'auto',
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <GlobalStyle />
        {children}
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

export default Layout;
