import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
  ContentfulComponentCtaButton,
  ContentfulTopicDocumentSet,
} from '../../graphql-types';
import { useGlobalState } from '../hooks/useGlobalState';
import { WEBSITE } from '../types/website.enum';
import Button from './Button';
import Typography from './Typography';

const StyledFormControl = styled(FormControl)<{ dark?: string }>`
  ${(props) => {
    if (props.dark === 'true') {
      return `
      color: ${props.theme.color.white} !important;

      .MuiInput-underline::before {
        border-bottom-color: ${props.theme.color.white} !important;
      }
      .MuiSvgIcon-root {
        color: ${props.theme.color.secondary} !important;
      }
      .MuiInputBase-root,
      .MuiFormLabel-root {
        color: ${props.theme.color.white} !important;
      }
    `;
    } else {
      return `
      color: ${props.theme.color.black} !important;

      &.MuiInput-underline::before {
        border-bottom-color: ${props.theme.color.black} !important;
      }
      & .MuiSvgIcon-root {
        color: ${props.theme.color.secondary} !important;
      }
      `;
    }
  }}
`;

interface DownloadProps {
  button?: ContentfulComponentCtaButton;
  className?: string;
  documentSets?: ContentfulTopicDocumentSet[];
  fieldLabel?: string;
  secondaryFieldLabel?: string;
  subheading?: string;
  title?: string;
  variant?: string;
  darkMode?: boolean;
  listColumns?: number;
}
const Download: React.FC<DownloadProps> = (props) => {
  const {
    className,
    documentSets,
    title,
    subheading,
    secondaryFieldLabel,
    fieldLabel,
    button,
    variant,
    darkMode,
    listColumns,
  } = props;
  const theme = React.useContext(ThemeContext);
  const { site } = useGlobalState();

  // set default selected download option
  const defaultSelectedDocumentIds = {};
  if (documentSets && documentSets.length > 0) {
    documentSets.forEach((documentSet) => {
      defaultSelectedDocumentIds[documentSet.id] = ''; // documentSet.documents?.[0]?.id;
    });
  }
  const [selectedDocumentIds, setSelectedDocumentIds] = React.useState<{
    [key: string]: string;
  }>(defaultSelectedDocumentIds);

  const [selectedDocumentSet, setSelectedDocumentSet] =
    React.useState<ContentfulTopicDocumentSet>(documentSets?.[0]);

  const handleChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
    documentSetId: string,
  ) => {
    const newSelectedDocuments = { ...selectedDocumentIds };
    newSelectedDocuments[documentSetId] = e.target.value as string;
    setSelectedDocumentIds(newSelectedDocuments);
  };

  const handleDocumentSetChange = (documentSet: ContentfulTopicDocumentSet) => {
    setSelectedDocumentSet(documentSet);
  };

  const darkModeClasses = darkMode
    ? 'w-full p-m2 pb-l2 bg-greyDark text-white'
    : '';

  const documentSelectorElements =
    documentSets?.length === 1 ? (
      // getDocumentDropdown(documentSets[0])
      <StyledFormControl
        key={documentSets[0].id}
        fullWidth
        dark={darkMode?.toString()}
      >
        <InputLabel id={`document-set-select-label-${documentSets[0].id}`}>
          {fieldLabel}
        </InputLabel>
        <Select
          label={fieldLabel}
          labelId={`document-set-select-label-${documentSets[0].id}`}
          id={`document-set-select-${documentSets[0].id}`}
          onChange={(e) => handleChange(e, documentSets[0].id)}
          value={selectedDocumentIds[documentSets[0].id]}
        >
          {documentSets[0].documents?.map((document) => (
            <MenuItem key={document.id} value={document.id}>
              {document.description || document.title}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    ) : (
      <>
        <StyledFormControl
          fullWidth
          dark={darkMode?.toString()}
          className="my-s3"
        >
          <InputLabel>{fieldLabel}</InputLabel>
          <Select label={fieldLabel} value={selectedDocumentSet?.id}>
            {documentSets?.map((documentSet) => (
              <MenuItem
                key={documentSet.id}
                value={documentSet.id}
                onClick={() => handleDocumentSetChange(documentSet)}
              >
                {documentSet.category?.title}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        {selectedDocumentSet && (
          <StyledFormControl
            key={selectedDocumentSet.id}
            fullWidth
            dark={darkMode?.toString()}
          >
            <InputLabel
              id={`document-set-select-label-${selectedDocumentSet.id}`}
            >
              {secondaryFieldLabel}
            </InputLabel>
            <Select
              label={secondaryFieldLabel}
              labelId={`document-set-select-label-${selectedDocumentSet.id}`}
              id={`document-set-select-${selectedDocumentSet.id}`}
              onChange={(e) => handleChange(e, selectedDocumentSet.id)}
              value={selectedDocumentIds[selectedDocumentSet.id]}
            >
              {selectedDocumentSet.documents?.map((document) => (
                <MenuItem key={document.id} value={document.id}>
                  {document.description || document.title}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        )}
      </>
    );

  const handleClickDownloadButton = () => {
    // now grab the actual document with the id of the document set
    const currentDocument = selectedDocumentSet.documents?.find(
      (document) => document.id === selectedDocumentIds[selectedDocumentSet.id],
    );

    if (currentDocument && currentDocument.file?.url) {
      const element = document.createElement('a');
      element.setAttribute('href', currentDocument.file?.url);
      element.setAttribute('download', currentDocument.file?.fileName);
      element.setAttribute('target', '_blank');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      if (window?.dataLayer) {
        window.dataLayer?.push({
          event: `downloadEvent`,
          value: currentDocument.file?.fileName,
          url: currentDocument.file?.url,
        });
      }
    }
  };

  const downloadButtonElement = (
    <Button
      darkMode={darkMode}
      variant={button?.variant}
      onClick={handleClickDownloadButton}
    >
      {button?.text}
    </Button>
  );

  const handleClickAnchor = (value: string, url: string) => {
    if (window?.dataLayer) {
      window.dataLayer?.push({
        event: `downloadEvent`,
        value: value,
        url: url,
      });
    }
  };

  // variants: 'dropdown', 'dropdown-inline', 'list'
  // default: 'dropdown'
  let downloadVariant = <></>;
  if (variant === 'dropdown') {
    downloadVariant = (
      <div
        className={`${darkModeClasses} ${className} ${
          site === WEBSITE.CYMBRIA ? 'clip-corner' : ''
        }`}
      >
        {title && (
          <Typography
            as="h2"
            variant="h3"
            className="flex flex-col sm:flex-row sm:items-end leading-none"
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="inline self-start flex-shrink-0"
            >
              <path
                d="M14.875 2.27987V10.2812C14.875 10.3392 14.898 10.3949 14.9391 10.4359C14.9801 10.4769 15.0357 10.4999 15.0938 10.4999H23.0951C23.1167 10.5 23.1378 10.4936 23.1558 10.4816C23.1738 10.4696 23.1878 10.4526 23.1962 10.4326C23.2045 10.4127 23.2067 10.3907 23.2025 10.3695C23.1983 10.3483 23.188 10.3288 23.1728 10.3135L15.0615 2.20222C15.0462 2.18698 15.0267 2.17663 15.0055 2.17246C14.9842 2.1683 14.9623 2.17051 14.9423 2.17881C14.9224 2.18712 14.9053 2.20116 14.8934 2.21914C14.8814 2.23713 14.875 2.25826 14.875 2.27987Z"
                fill="currentColor"
              />
              <path
                d="M13.5625 12.25C13.4465 12.25 13.3352 12.2039 13.2531 12.1219C13.1711 12.0398 13.125 11.9285 13.125 11.8125V1.75H5.03125C4.8572 1.75 4.69028 1.81914 4.56721 1.94221C4.44414 2.06528 4.375 2.2322 4.375 2.40625V25.5938C4.375 25.7678 4.44414 25.9347 4.56721 26.0578C4.69028 26.1809 4.8572 26.25 5.03125 26.25H22.9688C23.1428 26.25 23.3097 26.1809 23.4328 26.0578C23.5559 25.9347 23.625 25.7678 23.625 25.5938V12.25H13.5625ZM19.25 21H8.75V19.25H19.25V21ZM19.25 16.625H8.75V14.875H19.25V16.625Z"
                fill="currentColor"
              />
            </svg>
            <span className="mt-s2 sm:mt-0 sm:ml-s1">{title}</span>
          </Typography>
        )}
        {subheading && (
          <Typography as="div" variant="h2" className="mt-s4">
            {subheading}
          </Typography>
        )}
        {documentSets && documentSets.length > 0 && (
          <div className="mt-s2">{documentSelectorElements}</div>
        )}
        {button && <div className="mt-s4">{downloadButtonElement}</div>}
      </div>
    );
  } else if (variant === 'dropdown-inline') {
    downloadVariant = (
      <div className={`${darkModeClasses} ${className}`}>
        <div className="flex flex-col md:flex-row md:items-center mt-s4">
          {documentSets && documentSets.length > 0 && (
            <div className="flex-none w-full max-w-[320px]">
              {documentSelectorElements}
            </div>
          )}
          {button && (
            <div className="mt-s4 md:mt-0 md:ml-s4">
              {downloadButtonElement}
            </div>
          )}
        </div>
      </div>
    );
  } else if (variant === 'list') {
    downloadVariant = (
      <div className={`container ${className}`}>
        {documentSets && documentSets.length > 0 && (
          <>
            {title && (
              <Typography
                as="h2"
                variant="h2"
                className={`leading-none mb-s2 md:mb-s3 ${
                  site === WEBSITE.EDGEPOINT ? 'uppercase' : ''
                }`}
              >
                {title}
              </Typography>
            )}
            <ul>
              {documentSets.map((documentSet) => {
                return (
                  <li key={documentSet.id}>
                    {documentSet.documents &&
                      documentSet.documents.length > 0 && (
                        <ul className="lg:grid grid-cols-12 gap-x-s3 gap-y-s2">
                          {documentSet.documents.map((document) => {
                            // needed to do this way for TW to interpolate static string
                            const columnClasses =
                              listColumns === 1
                                ? 'col-span-12'
                                : listColumns === 2
                                ? 'col-span-6'
                                : listColumns === 3
                                ? 'col-span-4'
                                : 'col-span-3';

                            return (
                              <li
                                key={document.id}
                                className={`mt-s1 lg:mt-0 ${columnClasses}`}
                              >
                                <a
                                  href={document.file?.url}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  download={document.file?.fileName}
                                  onClick={() =>
                                    handleClickAnchor(
                                      document.file?.fileName,
                                      document.file?.url,
                                    )
                                  }
                                  className="flex items-center"
                                >
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mr-s1 mt-1 self-start flex-shrink-0"
                                  >
                                    <path
                                      d="M9.5625 5.625V10.806L11.8125 8.55598L12.6081 9.35156L9 12.9596L5.39191 9.35156L6.1875 8.55598L8.4375 10.806V5.625H3.23438C3.12249 5.625 3.01518 5.66945 2.93606 5.74856C2.85695 5.82768 2.8125 5.93499 2.8125 6.04688V16.4531C2.8125 16.565 2.85695 16.6723 2.93606 16.7514C3.01518 16.8306 3.12249 16.875 3.23438 16.875H14.7656C14.8775 16.875 14.9848 16.8306 15.0639 16.7514C15.1431 16.6723 15.1875 16.565 15.1875 16.4531V6.04688C15.1875 5.93499 15.1431 5.82768 15.0639 5.74856C14.9848 5.66945 14.8775 5.625 14.7656 5.625H9.5625Z"
                                      fill={theme.color.secondary}
                                    />
                                    <path
                                      d="M9.5625 1.125H8.4375V5.625H9.5625V1.125Z"
                                      fill={theme.color.secondary}
                                    />
                                  </svg>
                                  <Typography
                                    variant="body"
                                    className="font-medium"
                                  >
                                    {document.description || document.title}
                                  </Typography>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </div>
    );
  }

  return downloadVariant;
};

export default Download;
