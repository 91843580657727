import Holidays from '../data/ontario-holidays.json';

const localeTimeNow = new Date();

// check if in daylights savings and add an hour to compensate
// cf. https://stackoverflow.com/a/11888430
const january = new Date(localeTimeNow.getFullYear(), 0, 1).getTimezoneOffset();
const july = new Date(localeTimeNow.getFullYear(), 6, 1).getTimezoneOffset();
let daylightSavingsOffset = 0;
if (localeTimeNow.getTimezoneOffset() < Math.max(january, july)) {
  daylightSavingsOffset = 60;
}
const localeOffset =
  (localeTimeNow.getTimezoneOffset() + daylightSavingsOffset) * -60000;

/**
 * Returns true if Date is an Ontario observed holiday.
 * @param date Javascript Date
 * @returns boolean
 */
export const isHoliday = (date: Date): boolean => {
  const isoStringDate = date.toISOString().substring(0, 10);
  const isHoliday = Holidays.includes(isoStringDate);
  return isHoliday;
};

/**
 * Returns true if Date is Monday to Friday and not a holiday.
 * @param date Javascript Date
 * @returns boolean
 */
export const isBusinessDay = (date: Date): boolean => {
  const day = date.getDay();
  return day !== 0 && day !== 6 && !isHoliday(date);
};

export const getLastBusinessDate = (): Date => {
  const lastBusinessDate = getDateWithLocaleOffset();
  lastBusinessDate.setDate(lastBusinessDate.getDate() - 1);

  // last business day cannot be a weekend
  while (!isBusinessDay(lastBusinessDate)) {
    lastBusinessDate.setDate(lastBusinessDate.getDate() - 1);
  }

  return lastBusinessDate;
};

/**
 * Returns true if both dates are the same day.
 * @param date Date
 * @param date2 Date
 * @returns Boolean
 */
export const isSameDay = (date: Date, date2: Date): boolean => {
  if (!date || !date2) {
    return null;
  }

  return (
    date?.getFullYear() === date2?.getFullYear() &&
    date?.getMonth() === date2?.getMonth() &&
    date?.getDate() === date2?.getDate()
  );
};
/**
 * Returns true if the date is the last business day of the month.
 * @param date Date
 * @returns Boolean
 */
export const isLastBusinessDayOfMonth = (date: Date): boolean => {
  if (!date) {
    return null;
  }

  const lastDayOfTheMonth = new Date(
    date?.getFullYear(),
    date?.getMonth() + 1,
    0,
  );

  const isLastBusinessDayOfMonth =
    date?.getFullYear() === lastDayOfTheMonth?.getFullYear() &&
    date?.getMonth() === lastDayOfTheMonth?.getMonth() &&
    date?.getDate() === lastDayOfTheMonth?.getDate();

  return isLastBusinessDayOfMonth;
};

/**
 * Returns an EST offset date when given a UTC date string. If no date string is provided,
 * it will return today at midnight.
 *
 * cf. https://262.ecma-international.org/5.1/#sec-15.9.4.2
 * Javascript will default to assuming a date string in YYYY-MM-DD or YYYY-MM-DDTHH:mm:ss.sssZ
 * is in UTC, which causes local EST computers to show the date a day behind.
 *
 * @param date A string representing a date format in YYYY-MM-DD or YYYY-MM-DDTHH:mm:ss.sssZ or a Javascript Date in UTC
 * @returns A date in EST with correct offset
 */
export const getDateWithLocaleOffset = (date?: string | Date): Date => {
  if (date === null || date === undefined || !date) {
    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return new Date(now.getTime() - localeOffset);
  }

  if (date instanceof Date) {
    const estDate = new Date(date.getTime() - localeOffset);
    return estDate;
  } else if (typeof date === 'string') {
    // string
    // remove any time portion of string
    const dateFormat = date.split('T')?.[0]?.replace(/-/g, '/');
    const newDate = new Date(dateFormat);
    const estDate = new Date(newDate.getTime() - localeOffset);
    return estDate;
  }
};

export const getDaysBetween = (start: string, end: string): number => {
  const localizedStartDate = getDateWithLocaleOffset(start);
  const localizedEndDate = getDateWithLocaleOffset(end);
  const daysBetween =
    (localizedEndDate.getTime() - localizedStartDate.getTime()) /
    (1000 * 3600 * 24);
  return daysBetween;
};
