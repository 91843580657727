/* eslint-disable react/display-name */
import React from 'react';
import styled from 'styled-components';
import {
  ContentfulAsset,
  ContentfulComponentSiteFooter,
} from '../../graphql-types';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { WEBSITE } from '../types/website.enum';
import { useGlobalState } from '../hooks/useGlobalState';
import ContentfulImage from './ContentfulImage';
import Typography from './Typography';
import Link from './Link';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import { replaceContentfulLineBreaks } from '../utils/replaceContentfulLineBreaks';
import { useLocalization } from 'gatsby-theme-i18n';

const footerContentfulRenderOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography as="p" variant="footerBody1" className="mt-s4 first:mt-0">
        {children}
      </Typography>
    ),
  },
  renderText: (text) => replaceContentfulLineBreaks(text),
};

const footerContentfulRenderOptionsLegal = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography as="span" variant="footerBody3">
        {children}
      </Typography>
    ),
  },
  renderText: (text) => replaceContentfulLineBreaks(text),
};

const Container = styled.footer<{ site?: string }>`
  ${(props) => {
    let css = ``;
    if (props.site === WEBSITE.EDGEPOINT) {
      css += `
      background-color: ${props.theme.color.greyLight};
      border-top: 1px solid ${props.theme.color.secondary};
      `;
    } else {
      css += `
      background-color: ${props.theme.color.black};
      color: ${props.theme.color.white};
      `;
    }
    return css;
  }}
`;
const LegalLinksListItem = styled.li`
  @media (min-width: ${(props) => props.theme.breakpoint.sm}) {
    &:not(:first-of-type):before {
      content: '|';
      display: inline-block;
      margin: 0 ${(props) => props.theme.spacing.s1};
    }
  }
`;

interface FooterProps {
  data?: ContentfulComponentSiteFooter;
  logo?: ContentfulAsset;
  socialLinks?: { id: string; key: string; value: string }[];
}
const Footer: React.FC<FooterProps> = (props) => {
  const { data, logo, socialLinks } = props;
  const {
    businessAddress,
    businessContact,
    legalLinks,
    menus,
    registeredTrademark,
  } = data || {};
  const { site } = useGlobalState();
  const { locale } = useLocalization();

  return (
    <Container site={site} className="pt-s4 pb-s4 md:pt-l3 md:pb-m2">
      <div className="container wide">
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-2/3">
            {/* Footer menu section */}
            {menus && (
              <ul className="flex flex-wrap">
                {menus?.map((menu) => {
                  return (
                    <li
                      key={`footer-menu-${menu.id}`}
                      className="mt-m1 md:mt-0 mr-s3 last:mr-0 md:mr-m3"
                    >
                      <Typography
                        variant="footerTitle"
                        className="leading-none"
                      >
                        {menu.text}
                      </Typography>

                      <ul className="flex flex-col">
                        {menu.menu?.map((menuItem) => (
                          <li
                            key={`footer-menu-item-${menuItem.id}`}
                            className="mt-s3 leading-none"
                          >
                            <Link link={menuItem}>
                              <Typography
                                variant="footerBody1"
                                className="leading-none"
                              >
                                {menuItem.text}
                              </Typography>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>

          {/* Logo and contact information section */}
          <div className="mt-m4 lg:mt-0 lg:w-1/3">
            {logo && (
              <ContentfulImage
                image={logo}
                alt={logo?.description || `${site} Logo`}
                style={{
                  maxWidth: '194px',
                }}
              />
            )}

            {businessAddress?.copy && (
              <Typography
                as="div"
                variant="footerTitle"
                className="mt-s4 leading-none"
              >
                {renderContentfulRichText(
                  businessAddress?.copy,
                  footerContentfulRenderOptions,
                )}
              </Typography>
            )}

            {businessContact?.copy && (
              <Typography as="div" variant="body" className="mt-s3">
                {renderContentfulRichText(
                  businessContact?.copy,
                  footerContentfulRenderOptions,
                )}
              </Typography>
            )}

            {socialLinks && (
              <ul className="mt-s3 flex">
                {socialLinks?.map((socialLink) => {
                  let iconElement = <></>;
                  switch (socialLink.key) {
                    case 'linkedin':
                      iconElement = (
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-labelledby="linkedInTitle"
                        >
                          <title id="linkedInTitle">Visit us on LinkedIn</title>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM11.3072 13.252H7.68121V24.1465H11.3072V13.252ZM11.5456 9.88175C11.5221 8.81355 10.7582 7.99995 9.51775 7.99995C8.27732 7.99995 7.46637 8.81355 7.46637 9.88175C7.46637 10.9278 8.25335 11.7649 9.47068 11.7649H9.49385C10.7582 11.7649 11.5456 10.9278 11.5456 9.88175ZM24.3837 17.8998C24.3837 14.5535 22.5949 12.9961 20.2089 12.9961C18.2838 12.9961 17.4218 14.0535 16.9406 14.7953V13.2522H13.3142C13.362 14.2745 13.3142 24.1467 13.3142 24.1467H16.9406V18.0623C16.9406 17.7367 16.9641 17.4119 17.06 17.1788C17.3221 16.5283 17.9187 15.8548 18.9205 15.8548C20.2331 15.8548 20.7578 16.8538 20.7578 18.3178V24.1464H24.3836L24.3837 17.8998Z"
                            fill="#231F20"
                          />
                        </svg>
                      );
                      break;
                  }
                  return (
                    <li
                      key={`social-links-list-item-${socialLink.id}`}
                      className="ml-s2 first:ml-0"
                    >
                      <a
                        href={socialLink.value}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {iconElement}
                      </a>
                    </li>
                  );
                })}
                {/* Harcoded since the url is controlled entirely in frontend */}
                {site === WEBSITE.EDGEPOINT &&
                  (locale === 'en' || locale === 'fr') && (
                    <li
                      key={`social-links-list-item-rss`}
                      className="ml-s2 first:ml-0"
                    >
                      <a
                        href={`${locale === 'fr' ? '/fr' : ''}/rss.xml`}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <svg
                          version="1.1"
                          width="32"
                          height="32"
                          viewBox="0 0 455 455"
                        >
                          <g>
                            <rect
                              x="0"
                              y="0"
                              fill="#231F20"
                              width="455"
                              height="455"
                            />
                            <g>
                              <path
                                fill="#FFFFFF"
                                d="M296.208,159.16C234.445,97.397,152.266,63.382,64.81,63.382v64.348
			c70.268,0,136.288,27.321,185.898,76.931c49.609,49.61,76.931,115.63,76.931,185.898h64.348
			C391.986,303.103,357.971,220.923,296.208,159.16z"
                              />
                              <path
                                fill="#FFFFFF"
                                d="M64.143,172.273v64.348c84.881,0,153.938,69.056,153.938,153.939h64.348
			C282.429,270.196,184.507,172.273,64.143,172.273z"
                              />
                              <circle
                                fill="#FFFFFF"
                                cx="109.833"
                                cy="346.26"
                                r="46.088"
                              />
                            </g>
                          </g>
                        </svg>
                      </a>
                    </li>
                  )}
              </ul>
            )}
          </div>
        </div>

        {site === WEBSITE.EDGEPOINT && (
          <>
            {/* Legal links section */}
            {legalLinks && (
              <ul className="flex flex-wrap">
                {legalLinks?.map((menuItem, index) => {
                  return (
                    <LegalLinksListItem
                      key={`ep-legal-links-list-item${index}-${menuItem.id}`}
                      className="mt-m2 ml-s2 sm:ml-0 first:ml-0"
                    >
                      <Link link={menuItem}>
                        <Typography variant="footerBody2">
                          {menuItem.text}
                        </Typography>
                      </Link>
                    </LegalLinksListItem>
                  );
                })}
              </ul>
            )}

            {/* Legal copy section */}
            {registeredTrademark?.copy && (
              <div className="mt-s2">
                {renderContentfulRichText(
                  registeredTrademark?.copy,
                  footerContentfulRenderOptionsLegal,
                )}
              </div>
            )}
          </>
        )}

        {site === WEBSITE.CYMBRIA && (
          <>
            {/* HR only on Cymbria */}
            {<hr className="mt-m1" />}

            <div className="lg:flex">
              {/* Legal links section */}
              {legalLinks && (
                <div className="mt-s2">
                  <ul className="flex flex-col sm:flex-row">
                    {legalLinks?.map((menuItem) => (
                      <LegalLinksListItem
                        key={`cym-legal-links-list-item-${menuItem.id}`}
                        className="ml-sm first:ml-0"
                      >
                        <Link link={menuItem}>
                          <Typography variant="footerBody2">
                            {menuItem.text}
                          </Typography>
                        </Link>
                      </LegalLinksListItem>
                    ))}
                  </ul>
                </div>
              )}

              {/* Legal copy section */}
              {registeredTrademark?.copy && (
                <div className="mt-s2 lg:ml-auto lg:flex-initial lg:w-1/2">
                  <Typography variant="footerBody2" className="lg:text-right">
                    {renderContentfulRichText(
                      registeredTrademark?.copy,
                      footerContentfulRenderOptions,
                    )}
                  </Typography>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default Footer;
