import React from 'react';
import DataVisualizationHighchartsChart from './DataVisualizationHighchartsChart';
import DataVisualizationTable from './DataVisualizationTable';
import DataVisualizationStatsFundata from './DataVisualizationStatsFundata';
import DataVisualizationTableDistributions from './DataVisualizationTableDistributions';
import { CONTENTFUL_FUNDATA_SOURCE_OPTIONS } from '../services/fundata-v2.service';
import DataVisualizationTablePricing from './DataVisualizationTablePricing';
import DataVisualizationTableInvestmentResults from './DataVisualizationTableInvestmentResults';
import DataVisualizationTableCalendarYearReturns from './DataVisualizationTableCalendarYearReturns';
import { overrideTableFontSize } from '../services/table.service';

interface DataVisualizationProps {
  className?: string;
  variant?: string;
  tableFontSize: null | 'Same as body text' | 'Default';
}

const DataVisualization: React.FC<DataVisualizationProps & any> = (props) => {
  const { className, variant, tableFontSize, ...others } = props;

  const shouldOverrideTableFontSize = overrideTableFontSize(tableFontSize);

  let element = <></>;
  switch (variant) {
    case 'table': {
      if (
        props.dataSets?.[0]?.funDataSource &&
        props.dataSets?.[0]?.funDataSource ===
          CONTENTFUL_FUNDATA_SOURCE_OPTIONS.PRICING
      ) {
        element = (
          <DataVisualizationTablePricing
            dataSet={props.dataSets[0]}
            title={props.title}
            subheading={props.subheading}
            footnote={props.footnote}
            headlineOrientation={props.headlineOrientation}
            showDateSelector={props.showDateSelector}
            dateSelectorLabel={props.dateSelectorLabel}
            showPortfolioFilters={props.showPortfolioFilters}
            removeTSeriesFunds={props.removeTSeriesFunds}
            overrideTableFontSize={shouldOverrideTableFontSize}
          />
        );
      } else if (
        props.dataSets?.[0]?.funDataSource &&
        props.dataSets?.[0]?.funDataSource ===
          CONTENTFUL_FUNDATA_SOURCE_OPTIONS.INVESTMENT_RESULTS
      ) {
        element = (
          <DataVisualizationTableInvestmentResults
            dataSet={props.dataSets[0]}
            title={props.title}
            subheading={props.subheading}
            footnote={props.footnote}
            headlineOrientation={props.headlineOrientation}
            dateSelectorLabel={props.dateSelectorLabel}
            showPortfolioFilters={props.showPortfolioFilters}
            removeTSeriesFunds={props.removeTSeriesFunds}
            overrideTableFontSize={shouldOverrideTableFontSize}
          />
        );
      } else if (
        props.dataSets?.[0]?.funDataSource &&
        props.dataSets?.[0]?.funDataSource ===
          CONTENTFUL_FUNDATA_SOURCE_OPTIONS.CALENDAR_YEAR_RETURNS
      ) {
        element = (
          <DataVisualizationTableCalendarYearReturns
            dataSet={props.dataSets[0]}
            title={props.title}
            subheading={props.subheading}
            footnote={props.footnote}
            headlineOrientation={props.headlineOrientation}
            showPortfolioFilters={props.showPortfolioFilters}
            removeTSeriesFunds={props.removeTSeriesFunds}
            overrideTableFontSize={shouldOverrideTableFontSize}
          />
        );
      } else if (
        props.dataSets?.[0]?.funDataSource &&
        props.dataSets?.[0]?.funDataSource ===
          CONTENTFUL_FUNDATA_SOURCE_OPTIONS.DISTRIBUTIONS
      ) {
        element = (
          <DataVisualizationTableDistributions
            dataSet={props.dataSets[0]}
            title={props.title}
            subheading={props.subheading}
            footnote={props.footnote}
            headlineOrientation={props.headlineOrientation}
            dateSelectorLabel={props.dateSelectorLabel}
            showPortfolioFilters={props.showPortfolioFilters}
            overrideTableFontSize={shouldOverrideTableFontSize}
          />
        );
      } else {
        element = (
          <DataVisualizationTable
            overrideTableFontSize={shouldOverrideTableFontSize}
            {...others}
          />
        );
      }
      break;
    }
    case 'highchart':
    case 'pie-chart':
    case 'pie-chart-alt':
    case 'line-chart':
    case 'bar-chart':
    case 'column-chart': {
      element = <DataVisualizationHighchartsChart type={variant} {...others} />;
      break;
    }
    case 'stats': {
      element = (
        <DataVisualizationStatsFundata
          dataSet={props.dataSets[0]}
          title={props.title}
          showDateSelector={props.showDateSelector}
          dateSelectorLabel={props.dateSelectorLabel}
        />
      );
      break;
    }
    default: {
      element = (
        <div className="container p-s3 bg-red-100">
          Unknown Data Visualization variant {variant}
        </div>
      );
      break;
    }
  }

  return <section className={`${className ?? ''}`}>{element}</section>;
};

export default DataVisualization;
