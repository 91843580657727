import React from 'react';
import { ContentfulDesignAppearance } from '../../graphql-types';

enum SIZING_OPTIONS {
  NONE = 'None',
  XS = 'X-Small',
  SM = 'Small',
  MD = 'Medium',
  LG = 'Large',
  XL = 'X-Large',
}

enum THEME_OPTIONS {
  LIGHT = 'Light',
  DARK = 'Dark',
}

interface AppearanceProps {
  appearance?: ContentfulDesignAppearance;
}
const Appearance: React.FC<AppearanceProps> = (props) => {
  const { appearance, children } = props;

  if (!appearance) {
    return <>{children}</>;
  }

  const backgroundColor =
    appearance?.theme === THEME_OPTIONS.DARK ? `bg-greyLight` : '';

  const topPadding =
    appearance?.topPadding === SIZING_OPTIONS.XS
      ? `pt-s1 md:pt-s2 lg:pt-s3`
      : appearance?.topPadding === SIZING_OPTIONS.SM
      ? `pt-s2 md:pt-s3 lg:pt-s4`
      : appearance?.topPadding === SIZING_OPTIONS.MD
      ? `pt-m1 md:pt-m2 lg:pt-m3`
      : appearance?.topPadding === SIZING_OPTIONS.LG
      ? `pt-m3 md:pt-m4 lg:pt-l1`
      : appearance?.topPadding === SIZING_OPTIONS.XL
      ? `pt-l1 md:pt-l2 lg:pt-l4`
      : ``;
  const bottomPadding =
    appearance?.bottomPadding === SIZING_OPTIONS.XS
      ? `pb-s1 md:pb-s2 lg:pb-s3`
      : appearance?.bottomPadding === SIZING_OPTIONS.SM
      ? `pb-s2 md:pb-s3 lg:pb-s4`
      : appearance?.bottomPadding === SIZING_OPTIONS.MD
      ? `pb-m1 md:pb-m2 lg:pb-m3`
      : appearance?.bottomPadding === SIZING_OPTIONS.LG
      ? `pb-m3 md:pb-m4 lg:pb-l1`
      : appearance?.bottomPadding === SIZING_OPTIONS.XL
      ? `pb-l1 md:pb-l2 lg:pb-l4`
      : ``;
  const leftPadding =
    appearance?.leftPadding === SIZING_OPTIONS.XS
      ? `pl-s1 md:pl-s2 lg:pl-s3`
      : appearance?.leftPadding === SIZING_OPTIONS.SM
      ? `pl-s2 md:pl-s3 lg:pl-s4`
      : appearance?.leftPadding === SIZING_OPTIONS.MD
      ? `pl-m1 md:pl-m2 lg:pl-m3`
      : appearance?.leftPadding === SIZING_OPTIONS.LG
      ? `pl-m3 md:pl-m4 lg:pl-l1`
      : appearance?.leftPadding === SIZING_OPTIONS.XL
      ? `pl-l1 md:pl-l2 lg:pl-l4`
      : ``;
  const rightPadding =
    appearance?.rightPadding === SIZING_OPTIONS.XS
      ? `pr-s1 md:pr-s2 lg:pr-s3`
      : appearance?.rightPadding === SIZING_OPTIONS.SM
      ? `pr-s2 md:pr-s3 lg:pr-s4`
      : appearance?.rightPadding === SIZING_OPTIONS.MD
      ? `pr-m1 md:pr-m2 lg:pr-m3`
      : appearance?.rightPadding === SIZING_OPTIONS.LG
      ? `pr-m3 md:pr-m4 lg:pr-l1`
      : appearance?.rightPadding === SIZING_OPTIONS.XL
      ? `pr-l1 md:pr-l2 lg:pr-l4`
      : ``;
  const topMargin =
    appearance?.topMargin === SIZING_OPTIONS.XS
      ? `mt-s1 md:mt-s2 lg:mt-s3`
      : appearance?.topMargin === SIZING_OPTIONS.SM
      ? `mt-s2 md:mt-s3 lg:mt-s4`
      : appearance?.topMargin === SIZING_OPTIONS.MD
      ? `mt-m1 md:mt-m2 lg:mt-m3`
      : appearance?.topMargin === SIZING_OPTIONS.LG
      ? `mt-m3 md:mt-m4 lg:mt-l1`
      : appearance?.topMargin === SIZING_OPTIONS.XL
      ? `mt-l1 md:mt-l2 lg:mt-l4`
      : ``;
  const bottomMargin =
    appearance?.bottomMargin === SIZING_OPTIONS.XS
      ? `mb-s1 md:mb-s2 lg:mb-s3`
      : appearance?.bottomMargin === SIZING_OPTIONS.SM
      ? `mb-s2 md:mb-s3 lg:mb-s4`
      : appearance?.bottomMargin === SIZING_OPTIONS.MD
      ? `mb-m1 md:mb-m2 lg:mb-m3`
      : appearance?.bottomMargin === SIZING_OPTIONS.LG
      ? `mb-m3 md:mb-m4 lg:mb-l1`
      : appearance?.bottomMargin === SIZING_OPTIONS.XL
      ? `mb-l1 md:mb-l2 lg:mb-l4`
      : ``;

  const styledChildren = React.Children.map(
    children,
    (child: React.ReactElement<any>) => {
      const reactElements = React.cloneElement(child, {
        className: `${
          child.props.className ?? ''
        } ${topPadding} ${bottomPadding} ${leftPadding} ${rightPadding} ${topMargin} ${bottomMargin}`,
      });
      if (backgroundColor) {
        return <div className={backgroundColor}>{reactElements}</div>;
      } else {
        return reactElements;
      }
    },
  );

  return <>{styledChildren}</>;
};

export default Appearance;
