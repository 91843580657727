import { ArrowForward } from '@material-ui/icons';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import React from 'react';
import styled from 'styled-components';
import { WEBSITE } from '../types/website.enum';
import { useGlobalState } from '../hooks/useGlobalState';

// cf. https://stackoverflow.com/a/19709846
const protocolRegularExpression = new RegExp('^(?:[a-z]+:)?//', 'i');

export enum BUTTON_VARIANTS {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINK = 'link',
}

interface ContainerProps {
  variant?: string;
  site?: string;
  dark?: boolean;
}
const Container = styled.div<ContainerProps>`
  cursor: pointer;
  line-height: 20px; /* ensures padding doesn't become too big */
  position: relative;
  z-index: 0;

  /* enforce proper styling when button includes links and icons */
  text-decoration: none !important;
  &:active,
  &:focus,
  &:hover {
    text-decoration: none !important;
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }

  ${(props) => {
    if (props.variant === BUTTON_VARIANTS.PRIMARY) {
      if (props.site === WEBSITE.EDGEPOINT) {
        return `
            border: 1px solid ${props.theme.color.black};
            background: ${props.theme.color.black};
            color: ${props.theme.color.white};
            font-family: ${props.theme.fontFamily.heading};
            font-size: ${props.theme.fontSize.small};
            font-weight: ${props.theme.fontWeight.medium};
            svg {
              fill: ${props.theme.color.secondary} !important;
            }
            text-transform: uppercase;
      
            padding: ${props.theme.spacing.s1}
              ${props.theme.spacing.s2};
            @media (min-width: ${props.theme.breakpoint.sm}) {
              padding: ${props.theme.spacing.s2}
                ${props.theme.spacing.s3};
            }
            
            &:hover:not(:disabled), &:active:not(:disabled), &:focus:not(:disabled) {
              background: transparent;
              color: ${props.theme.color.black};
            }
        `;
      } else if (props.site === WEBSITE.CYMBRIA) {
        return `
            background: ${props.theme.color.black};
            color: ${props.theme.color.white};
            font-family: ${props.theme.fontFamily.heading};
            font-size: ${props.theme.fontSize.small};
            font-weight: ${props.theme.fontWeight.bold};
      
            padding: ${props.theme.spacing.s1}
              ${props.theme.spacing.s2};
            @media (min-width: ${props.theme.breakpoint.sm}) {
              padding: ${props.theme.spacing.s2}
                ${props.theme.spacing.s3};
            }
        
            &:hover:not(:disabled), &:active:not(:disabled), &:focus:not(:disabled) {
              background: ${props.theme.color.white};
              color: ${props.theme.color.black};
            }

            ${
              props.dark
                ? `
              background: ${props.theme.color.white};
              color: ${props.theme.color.black};
              &:hover:not(:disabled), &:active:not(:disabled), &:focus:not(:disabled) {
                background: ${props.theme.color.black};
                color: ${props.theme.color.white};
              }
            `
                : ``
            }
        `;
      }
    } else if (props.variant === BUTTON_VARIANTS.SECONDARY) {
      if (props.site === WEBSITE.EDGEPOINT) {
        return `
            border: 1px solid ${props.theme.color.secondary};
            color: ${props.theme.color.black};
            font-family: ${props.theme.fontFamily.heading};
            font-size: ${props.theme.fontSize.small};
            font-weight: ${props.theme.fontWeight.medium};
            text-transform: uppercase;
      
            padding: ${props.theme.spacing.s1}
              ${props.theme.spacing.s2};
            @media (min-width: ${props.theme.breakpoint.sm}) {
              padding: ${props.theme.spacing.s2}
                ${props.theme.spacing.s3};
            }

            &:hover:not(:disabled), &:active:not(:disabled), &:focus:not(:disabled) {
              background: ${props.theme.color.secondary};
            }
        `;
      } else if (props.site === WEBSITE.CYMBRIA) {
        return `
            border-bottom: 2px solid ${props.theme.color.black};
            color: ${props.theme.color.black};
            font-family: ${props.theme.fontFamily.heading};
            font-size: ${props.theme.fontSize.small};
            font-weight: ${props.theme.fontWeight.bold};
            padding: ${props.theme.spacing.s1} 0;

            &:hover:not(:disabled), &:active:not(:disabled), &:focus:not(:disabled) {
              border-color: ${props.theme.color.secondary};
            }
        `;
      }
    } else {
      // default 'link' style
      return `
            color: ${props.theme.color.black};
            ${props.theme.typography.body}
            font-weight: ${props.theme.fontWeight.medium};

            &:hover, &:active, &:focus {
              text-decoration: underline !important;
            }
        `;
    }
  }}};
`;

interface ButtonProps {
  variant?: string;
  darkMode?: boolean;
  className?: string;
  onClick?: () => void;
  link?: string;
  openInNewWindow?: boolean;
  parameters?: string;
  type?: string;
  disabled?: boolean;
}
const Button: React.FC<ButtonProps> = (props) => {
  const {
    children,
    className,
    link,
    onClick,
    openInNewWindow,
    parameters,
    variant,
    type,
    disabled,
    darkMode,
  } = props;
  const { locale } = useLocalization();
  const { site } = useGlobalState();

  const buttonAttributes: any = {};

  // test if link url is internal or external link
  const hasProtocol = protocolRegularExpression.test(link);

  if (link && !hasProtocol) {
    let normalizedLink = link?.startsWith('/') // ensures internal links are always relative to baseUrl
      ? `${link}`
      : `/${link}`;
    normalizedLink = normalizedLink?.endsWith('/') // ensures internal links always have trailing slash
      ? `${normalizedLink}`
      : `${normalizedLink}/`;
    buttonAttributes.as = LocalizedLink;
    buttonAttributes.language = locale;
    buttonAttributes.to = `${normalizedLink}${
      parameters ? `?${parameters}` : ``
    }`;
  } else if (link) {
    buttonAttributes.as = 'a';
    buttonAttributes.href = link;
    buttonAttributes.target = openInNewWindow ? '_blank' : '';
  } else {
    buttonAttributes.as = 'button';
    buttonAttributes.type = type;
    buttonAttributes.disabled = disabled;
    buttonAttributes.onClick = onClick || null;
  }
  const hasArrow =
    variant === BUTTON_VARIANTS.PRIMARY && site === WEBSITE.EDGEPOINT;

  return (
    <Container
      site={site}
      variant={variant}
      dark={darkMode}
      className={`${className || ``} inline-flex items-center justify-center`}
      {...buttonAttributes}
    >
      {children}
      {hasArrow && <ArrowForward className="ml-2" fontSize="small" />}
    </Container>
  );
};
export default Button;
