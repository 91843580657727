import React from 'react';
import styled from 'styled-components';
import { useLocalization } from 'gatsby-theme-i18n';
import { StaticImage } from 'gatsby-plugin-image';
import {
  ContentfulAsset,
  ContentfulComponentCtaButton,
  ContentfulComposePage,
} from '../../graphql-types';
import Button from './Button';
import ContentfulImage from './ContentfulImage';
import Typography from './Typography';
import { WEBSITE } from '../types/website.enum';
import { useGlobalState } from '../hooks/useGlobalState';
import { formatLocalizedDate } from '../services/localization.service';
import { getDateWithLocaleOffset } from '../services/date.service';
import Link from './Link';

const CardContainer = styled.div`
  position: relative;
  a:hover,
  a:active,
  a:focus {
    text-decoration: none;

    h3 {
      text-decoration: underline;
    }
  }
`;
const CardFlexContainer = styled.div<{ variant?: string }>`
  display: flex;
  flex-direction: column;
  ${(props) => {
    if (props.variant === 'horizontal') {
      return `
      @media (min-width: ${props.theme.breakpoint.md}) {
        flex-direction: row;
        margin: 0 calc(-${props.theme.spacing.s3} / 2);
        overflow: hidden;

        & > div {
          padding: 0 calc(${props.theme.spacing.s3} / 2);
        }
      }
      `;
    }
  }}
`;

const CaptionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: ${(props) => props.theme.color.primary};
  border-left: 4px solid ${(props) => props.theme.color.secondary};
  color: ${(props) => props.theme.color.white};
  padding: ${(props) => props.theme.spacing.s2};
  line-height: 0; /* removes added spacing around inner span */
`;
const ImageContainer = styled.div<{ variant?: string }>`
  height: 280px;
  ${(props) => {
    if (props.variant === 'horizontal') {
      return `
        @media (min-width: ${props.theme.breakpoint.md}) {
          width: 260px;
          height: 160px;
        }
        `;
    }
  }}
`;

interface CardProps {
  body?: string;
  buttons?: ContentfulComponentCtaButton[];
  caption?: string;
  className?: string;
  date?: string;
  image?: ContentfulAsset;
  page?: ContentfulComposePage;
  subheading?: string;
  title?: string;
  variant?: 'horizontal' | 'vertical';
}
const Card: React.FC<CardProps> = (props) => {
  const {
    body,
    buttons,
    caption,
    className,
    date,
    image,
    page,
    subheading,
    title,
    variant,
  } = props;
  const { locale } = useLocalization();
  const { site } = useGlobalState();

  const PageWrapper: React.FC = ({ children }) =>
    page && page?.slug ? (
      <Link language={locale} link={{ page: page }}>
        {children}
      </Link>
    ) : (
      <>{children}</>
    );

  return (
    <CardContainer className={`${className || ''}`}>
      <PageWrapper>
        {caption && (
          <CaptionContainer
            className={
              site === WEBSITE.CYMBRIA ? 'clip-corner-sm bg-greyDark' : ''
            }
          >
            <Typography
              as="span"
              variant="tag"
              className="uppercase leading-none text-white"
            >
              {caption}
            </Typography>
          </CaptionContainer>
        )}
        <CardFlexContainer variant={variant}>
          <ImageContainer variant={variant} className="flex-none">
            {image ? (
              <ContentfulImage
                image={image}
                alt={image.description || ''}
                style={{
                  height: '100%',
                  width: '100%',
                }}
              />
            ) : (
              // use placeholder image asset
              <StaticImage
                src="../images/placeholder.png"
                alt="Placeholder image"
                style={{
                  height: '100%',
                  width: '100%',
                }}
              />
            )}
          </ImageContainer>
          <div className="flex-1">
            {title && (
              <Typography
                as="h3"
                variant="h4"
                className={`mt-s2 ${variant === 'horizontal' ? 'md:mt-0' : ''}`}
              >
                {title}
              </Typography>
            )}
            {subheading && (
              <Typography as="h4" variant="body" className="mt-s2">
                {subheading}
              </Typography>
            )}
            {date && (
              <Typography
                as="time"
                variant="date"
                className={`mt-s2 block ${
                  variant === 'vertical' ? '' : 'md:hidden'
                }`}
              >
                {formatLocalizedDate(getDateWithLocaleOffset(date), locale, {
                  month: 'long',
                })}
              </Typography>
            )}
            {body && (
              <Typography as="p" variant="body" className="mt-s2">
                {body}
              </Typography>
            )}
            {date && (
              <Typography
                as="time"
                variant="date"
                className={`mt-s2 ${
                  variant === 'horizontal' ? 'hidden md:block' : 'hidden'
                }`}
              >
                {formatLocalizedDate(getDateWithLocaleOffset(date), locale, {
                  month: 'long',
                })}
              </Typography>
            )}
            {buttons?.length > 0 &&
              buttons?.map((button) => (
                <Button
                  key={`button-${button?.id}`}
                  variant={button?.variant}
                  link={button?.page?.slug || button?.externalUrl}
                  openInNewWindow={button.openInNewWindow}
                >
                  {button?.text}
                </Button>
              ))}
          </div>
        </CardFlexContainer>
      </PageWrapper>
    </CardContainer>
  );
};

export default Card;
