import React from 'react';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import { CircularProgress, Fade, TextField } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FormikConfig, useFormik } from 'formik';
import * as yup from 'yup';
import { ContentfulGlobalMicrocopy } from '../../graphql-types';
import Button from './Button';
import Typography from './Typography';
import { getPlainTextFromMicrocopy } from '../utils/getPlainTextFromMicrocopy';
import { isLoggedIn } from '../services/login.service';
import { isBrowser } from '../utils/isBrowser';

/** Password RegEx for AWS Cognito
 * Must contain one lowercase, one uppercase, one number, and one special character
 */
const CognitoPasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/;
export enum AUTH_CHALLENGES {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
}
export enum LOGIN_FORM_STATE {
  LOGIN,
  FORCE_CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  LOGGED_IN,
  ERROR,
}

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 0px;
    background-color: ${(props) => props.theme.color.white};

    & fieldset {
      border-color: ${(props) => props.theme.color.primary};
    }
  }
`;

const FieldLabel = styled(Typography)`
  text-transform: uppercase;
`;

interface SignUpLoginProps {
  className?: string;
  title?: string;
  variant?: string;
  formFieldLabels?: ContentfulGlobalMicrocopy[];
}
interface SignUpLabels {
  LoginMandatoryField?: string;
  LoginRequiredField?: string;
  LoginPassword?: string;
  LoginPasswordValidation?: string;
  LoginMatchPassword?: string;
  LoginLoggedInTitle?: string;
  LoginSubmit?: string;
  LoginCancel?: string;
  LoginChangePassword?: string;
  LoginChangePasswordError?: string;
  LoginChangePasswordTitle?: string;
  LoginCode?: string;
  LoginConfirmPassword?: string;
  LoginCurrentPassword?: string;
  LoginEmail?: string;
  LoginForceChangePasswordError?: string;
  LoginForceChangePasswordTitle?: string;
  LoginEnterVerificationCode?: string;
  LoginForgotPassword?: string;
  LoginForgotPasswordMessage?: string;
  LoginForgotPasswordTitle?: string;
  LoginEmailOrPasswordIncorrect?: string;
  LoginLogOut?: string;
  LoginLogOutError?: string;
  LoginNewPassword?: string;
  LoginResetPasswordError?: string;
}
const SignUpLogin: React.FC<SignUpLoginProps> = (props) => {
  const { className, title, formFieldLabels } = props;
  const [currentLoginFormState, setCurrentLoginFormState] = React.useState(
    LOGIN_FORM_STATE.LOGIN,
  );
  // check if user is logged in on init
  React.useEffect(() => {
    setIsLoading(true);
    // Amplify Auth is taking time to initialize
    setTimeout(() => {
      isLoggedIn()
        .then((isLoggedIn) => {
          if (isLoggedIn) {
            setCurrentLoginFormState(LOGIN_FORM_STATE.LOGGED_IN);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 500);
  }, []);

  const [isLoading, setIsLoading] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [forceChangePasswordUser, setForceChangePasswordUser] =
    React.useState(null);

  // setup form labels, validation, and formik options
  const labels: SignUpLabels = {};
  formFieldLabels?.forEach((formField) => {
    labels[formField.key] = getPlainTextFromMicrocopy(formField.copy?.raw);
  });

  const handleLogOut = async () => {
    // reset
    setErrorMessage('');
    setIsLoading(true);
    try {
      const _result = await Auth.signOut();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error signing out: ', error);
      setErrorMessage(
        labels?.LoginLogOutError || 'Sorry, there was an error logging out.',
      );
      setIsLoading(false);
    }

    if (isBrowser()) {
      // brute force whole page to rerender
      window.location.reload();
    }
  };

  // login form
  const loginFormSchema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required(labels?.LoginMandatoryField || 'This field is mandatory'),
    password: yup
      .string()
      .required(labels?.LoginMandatoryField || 'This field is mandatory'),
  });
  const loginFormikOptions: FormikConfig<{
    email: string;
    password: string;
  }> = {
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginFormSchema,
    onSubmit: async (values, { resetForm }) => {
      // reset any error message
      setErrorMessage('');
      setIsLoading(true);
      try {
        const result = await Auth.signIn(values.email, values.password);
        if (result?.challengeName === AUTH_CHALLENGES.NEW_PASSWORD_REQUIRED) {
          setForceChangePasswordUser(result);
          setCurrentLoginFormState(LOGIN_FORM_STATE.FORCE_CHANGE_PASSWORD);
          resetForm();
          setIsLoading(false);
        } else {
          if (isBrowser()) {
            // brute force whole page to rerender
            window.location.reload();
          }
        }
      } catch (error) {
        // no matter what error we get from authenticating the user,
        // we don't want to leak any details to whether that user
        // exists, provided the wrong password, or is disabled, so
        // simply provide a generic error message
        setErrorMessage(
          labels?.LoginEmailOrPasswordIncorrect ||
            'Email or password is incorrect.',
        );
        setIsLoading(false);
      }
    },
  };
  const loginFormik = useFormik(loginFormikOptions);
  const loginElement = (
    <form onSubmit={loginFormik.handleSubmit}>
      <fieldset
        disabled={isLoading}
        className={`flex flex-col items-bottom w-5/6`}
      >
        <FieldLabel variant="tag">
          {labels?.LoginEmail || '* Email:'}
        </FieldLabel>
        <StyledTextField
          id="email"
          name="email"
          size="small"
          type="email"
          variant="outlined"
          className="flex-1 my-s1"
          value={loginFormik.values.email}
          onChange={loginFormik.handleChange}
          error={loginFormik.touched.email && Boolean(loginFormik.errors.email)}
          helperText={loginFormik.touched.email && loginFormik.errors.email}
        />

        <FieldLabel variant="tag" className="mt-s2">
          {labels?.LoginPassword || '* Password:'}
        </FieldLabel>
        <StyledTextField
          id="password"
          name="password"
          type="password"
          size="small"
          variant="outlined"
          className="flex-1 my-s1"
          value={loginFormik.values.password}
          onChange={loginFormik.handleChange}
          error={
            loginFormik.touched.password && Boolean(loginFormik.errors.password)
          }
          helperText={
            loginFormik.touched.password && loginFormik.errors.password
          }
        />

        {labels?.LoginRequiredField && (
          <div className="text-right">
            <Typography variant="footerBody3">
              {labels?.LoginRequiredField}
            </Typography>
          </div>
        )}

        <div className="flex items-center mt-s3">
          <Button
            type="submit"
            variant={`primary`}
            className="flex-none"
            disabled={isLoading || !(loginFormik.isValid && loginFormik.dirty)}
          >
            {labels?.LoginSubmit || 'Submit'}
          </Button>
          <Button
            type="button"
            variant={`link`}
            className="ml-auto"
            onClick={() => {
              setCurrentLoginFormState(LOGIN_FORM_STATE.FORGOT_PASSWORD);
            }}
          >
            <Typography variant="date">
              {labels?.LoginForgotPassword || 'Forgot Password'}
            </Typography>
          </Button>
        </div>
      </fieldset>
    </form>
  );

  // force change password form
  const forceChangePasswordFormSchema = yup.object().shape({
    newPassword: yup
      .string()
      .matches(
        CognitoPasswordRegex,
        labels?.LoginPasswordValidation ||
          'Password must contain 8 characters, one uppercase, one lowercase, one number, and one special character',
      )
      .required(labels?.LoginMandatoryField || 'This field is mandatory'),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), null],
        labels?.LoginMatchPassword || 'Passwords must match',
      )
      .required(labels?.LoginMandatoryField || 'This field is mandatory'),
  });
  const forceChangePasswordFormikOptions: FormikConfig<{
    newPassword: string;
    confirmPassword: string;
  }> = {
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: forceChangePasswordFormSchema,
    onSubmit: async (values, { resetForm }) => {
      // reset
      setErrorMessage('');
      setIsLoading(true);

      try {
        // const cognitoUser = await Auth.currentAuthenticatedUser();
        const _result = await Auth.completeNewPassword(
          forceChangePasswordUser,
          values.confirmPassword,
          [],
        );

        if (isBrowser()) {
          // brute force whole page to rerender
          window.location.reload();
        }
      } catch (error) {
        // no matter what error we get from authenticating the user,
        // we don't want to leak any details to whether that user
        // exists, provided the wrong password, or is disabled, so
        // simply provide a generic error message
        setErrorMessage(
          labels?.LoginForceChangePasswordError ||
            'Sorry, there was an error updating your password.',
        );
        setIsLoading(false);
      }
    },
  };
  const forceChangePasswordFormik = useFormik(forceChangePasswordFormikOptions);
  const forceChangePasswordElement = (
    <form onSubmit={forceChangePasswordFormik.handleSubmit}>
      <fieldset
        disabled={isLoading}
        className={`flex flex-col items-bottom w-5/6`}
      >
        <div>
          <strong>
            {labels?.LoginForceChangePasswordTitle ||
              `You must provide a new password before logging in for the first time.`}
          </strong>
        </div>
        <FieldLabel variant="tag" className="mt-s2">
          {labels?.LoginNewPassword || '* New Password:'}
        </FieldLabel>
        <StyledTextField
          id="password"
          name="newPassword"
          type="password"
          size="small"
          variant="outlined"
          className="flex-1 my-s1"
          value={forceChangePasswordFormik.values.newPassword}
          onChange={forceChangePasswordFormik.handleChange}
          error={
            forceChangePasswordFormik.touched.newPassword &&
            Boolean(forceChangePasswordFormik.errors.newPassword)
          }
          helperText={
            forceChangePasswordFormik.touched.newPassword &&
            forceChangePasswordFormik.errors.newPassword
          }
        />
        <FieldLabel variant="tag" className="mt-s2">
          {labels?.LoginConfirmPassword || '* Confirm Password:'}
        </FieldLabel>
        <StyledTextField
          id="confirm-password"
          name="confirmPassword"
          type="password"
          size="small"
          variant="outlined"
          className="flex-1 my-s1"
          value={forceChangePasswordFormik.values.confirmPassword}
          onChange={forceChangePasswordFormik.handleChange}
          error={
            forceChangePasswordFormik.touched.confirmPassword &&
            Boolean(forceChangePasswordFormik.errors.confirmPassword)
          }
          helperText={
            forceChangePasswordFormik.touched.confirmPassword &&
            forceChangePasswordFormik.errors.confirmPassword
          }
        />
        {labels?.LoginRequiredField && (
          <div className="text-right">
            <Typography variant="footerBody3">
              {labels?.LoginRequiredField}
            </Typography>
          </div>
        )}
        <div className="flex justify-between items-center mt-s3">
          <Button type="submit" variant={'primary'} className="flex-none">
            {labels?.LoginSubmit || `Submit`}
          </Button>
          <Button
            type="submit"
            variant={'link'}
            onClick={() => {
              setCurrentLoginFormState(LOGIN_FORM_STATE.LOGIN);
              forceChangePasswordFormik.resetForm();
            }}
          >
            <Typography variant="date">
              {labels?.LoginCancel || `Cancel`}
            </Typography>
          </Button>
        </div>
      </fieldset>
    </form>
  );

  // change password form
  const changePasswordFormSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .matches(
        CognitoPasswordRegex,
        labels?.LoginPasswordValidation ||
          'Password must contain 8 characters, one uppercase, one lowercase, one number, and one special character',
      )
      .required(labels?.LoginMandatoryField || 'This field is mandatory'),
    newPassword: yup
      .string()
      .matches(
        CognitoPasswordRegex,
        labels?.LoginPasswordValidation ||
          'Password must contain 8 characters, one uppercase, one lowercase, one number, and one special character',
      )
      .required(labels?.LoginMandatoryField || 'This field is mandatory'),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), null],
        labels?.LoginMatchPassword || 'Passwords must match',
      )
      .required(labels?.LoginMandatoryField || 'This field is mandatory'),
  });
  const changePasswordFormikOptions: FormikConfig<{
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }> = {
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: changePasswordFormSchema,
    onSubmit: async (values, { resetForm }) => {
      // reset any error message
      setErrorMessage('');
      setIsLoading(true);
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const _result = await Auth.changePassword(
          cognitoUser,
          values.currentPassword,
          values.confirmPassword,
        );
        // user is already logged in at this state, so we shouldn't
        // need to force page refresh like other states
        setCurrentLoginFormState(LOGIN_FORM_STATE.LOGGED_IN);
        resetForm();
      } catch (error) {
        // simply provide a generic error message
        // eslint-disable-next-line no-console
        console.error('Error changing password', error);
        setErrorMessage(
          labels?.LoginChangePasswordError ||
            'Sorry, there was an error updating your password.',
        );
      }

      setIsLoading(false);
    },
  };
  const changePasswordFormik = useFormik(changePasswordFormikOptions);
  const changePasswordElement = (
    <form onSubmit={changePasswordFormik.handleSubmit}>
      <fieldset
        disabled={isLoading}
        className={`flex flex-col items-bottom w-5/6`}
      >
        <div>
          <strong>
            {labels?.LoginChangePasswordTitle || `Change Password`}
          </strong>
        </div>
        <FieldLabel variant="tag" className="mt-s2">
          {labels?.LoginCurrentPassword || '* Current Password:'}
        </FieldLabel>
        <StyledTextField
          id="currentPassword"
          name="currentPassword"
          type="password"
          size="small"
          variant="outlined"
          className="flex-1 my-s1"
          value={changePasswordFormik.values.currentPassword}
          onChange={changePasswordFormik.handleChange}
          error={
            changePasswordFormik.touched.currentPassword &&
            Boolean(changePasswordFormik.errors.currentPassword)
          }
          helperText={
            changePasswordFormik.touched.currentPassword &&
            changePasswordFormik.errors.currentPassword
          }
        />
        <hr />
        <FieldLabel variant="tag" className="mt-s2">
          {labels?.LoginNewPassword || '* New Password:'}
        </FieldLabel>
        <StyledTextField
          id="newPassword"
          name="newPassword"
          type="password"
          size="small"
          variant="outlined"
          className="flex-1 my-s1"
          value={changePasswordFormik.values.newPassword}
          onChange={changePasswordFormik.handleChange}
          error={
            changePasswordFormik.touched.newPassword &&
            Boolean(changePasswordFormik.errors.newPassword)
          }
          helperText={
            changePasswordFormik.touched.newPassword &&
            changePasswordFormik.errors.newPassword
          }
        />
        <FieldLabel variant="tag" className="mt-s2">
          {labels?.LoginConfirmPassword || '* Confirm Password:'}
        </FieldLabel>
        <StyledTextField
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          size="small"
          variant="outlined"
          className="flex-1 my-s1"
          value={changePasswordFormik.values.confirmPassword}
          onChange={changePasswordFormik.handleChange}
          error={
            changePasswordFormik.touched.confirmPassword &&
            Boolean(changePasswordFormik.errors.confirmPassword)
          }
          helperText={
            changePasswordFormik.touched.confirmPassword &&
            changePasswordFormik.errors.confirmPassword
          }
        />
        {labels?.LoginRequiredField && (
          <div className="text-right">
            <Typography variant="footerBody3">
              {labels?.LoginRequiredField}
            </Typography>
          </div>
        )}
        <div className="flex justify-between items-center mt-s3">
          <Button
            type="submit"
            variant={`primary`}
            className="flex-none"
            disabled={!changePasswordFormik.dirty}
          >
            {labels?.LoginSubmit || `Submit`}
          </Button>
          <div className="flex justify-end">
            <Button
              type="button"
              variant={'link'}
              onClick={() => {
                setCurrentLoginFormState(LOGIN_FORM_STATE.LOGGED_IN);
                changePasswordFormik.resetForm();
              }}
            >
              <Typography variant="date">
                {labels?.LoginCancel || `Cancel`}
              </Typography>
            </Button>
          </div>
        </div>
      </fieldset>
    </form>
  );

  // forgot password form
  const forgotPasswordFormSchema = yup.object().shape({
    email: yup.string().email(),
  });
  const forgotPasswordFormikOptions: FormikConfig<{ email: string }> = {
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordFormSchema,
    onSubmit: async (values) => {
      // reset any error message
      setErrorMessage('');
      setIsLoading(true);

      try {
        const _result = await Auth.forgotPassword(values.email);
      } catch (error) {
        // no matter what error we get from trying to forget the user password,
        // we don't want to leak any details to whether that username exists
      }

      setMessage(
        labels?.LoginForgotPasswordMessage ||
          'If there is a user with this email, a verification link will be sent to this address.',
      );

      setIsLoading(false);
    },
  };
  const forgotPasswordFormik = useFormik(forgotPasswordFormikOptions);
  const forgotPasswordElement = (
    <form onSubmit={forgotPasswordFormik.handleSubmit}>
      <fieldset
        disabled={isLoading}
        className={`flex flex-col items-bottom w-5/6`}
      >
        <div>
          <strong>
            {labels?.LoginForgotPasswordTitle || 'Forgot Password'}
          </strong>
        </div>
        <FieldLabel variant="tag" className="mt-s1">
          {labels?.LoginEmail || 'Email:'}
        </FieldLabel>
        <StyledTextField
          id="email"
          name="email"
          size="small"
          variant="outlined"
          className="flex-1 my-s1"
          value={forgotPasswordFormik.values.email}
          onChange={forgotPasswordFormik.handleChange}
          error={
            forgotPasswordFormik.touched.email &&
            Boolean(forgotPasswordFormik.errors.email)
          }
          helperText={
            forgotPasswordFormik.touched.email &&
            forgotPasswordFormik.errors.email
          }
        />
        {labels?.LoginRequiredField && (
          <div className="text-right">
            <Typography variant="footerBody3">
              {labels?.LoginRequiredField}
            </Typography>
          </div>
        )}
        <div className="flex">
          <Button type="submit" variant={'primary'} className="flex-none">
            {labels?.LoginSubmit || 'Submit'}
          </Button>
          <Button
            type="button"
            variant={'link'}
            className="ml-s2"
            onClick={() => {
              setCurrentLoginFormState(LOGIN_FORM_STATE.RESET_PASSWORD);
              forgotPasswordFormik.resetForm();
              setMessage('');
            }}
          >
            <Typography>
              {labels?.LoginEnterVerificationCode || 'Enter verification code'}
            </Typography>
          </Button>
          <Button
            type="button"
            variant={'link'}
            className="ml-auto"
            onClick={() => {
              setCurrentLoginFormState(LOGIN_FORM_STATE.LOGIN);
              forgotPasswordFormik.resetForm();
            }}
          >
            <Typography variant="date">
              {labels?.LoginCancel || 'Cancel'}
            </Typography>
          </Button>
        </div>
      </fieldset>
    </form>
  );

  // reset password form
  const resetPasswordFormSchema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required(labels?.LoginMandatoryField || 'This field is mandatory'),
    code: yup
      .string()
      .required(labels?.LoginMandatoryField || 'This field is mandatory'),
    newPassword: yup
      .string()
      .matches(
        CognitoPasswordRegex,
        labels?.LoginPasswordValidation ||
          'Password must contain 8 characters, one uppercase, one lowercase, one number, and one special character',
      )
      .required(labels?.LoginMandatoryField || 'This field is mandatory'),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), null],
        labels?.LoginMatchPassword || 'Passwords must match',
      )
      .required(labels?.LoginMandatoryField || 'This field is mandatory'),
  });
  const resetPasswordFormikOptions: FormikConfig<{
    email: string;
    code: string;
    newPassword: string;
    confirmPassword: string;
  }> = {
    initialValues: {
      email: '',
      code: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: resetPasswordFormSchema,
    onSubmit: async (values, { resetForm }) => {
      // reset any error message
      setErrorMessage('');
      setIsLoading(true);

      try {
        const _result = await Auth.forgotPasswordSubmit(
          values.email,
          values.code,
          values.confirmPassword,
        );
        setCurrentLoginFormState(LOGIN_FORM_STATE.LOGIN);
        resetForm();
      } catch (error) {
        // simply provide a generic error message
        // eslint-disable-next-line no-console
        console.error('Error resetting password', error);
        setErrorMessage(
          labels?.LoginResetPasswordError ||
            'Sorry, there was an error resetting your password.',
        );
      }

      setIsLoading(false);
    },
  };
  const resetPasswordFormik = useFormik(resetPasswordFormikOptions);
  const resetPasswordElement = (
    <form onSubmit={resetPasswordFormik.handleSubmit}>
      <fieldset
        disabled={isLoading}
        className={`flex flex-col items-bottom w-5/6`}
      >
        <FieldLabel variant="tag" className="mt-s1">
          {labels?.LoginEmail || '* Email:'}
        </FieldLabel>
        <StyledTextField
          id="email"
          name="email"
          size="small"
          variant="outlined"
          className="flex-1 my-s1"
          value={resetPasswordFormik.values.email}
          onChange={resetPasswordFormik.handleChange}
          error={
            resetPasswordFormik.touched.email &&
            Boolean(resetPasswordFormik.errors.email)
          }
          helperText={
            resetPasswordFormik.touched.email &&
            resetPasswordFormik.errors.email
          }
        />
        <FieldLabel variant="tag" className="mt-s2">
          {labels?.LoginCode || '* Verification Code:'}
        </FieldLabel>
        <StyledTextField
          id="code"
          name="code"
          size="small"
          variant="outlined"
          className="flex-1 my-s1"
          value={resetPasswordFormik.values.code}
          onChange={resetPasswordFormik.handleChange}
          error={
            resetPasswordFormik.touched.code &&
            Boolean(resetPasswordFormik.errors.code)
          }
          helperText={
            resetPasswordFormik.touched.code && resetPasswordFormik.errors.code
          }
        />
        <FieldLabel variant="tag" className="mt-s2">
          {labels?.LoginNewPassword || '* New Password:'}
        </FieldLabel>
        <StyledTextField
          id="newPassword"
          name="newPassword"
          type="password"
          size="small"
          variant="outlined"
          className="flex-1 my-s1"
          value={resetPasswordFormik.values.newPassword}
          onChange={resetPasswordFormik.handleChange}
          error={
            resetPasswordFormik.touched.newPassword &&
            Boolean(resetPasswordFormik.errors.newPassword)
          }
          helperText={
            resetPasswordFormik.touched.newPassword &&
            resetPasswordFormik.errors.newPassword
          }
        />
        <FieldLabel variant="tag" className="mt-s2">
          {labels?.LoginConfirmPassword || '* Confirm Password:'}
        </FieldLabel>
        <StyledTextField
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          size="small"
          variant="outlined"
          className="flex-1 my-s1"
          value={resetPasswordFormik.values.confirmPassword}
          onChange={resetPasswordFormik.handleChange}
          error={
            resetPasswordFormik.touched.confirmPassword &&
            Boolean(resetPasswordFormik.errors.confirmPassword)
          }
          helperText={
            resetPasswordFormik.touched.confirmPassword &&
            resetPasswordFormik.errors.confirmPassword
          }
        />
        {labels?.LoginRequiredField && (
          <div className="text-right">
            <Typography variant="footerBody3">
              {labels?.LoginRequiredField}
            </Typography>
          </div>
        )}
        <div className="flex justify-between items-center mt-s3">
          <Button
            type="submit"
            variant={'primary'}
            className="flex-none"
            disabled={!resetPasswordFormik.dirty}
          >
            {labels?.LoginSubmit || `Submit`}
          </Button>
          <Button
            type="button"
            variant={'link'}
            onClick={() => {
              setCurrentLoginFormState(LOGIN_FORM_STATE.LOGIN);
              resetPasswordFormik.resetForm();
            }}
          >
            <Typography variant="date">
              {labels?.LoginCancel || `Cancel`}
            </Typography>
          </Button>
        </div>
      </fieldset>
    </form>
  );

  // logged in state
  const loggedInElement = (
    <>
      <div>
        <strong>
          {labels?.LoginLoggedInTitle || `You are currently logged in.`}
        </strong>
      </div>
      <div className="mt-s2">
        <Button
          type="button"
          variant={'link'}
          onClick={() => {
            setCurrentLoginFormState(LOGIN_FORM_STATE.CHANGE_PASSWORD);
          }}
        >
          <Typography variant="date">
            {labels?.LoginChangePassword || `Change Password`}
          </Typography>
        </Button>
      </div>
      <div className="mt-s2">
        <Button type="button" variant={'link'} onClick={handleLogOut}>
          <Typography variant="date">
            {labels?.LoginLogOut || `Log out`}
          </Typography>
        </Button>
      </div>
    </>
  );

  // error state
  const errorElement = (
    <Alert severity="error" className="mt-s2">
      <AlertTitle>Sorry! An error occurred. Please try again later.</AlertTitle>
    </Alert>
  );

  return (
    <div className={`container relative py-m4 ${className || ''}`}>
      <Fade in={isLoading} unmountOnExit>
        <div className="absolute z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <CircularProgress aria-label="Loading" />
        </div>
      </Fade>
      <div className="lg:grid gap-s3 grid-cols-10">
        <div className="lg:col-span-4">
          {title && <Typography variant="h2">{title}</Typography>}
        </div>
        <div
          className={`mt-s4 lg:mt-0 lg:col-span-6 ${
            isLoading ? 'opacity-60' : ''
          }`}
        >
          {currentLoginFormState === LOGIN_FORM_STATE.LOGIN
            ? loginElement
            : currentLoginFormState === LOGIN_FORM_STATE.FORCE_CHANGE_PASSWORD
            ? forceChangePasswordElement
            : currentLoginFormState === LOGIN_FORM_STATE.CHANGE_PASSWORD
            ? changePasswordElement
            : currentLoginFormState === LOGIN_FORM_STATE.FORGOT_PASSWORD
            ? forgotPasswordElement
            : currentLoginFormState === LOGIN_FORM_STATE.RESET_PASSWORD
            ? resetPasswordElement
            : currentLoginFormState === LOGIN_FORM_STATE.LOGGED_IN
            ? loggedInElement
            : errorElement}
          {message && (
            <Alert severity="success" className="mt-s2">
              <AlertTitle>{message}</AlertTitle>
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="error" className="mt-s2">
              <AlertTitle>{errorMessage}</AlertTitle>
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUpLogin;
